import React from "react"
import { Button } from "reactstrap"
import Inputform from "../../Inputform/Inputform"

export default class FormularioCuenta extends React.Component {
    constructor(props) {
        super(props)
        //console.log("Maincontacto", this.props)
        this.state = {
            arrayestatus: [{
                id: 0,
                nombre: " Activado"
            },
            {
                id: 1,
                nombre: " Desactivado"
            }]
        }
    }


    render() {
        return (
            <>
                <div className="col-1 col-md-3  col-sm-6 col-xs-12">
                    {(this.props.tipovista != 1) ? (//idcuenta
                        <div> <label>{this.props.titulos[0]}: </label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.idcuentas}</b></div>
                        </div>
                    ) :
                        <div> <label>{this.props.titulos[0]}: </label>
                            <div style={{ minHeight: "42px" }}><b>Nueva cuenta</b></div>
                        </div>}
                </div>

                <div className="col-1 col-md-3  col-sm-6 col-xs-12">
                    {(this.props.tipovista == 1) ? (//cuenta
                        <Inputform datosinput={this.props.inputs[0]} valoringresado={(e) => this.props.guardarvalor(e)} />
                    ) :
                        <>
                            <label>{this.props.titulos[1]}: </label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.cuenta}</b></div>
                        </>
                    }
                </div>

                <div className="col-2  col-md-3   col-sm-6 col-xs-12">
                    {(this.props.tipovista == 1) ? (//cliente
                        <Inputform datosinput={this.props.inputs[1]} valoringresado={(e) => this.props.guardarvalor(e)} />
                    ) :
                        <>
                            <label>{this.props.titulos[2]}:</label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.cliente}</b></div>

                        </>
                    }
                </div>

                <div className="col-1 col-md-3  col-sm-6 col-xs-12">
                    {(this.props.tipovista != 3) ? ( //familia
                        <>
                            {
                                (this.props.familias.length > 0) ? (
                                    <><label className="labelform-theme">{this.props.titulos[4]}: </label>
                                        <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                            onChange={(e) => this.props.cambiarfamilia(e.target.value)}>
                                            {this.props.familias.map((familia, index) => (
                                                <>
                                                    {(familia.idcatalogofamilias == this.props.cuenta.idcatalogofamilias) ? (
                                                        <option value={index} selected>{familia.familia}</option>
                                                    ) :
                                                        <option value={index} >{familia.familia}</option>
                                                    }
                                                </>
                                            ))}
                                        </select>
                                    </>
                                ) :
                                    "Necesario agregar familias"
                            }
                        </>
                    ) :
                        <>
                            <label>{this.props.titulos[4]}: </label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.familia}</b></div>
                        </>
                    }
                </div>

                <div className="col-2  col-md-3  col-sm-6   col-xs-12">

                    {(this.props.tipovista == 2 || this.props.tipovista == 1) ? ( //correo
                        <Inputform datosinput={this.props.inputs[2]} valoringresado={(e) => this.props.guardarvalor(e)} />
                    ) :
                        <>
                            <label>{this.props.titulos[3]}: </label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.correo}</b></div>
                        </>
                    }
                </div>

                <div className="col-1 col-md-3  col-sm-6 col-xs-12">
                    {(this.props.tipovista == 2 || this.props.tipovista == 1) ? (//situaci[on]
                        <>
                            <label>{this.props.titulos[6]}: </label>
                            {(this.props.situaciones.length > 0) ? (
                                <>
                                    <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                        onChange={(e) => this.props.cambiarestatuscuenta(e.target.value)}>
                                        {this.props.situaciones.map((situacion, index) => (
                                            <>
                                                {(situacion.idcatalogoestatus == this.props.cuenta.idcatalogoestatus) ? (
                                                    <option value={index} selected>{situacion.estatus}</option>
                                                ) :
                                                    <option value={index} >{situacion.estatus}</option>
                                                }
                                            </>
                                        ))}
                                    </select>
                                </>
                            ) : "Necesario crear situaciones"
                            }
                        </>
                    ) :
                        <>
                            <label>{this.props.titulos[6]}: </label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.estatus}</b></div>
                        </>
                    }
                </div>

                <div className="col-1 col-md-3 col-sm-6 col-xs-12">

                    {(this.props.tipovista != 3) ? (//notificaciones
                        <>
                            {
                                (this.props.envioresultados.length > 0) ? (
                                    <><label className="labelform-theme">{this.props.titulos[5]}: </label>


                                        <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                            onChange={(e) => this.props.cambiarnotificaciones(e.target.value)}>
                                            {this.props.envioresultados.map((notificacion, index) => (
                                                <>
                                                    {(notificacion.idcatalogoenviarresultados == this.props.cuenta.idcatalogoenviarresultados) ? (
                                                        <option value={index} selected>{notificacion.descripcion}</option>
                                                    ) :
                                                        <option value={index} >{notificacion.descripcion}</option>
                                                    }
                                                </>
                                            ))}
                                        </select>
                                    </>
                                ) :
                                    "Necesario agregar familias"
                            }
                        </>
                    ) :
                        <>
                            <label>{this.props.titulos[5]}: </label>
                            <div style={{ minHeight: "42px" }}><b>{this.props.cuenta.notificaciones}</b></div>
                        </>
                    }

                </div>

                <div className="col-1 col-md-3  col-sm-6 col-xs-12">
                    <label>Envío mensajes SMS: </label>
                    <div style={{ minHeight: "42px" }}>
                        {(this.props.tipovista == 2) ? (// enviomensajessms
                            <>
                                <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                    onChange={(e) => this.props.cambiarindicadorsms(e.target.value)}>
                                    {this.state.arrayestatus.map((item) => (
                                        <>
                                            {(this.props.cuenta.estatussms == 0) ? (
                                                <option value={item.id} selected>{item.nombre}</option>
                                            ) :
                                                <option value={item.id} >{item.nombre}</option>
                                            }
                                        </>
                                    ))}
                                </select>
                            </>

                        ) :
                            <>

                                <select className={(this.props.cuenta.estatussms == "1") ? ("enviomensajes-activo") : "enviomensajes-inactivo"} style={{ padding: ".375rem .75rem" }}
                                    disabled={true}>
                                    {this.state.arrayestatus.map((item) => (
                                        <>
                                            {(this.props.cuenta.estatussms == 0) ? (
                                                <option value={item.id} selected>{item.nombre}</option>
                                            ) :
                                                <option value={item.id} >{item.nombre}</option>
                                            }
                                        </>
                                    ))}
                                </select>



                              

                            </>
                        }

                    </div>

                </div>

            </>
        )
    }
}