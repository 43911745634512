import React from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap"

export default class Inputform extends React.Component {
    constructor(props) {
        super(props)
        this.state={valores:this.props.datosinput}

        //console.log(this.props)
    }

    valoringresado = ( valor) => {
     // console.log(valor)

        this.props.valoringresado({posicion:this.state.valores.posicion,valor:valor});
    }
    render() {
        return (
            <>
                <div>
                    <FormGroup>
                        <Label className="labelform-theme" >
                            {this.props.datosinput.tituloinput}
                        </Label>
                        {(this.props.datosinput.validacion == "") ? (
                            <Input onKeyPress={this.props.onKeyUp} onChange={(e)=>
                                //this.valoringresado(e.target.value)
                                this.props.valoringresado({posicion:this.props.datosinput.posicion,valor:e.target.value})
                            
                            } className="inputform-theme" value={this.props.datosinput.valor} type={this.props.datosinput.tipoinput} disabled={this.props.datosinput.disabled}/>
                        ) :
                            (this.props.datosinput.validacion == "valid") ? (
                                <Input valid onKeyPress={this.props.onKeyUp} onChange={(e)=>
                                    //this.valoringresado(e.target.value)
                                    this.props.valoringresado({posicion:this.props.datosinput.posicion,valor:e.target.value})
                                } className="inputform-theme"  value={this.props.datosinput.valor} type={this.props.datosinput.tipoinput}  disabled={this.props.datosinput.disabled}/>
                            ) :
                                <Input invalid onKeyPress={this.props.onKeyUp} onChange={(e)=>
                                    //this.valoringresado(e.target.value)
                                    this.props.valoringresado({posicion:this.props.datosinput.posicion,valor:e.target.value})
                                } className="inputform-theme"  value={this.props.datosinput.valor} type={this.props.datosinput.tipoinput}  disabled={this.props.datosinput.disabled}/>
                        }
                        <FormFeedback>
                            {this.props.datosinput.mensajevalidacion}
                        </FormFeedback>
                    </FormGroup>

                </div>
            </>
        )
    }
}