import React from "react"
import { GoX } from "react-icons/go";
import { Button, Modal, ModalBody, Table } from "reactstrap";


export default class ModalBitacora extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,

            header1: [
                { titulo: "ID", longitud: "5%" },
                { titulo: "ID Bitácora", longitud: "10%" },
                { titulo: "Fecha Procesado", longitud: "17%" },
                { titulo: "Fila", longitud: "5%" },
                { titulo: "Columna", longitud: "8%" },
                { titulo: "Valor", longitud: "13%" },
                { titulo: "Error", longitud: "24%" },
                { titulo: "Comentarios", longitud: "18%" },
            ],

            header2: [
                { titulo: "Id Carga", longitud: "100px" },
                { titulo: "# Fila", longitud: "60px" },
                { titulo: "Creado", longitud: "170px" },
                { titulo: "Estatus", longitud: "100px" },
                { titulo: "Estatus INTERCAM", longitud: "150px" },
                { titulo: "Actualizado", longitud: "150px" },
                { titulo: "Clabe", longitud: "180px" },
                { titulo: "Cuenta Origen", longitud: "120px" },
                { titulo: "Monto", longitud: "100px" },
                { titulo: "Moneda", longitud: "70px" },
                { titulo: "Fecha Programada", longitud: "140px" },
                { titulo: "Descripción", longitud: "100px" },
                { titulo: "Email Beneficiario", longitud: "180px" },
                { titulo: "Iva", longitud: "70px" },
                { titulo: "Razon Social", longitud: "180px" },
                { titulo: "Tipo Medio", longitud: "100px" },
                { titulo: "Referencia SPEI/SPID", longitud: "150px" },
                { titulo: "Tipo Servicio", longitud: "200px" },
                { titulo: "Referencia Cliente", longitud: "150px" },
                { titulo: "Solicitud valida cuenta", longitud: "170px" },
                { titulo: "Respuesta valida cuenta", longitud: "170px" },
                { titulo: "Codigo valida cuenta", longitud: "160px" },
                { titulo: "Solicitud legado", longitud: "170px" },
                { titulo: "Respuesta legado", longitud: "170px" },
                { titulo: "Codigo legado", longitud: "120px" },
                { titulo: "Descripción legado", longitud: "190px" }
            ],

            header3: [
                { titulo: "Id registro", longitud: "90px" },
                { titulo: "Id bitácora", longitud: "90px" },
                { titulo: "Fecha procesado", longitud: "170px" },
                { titulo: "# Fila", longitud: "60px" },
                { titulo: "Columna", longitud: "70px" },
                { titulo: "Resultado", longitud: "210px" },
                { titulo: "Cuenta", longitud: "100px" },
                { titulo: "Referencia cliente", longitud: "180px" },
                { titulo: "Cuenta clave", longitud: "170px" },
                { titulo: "Divisa", longitud: "90px" },
                { titulo: "Importe", longitud: "90px" },
                { titulo: "Descripción", longitud: "150px" },
                { titulo: "Fecha Programada", longitud: "170px" },
                { titulo: "Iva", longitud: "100px" },

            ],

            header4: [
                { titulo: "ID Bitácora", longitud: "90px" },
                { titulo: "ID Folio", longitud: "90px" },
                { titulo: "Fecha proceso", longitud: "110px" },
                { titulo: "No. Renglón", longitud: "70px" },
                { titulo: "Divisa", longitud: "70px" },
                { titulo: "Importe Operación", longitud: "140px" },
                { titulo: "Importe Operado", longitud: "140px" },
                { titulo: "Importe No Operado", longitud: "140px" },
                { titulo: "Archivo", longitud: "170px" },
                { titulo: "Código Descripción", longitud: "190px" },
                { titulo: "Descripción del pago", longitud: "180px" }
            ]

        }

    }

    componentDidMount() {

        window.addEventListener("resize", this.alturacontenedor)

        setTimeout(() => {
            this.alturacontenedor()
        }, 300);


    }

    alturacontenedor() {

        let item = document.querySelector(".modal-body");
        let header = document.querySelector(".modal-titulo").getBoundingClientRect().height;

        let nuevaaltura = item.getBoundingClientRect().height;

        let calcular = nuevaaltura - header - 50;

        document.querySelector(".modal-bitacora-cuerpocontenido").style.height = calcular + "px"
    }

    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }


    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered
                    className=" modal-cuentas modal-bitacora ">
                    <ModalBody style={{ height: "100%" }}>
                        <div style={{ height: "100%", width: "100%" }}>
                            <p className="modal-titulo">
                                <label className="modal-titulo-aux">{this.props.modaltitulo}</label>
                                <label className="modal-cuentas-cerrar">
                                    <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> </Button>
                                </label>
                            </p>

                            {((this.props.opcion == 1)) ? ( //lista errores 

                                <div className="modal-bitacora-cuerpocontenido" style={{ fontSize: "13px" }}>
                                    <div className="modal-bitacora-cuerpocontenido-tabla" style={(this.props.opcion == 1) ? ({ minWidth: "500px" }) : { minWidth: "2000px" }}>
                                        <div className="modal-bitacora-flex modal-bitacora-header">
                                            {this.state.header1.map(header => (
                                                <div className="modal-bitacora-th" style={{ width: header.longitud }}>
                                                    {header.titulo}
                                                </div>
                                            ))}
                                        </div>
                                        {this.props.listamodal.map(content => (
                                            <>

                                                <div className="modal-bitacora-flex">

                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[0].longitud }}>
                                                        {content.idcuentassolicitudesservicioserrores}
                                                    </div>
                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[1].longitud }}>
                                                        {content.idcuentassolicitudesservicios}
                                                    </div>

                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[2].longitud }}>
                                                        {content.fecha}
                                                    </div>
                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[3].longitud }}>
                                                        {content.fila}
                                                    </div>
                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[4].longitud }}>
                                                        {content.columna}
                                                    </div>
                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[5].longitud }}>
                                                        {content.valorcampo}
                                                    </div>

                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[6].longitud }}>
                                                        {content.error}
                                                    </div>
                                                    <div className="modal-bitacora-td" style={{ width: this.state.header1[7].longitud }}>
                                                        {content.comentarios}
                                                    </div>


                                                </div>
                                                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                                            </>

                                        ))}

                                    </div>
                                </div>



                            ) : //lista correctos
                                ((this.props.opcion == 2)) ? (
                                    <>

                                        <div className="modal-bitacora-cuerpocontenido">
                                            <div className="modal-bitacora-cuerpocontenido-tabla" style={(this.props.opcion == 1) ? ({ minWidth: "500px", fontSize: "13px" }) : { width: "fit-content", fontSize: "13px" }}>
                                                <div className="modal-bitacora-flex modal-bitacora-header">
                                                    {this.state.header2.map(header => (
                                                        <div className="modal-bitacora-th" style={{ width: header.longitud }}>
                                                            {header.titulo}
                                                        </div>
                                                    ))}
                                                </div>
                                                {this.props.listamodal.map(content => (
                                                    <>

                                                        <div className="modal-bitacora-flex">

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[0].longitud }}>
                                                                {content.idcuentassolicitudesservicios}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[1].longitud }}>
                                                                {content.fila}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[2].longitud }}>
                                                                {content.fecha}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[3].longitud }}>
                                                                {content.estatus}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[4].longitud }}>
                                                                {content.estatusintercam}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[5].longitud }}>
                                                                {content.fechaactualizacion}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[6].longitud }}>
                                                                {content.cuentainterbancaria}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[7].longitud }}>
                                                                {content.cuenta}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[8].longitud }}>
                                                                {content.importe}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[9].longitud }}>
                                                                {content.divisa}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[10].longitud }}>
                                                                {content.fechaprogramada}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[11].longitud }}>
                                                                {content.descripcion}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[12].longitud }}>
                                                                {content.correobeneficiario}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[13].longitud }}>
                                                                {content.iva}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[14].longitud }}>
                                                                {content.razonsocialpersonamoral}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[15].longitud }}>
                                                                {content.canal}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[16].longitud }}>
                                                                {content.referenciaspei}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[17].longitud }}>
                                                                {content.servicio}
                                                            </div>
                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[18].longitud }}>
                                                                {content.referenciadelcliente}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[19].longitud }}>
                                                                {content.fechasolicitudvalidacuenta}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[20].longitud }}>
                                                                {content.fecharespuestavalidacuenta}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[21].longitud }}>
                                                                {content.respuestavalidacuenta}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[22].longitud }}>
                                                                {content.fechasolicitudlegado}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[23].longitud }}>
                                                                {content.fecharespuestalegado}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[24].longitud }}>
                                                                {content.respuestalegadocodigo}
                                                            </div>

                                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[25].longitud }}>
                                                                {content.respuestalegadodescripcion}
                                                            </div>

                                                        </div>
                                                        <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                                                    </>

                                                ))}

                                            </div>
                                        </div>

                                    </>
                                ) :
                                    ((this.props.opcion == 10)) ? (
                                        <>
                                            <div className="modal-bitacora-cuerpocontenido">
                                                <div className="modal-bitacora-cuerpocontenido-tabla" style={(this.props.opcion == 1) ? ({ minWidth: "500px", fontSize: "13px" }) : { width: "fit-content", fontSize: "13px" }}>
                                                    <div className="modal-bitacora-flex modal-bitacora-header">
                                                        {this.state.header3.map(header => (
                                                            <div className="modal-bitacora-th" style={{ width: header.longitud }}>
                                                                {header.titulo}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {this.props.listamodal.map(content => (
                                                        <>

                                                            <div className="modal-bitacora-flex">

                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[0].longitud }}>
                                                                    {content.idcuentassolicitudesserviciosvalidados}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[1].longitud }}>
                                                                    {content.idcuentassolicitudesservicios}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[2].longitud }}>
                                                                    {content.fecha}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[3].longitud }}>
                                                                    {content.fila}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[4].longitud }}>
                                                                    {content.columna}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[5].longitud }}>
                                                                    {content.codigo}-{content.codigodescripcion}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[6].longitud }}>
                                                                    {content.cuenta}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[7].longitud }}>
                                                                    {content.referenciadelcliente}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[8].longitud }}>
                                                                    {content.cuentainterbancaria}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[9].longitud }}>
                                                                    {content.divisa}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[10].longitud }}>
                                                                    {content.importe}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[11].longitud }}>
                                                                    {content.descripcion}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[12].longitud }}>
                                                                    {content.fechaprogramada}
                                                                </div>
                                                                <div className="modal-bitacora-td" style={{ width: this.state.header3[13].longitud }}>
                                                                    {content.iva}
                                                                </div>

                                                            </div>
                                                            <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                                                        </>

                                                    ))}

                                                </div>
                                            </div>
                                        </>
                                    ) :

                                        ((this.props.opcion == 13)) ? ( // detalles volumen reporte
                                            <>
                                                <div className="modal-bitacora-cuerpocontenido">
                                                    <div className="modal-bitacora-cuerpocontenido-tabla" style={{ width: "fit-content", fontSize: "13px" }}>
                                                        <div className="modal-bitacora-flex modal-bitacora-header">
                                                            {this.state.header4.map(header => (
                                                                <div className="modal-bitacora-th" style={{ minWidth: header.longitud, width: header.longitud }}>
                                                                    {header.titulo}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {this.props.listamodal.map(content => (
                                                            <>

                                                                {(content.totales == false) ? (<>

                                                                    <div className="modal-bitacora-flex">

                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[0].longitud, width: this.state.header4[0].longitud }}>
                                                                            {content.idcuentassolicitudesservicios}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[1].longitud, width: this.state.header4[1].longitud }}>
                                                                            {content.idcuentassolicitudesserviciosvalidados}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[2].longitud, width: this.state.header4[2].longitud }}>
                                                                            {content.fechasolicitudlegado}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[3].longitud, width: this.state.header4[3].longitud }}>
                                                                            {content.fila}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[4].longitud, width: this.state.header4[4].longitud }}>
                                                                            {content.divisa}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[5].longitud, width: this.state.header4[5].longitud }}>
                                                                            {content.importe}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[6].longitud, width: this.state.header4[6].longitud }}>
                                                                            {content.operado}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[7].longitud, width: this.state.header4[7].longitud }}>
                                                                            {content.nooperado}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[8].longitud, width: this.state.header4[8].longitud }}>
                                                                            {content.archivo}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[9].longitud, width: this.state.header4[9].longitud }}>
                                                                            {content.codigodescripcion}
                                                                        </div>
                                                                        <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[10].longitud, width: this.state.header4[10].longitud }}>
                                                                            {content.concepto}
                                                                        </div>

                                                                    </div>
                                                                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />

                                                                </>) :
                                                                    <>

                                                                        <div className="modal-bitacora-flex">

                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[0].longitud, width: this.state.header4[0].longitud }}>
                                                                                <b>Total</b>
                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[1].longitud, width: this.state.header4[1].longitud }}>

                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[2].longitud, width: this.state.header4[2].longitud }}>

                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[3].longitud, width: this.state.header4[3].longitud }}>

                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[4].longitud, width: this.state.header4[4].longitud }}>

                                                                            </div>

                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[5].longitud, width: this.state.header4[5].longitud }}>
                                                                                <b>{this.format_moneda(content.importe)}</b>
                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[6].longitud, width: this.state.header4[6].longitud }}>
                                                                                <b>{this.format_moneda(content.operado)}</b>
                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[7].longitud, width: this.state.header4[7].longitud }}>
                                                                                <b>{this.format_moneda(content.nooperado)}</b>
                                                                            </div>


                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[8].longitud, width: this.state.header4[8].longitud }}>

                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[9].longitud, width: this.state.header4[9].longitud }}>

                                                                            </div>
                                                                            <div className="modal-bitacora-td" style={{ minWidth: this.state.header4[10].longitud, width: this.state.header4[10].longitud }}>

                                                                            </div>

                                                                        </div>
                                                                        <hr style={{ marginTop: "0px", marginBottom: "0px" }} />

                                                                    </>
                                                                }

                                                            </>

                                                        ))}

                                                    </div>
                                                </div>
                                            </>
                                        ) : null

                            }

                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

