import React from "react"
import { Table } from "reactstrap"

export default class VistaTablerosCuenta extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="row">
                {this.props.medio.map((medio, index) => (
                    <div className=" col-12 modal-cuentas-tablecontainer">
                        <p><b>Medio {medio.canal}</b> </p>
                        <Table className="panel-table">
                            <thead>
                                <tr>
                                    {this.props.headermedios.map(head => (
                                        <th>{head}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{minWidth:"60px"}}>{medio.idcuentacanalarchivos}</td>
                                    <td style={{minWidth:"200px"}}>{medio.canal}</td>
                                    <td style={{minWidth:"70px"}}>{medio.estatus}</td>
                                    <td style={{minWidth:"100px"}}>{medio.usuariocreacion}</td>
                                    <td style={{minWidth:"160px"}}>{medio.creado}</td>
                                    <td style={{minWidth:"150px"}}>{medio.usuarioactualizacion}</td>
                                    <td style={{minWidth:"160px"}}>{medio.actualizado}</td>
                                </tr>
                            </tbody>
                        </Table>

                        {/* servicios ///////////////////////////////////////////////////////////// */}
                        {(medio.servicios.length > 0) ? (
                            <>
                                <p><b>Servicios para {medio.canal} </b></p>
                                <Table className="panel-table">
                                    <thead>
                                        <tr>
                                            {this.props.headerservicios.map(head => (
                                                <th>{head} </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {medio.servicios.map((servicio) => (
                                            <tr>
                                                <td style={{minWidth:"60px"}}>{servicio.idcuentacanalarchivosservicio}</td>
                                                <td style={{minWidth:"200px"}}>{servicio.servicio}</td>
                                                <td style={{minWidth:"70px"}}>{servicio.estatus}</td>
                                                <td style={{minWidth:"100px"}}>{servicio.usuariocreacion}</td>
                                                <td style={{minWidth:"160px"}}>{servicio.creado}</td>
                                                <td style={{minWidth:"150px"}}>{servicio.usuarioactualizacion}</td>
                                                <td style={{minWidth:"160px"}}>{servicio.actualizado}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ) :
                            <p>
                                <b>No has agregado servicios para {medio.canal} </b>
                            </p>
                        }

                       

                    </div>
                ))}
            </div>
        )
    }
}