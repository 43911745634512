import React from "react";
import { Datepicker } from "../../Modales/DatePicker/DatePicker";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { RiCalendarEventFill } from 'react-icons/ri';
import ExportarConsultaArchivos from "./ExportarConsultaArchivos";
import { Reporte_consulta_archivos_detallado } from "../../../Services/Api";
import SpinnerModal from "../../Modales/SpinnerModal/SpinnerModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegCheckSquare } from "react-icons/fa";
import { GrCheckbox } from "react-icons/gr";

export default class FiltrosConsultaArchivos extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }


        this.state = {
            dropdownusuarios: false,
            dropdownservicios: false,
            showdatepicker: false,
            idclienteactivo: 0,
            idservicioactivo: 0,
            idfamiliactivo: 0,
            nombreclienteactivo: "Todos",
            servicioactivo: "Todos",
            familiaclienteactivo: "Todos",
            fechaactiva: null,
            fechainicio: fechaaux,
            fechafin: fechaaux,
            fechaactual: fechaaux,
            respaldoclientes: this.props.respaldoclientes,
            clientes: this.props.listaclientes,
            servicios: this.props.listaservicios,
            familias: this.props.listafamilia,
            dropdownfamily: false,
            check_detalles: false,
            loadingImage: false,
            arraydetalles: [],
            jsoncsv: []

        }
    }

    componentDidMount() {
        this.buscarseleccionado();
    }

    opendatepicker = (opcion) => {
        this.setState({ showdatepicker: !this.state.showdatepicker, fechaactiva: opcion });
    }

    dateSelected = (dato) => {
        this.setState({ showdatepicker: !this.state.showdatepicker });
        if (dato != null) {
            if (dato != null) {
                this.handleDayClick(dato.day, dato.y);
            }
        }
    }

    handleDayClick = (day, { selected }) => {
        let fecha = day.toLocaleDateString();
        if (this.state.fechaactiva == 1)
            this.setState({
                fechainicio: fecha.toString(),
            });
        if (this.state.fechaactiva == 2)
            this.setState({
                fechafin: fecha.toString(),
            });
        this.buscarseleccionado();
    }

    togglecuenta = () => {
        this.setState(prevState => ({
            dropdownusuarios: !prevState.dropdownusuarios
        }));
    }

    toggleservicio = () => {
        this.setState(prevState => ({
            dropdownservicios: !prevState.dropdownservicios
        }));
    }
    togglefamilia = () => {
        this.setState(prevState => ({
            dropdownfamily: !prevState.dropdownfamily
        }));
    }
    familiaselected = async (seleccionado) => {
        this.setState({ familiaclienteactivo: seleccionado.familia, idfamiliactivo: seleccionado.idcatalogofamilias })

        if (this.state.clientes.length > 0) {
            var filtroclientes = [];
            var array_clientes = JSON.parse(JSON.stringify(this.state.respaldoclientes));
            for (let i = 0; i < array_clientes.length; i++) {
                if (array_clientes[i].idcatalogofamilias == seleccionado.idcatalogofamilias) {
                    filtroclientes.push(array_clientes[i]);
                }
            }
            await this.setState({ clientes: filtroclientes })

            if (seleccionado.idcatalogofamilias == 0) {
                this.setState({ clientes: array_clientes })
            }
        }

        this.buscarseleccionado();
    }
    activa_detalles_check = async () => {


        if (this.props.arraydatos.length > 0) {

            var checkD = !this.state.check_detalles;


            if (checkD == true) {
                this.setState({ loadingImage: true })
                await this.setState({ check_detalles: checkD });
                try {


                    var resp = await Reporte_consulta_archivos_detallado(this.state.fechainicio, this.state.fechafin, this.state.idclienteactivo, this.state.idservicioactivo, this.state.idfamiliactivo, this.state.familiaclienteactivo);
                    if (resp.code == 0) {
                        var respuesta = resp.data.items;
                        /////////////////////////////////////////////////////////////////////
                        var array_datos = [];
                        var array_datosr = [];
                        var arraydetalles = [];

                        array_datos = ["Fecha de gestión", "Cliente", "Tipo de servicio", "Familia", "Fecha inicio", "Fecha Fin", "Detallado"];
                        array_datosr.push(array_datos);
                        array_datos = [this.state.fechaactual, this.state.nombreclienteactivo,
                        this.state.servicioactivo, this.state.familiaclienteactivo, this.state.fechainicio, this.state.fechafin, this.state.check_detalles == true ? ('SI') : 'NO'];
                        array_datosr.push(array_datos);

                        var arrayheders = JSON.parse(JSON.stringify(this.props.array_heders));
                        arrayheders.pop();
                        array_datosr.push(arrayheders);

                        array_datos = ["ID FOLIO", "RENGLON", "COLUMNA", "CODIGO Y DESCRIPCION VALIDACIÓN", "FECHA APLICACIÓN"];
                        array_datosr.push(array_datos);


                        for (let i = 0; i < respuesta.length; i++) {

                            array_datos = [respuesta[i].idcuentassolicitudesservicios,
                            respuesta[i].familia,
                            respuesta[i].cuenta,
                            respuesta[i].cliente,
                            respuesta[i].medio,
                            respuesta[i].servicio,
                            respuesta[i].archivo,
                            respuesta[i].fecharecepcion,
                            respuesta[i].totaltransacciones,
                            respuesta[i].totaltransaccioneserror,
                            respuesta[i].totaltransaccionesenviadas,
                            respuesta[i].totaltransaccionesenviadaserror,
                            respuesta[i].estatus,

                            ]

                            array_datosr.push(array_datos)
                            /*if (respuesta[i].detalles.length > 0) {
                                array_datos = ["ID FOLIO", "RENGLON", "CODIGO Y DESCRIPCION VALIDACIÓN", "FECHA APLICACIÓN"];
                                array_datosr.push(array_datos);
                            }*/
                            arraydetalles = [];
                            for (let j = 0; j < respuesta[i].detalles.length; j++) {
                                arraydetalles = [respuesta[i].detalles[j].idcuentassolicitudesserviciosvalidados,
                                respuesta[i].detalles[j].fila,
                                respuesta[i].detalles[j].columna,
                                respuesta[i].detalles[j].codigo + "-" + respuesta[i].detalles[j].codigodescripcion,
                                respuesta[i].detalles[j].fecha];
                                array_datosr.push(arraydetalles)
                            }

                        }

                        if (array_datosr.length > 4) {

                            this.setState({ jsoncsv: array_datosr })
                        }


                        /////////////////////////////////////////////////////////////////////
                        this.setState({ arraydetalles: resp.data.items })
                        this.setState({ loadingImage: false })
                    }
                } catch (error) {
                    this.setState({ loadingImage: false })
                    toast.warn("Intente otra ves", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
            }
            else {
                await this.setState({ check_detalles: checkD });
            }

        }
        else {
            toast.warn("No hay datos que exportar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
    }

    clienteselected = (seleccionado) => {
        this.setState({ nombreclienteactivo: seleccionado.cuenta + " " + seleccionado.cliente, idclienteactivo: seleccionado.idcuentas })
        this.buscarseleccionado();
    }
    servicioselected = (seleccionado) => {

        this.setState({ servicioactivo: seleccionado.servicio, idservicioactivo: seleccionado.idcatalogoservicios })
        this.buscarseleccionado();
    }

    buscarseleccionado = async () => {


        await this.setState({ check_detalles: false });

        setTimeout(() => {

            this.props.buscarseleccionado([
                this.state.fechainicio,
                this.state.fechafin,
                this.state.idclienteactivo,
                this.state.idservicioactivo,
                this.state.idfamiliactivo,
                this.state.familiaclienteactivo,

                this.state.fechaactual,
                this.state.nombreclienteactivo,
                this.state.servicioactivo,
                this.state.check_detalles == true ? ('SI') : 'NO'
            ])

        }, 100);
    }

    /////////////
    escribir = (prop) => (event) => {
        if (event.target.value == undefined) { return; }
        var dias = event.target.value.split("-");
        var fechaf = dias[2] + '/' + dias[1] + '/' + dias[0];
        this.setState({ [prop]: fechaf });
        if (this.state.fechaactiva == 1)
            this.setState({
                fechainicio: fechaf,
            });
        if (this.state.fechaactiva == 2)
            this.setState({
                fechafin: fechaf,
            });
        this.buscarseleccionado();
    }
    regresaFecha = (fecha) => {
        var [day, month, year] = fecha.split("/");
        var fechaf = "";
        if (day != undefined && month != undefined && year != undefined) {
            fechaf = `${year}-${month}-${day}`
            return fechaf;
        } else {
            fechaf = fecha;
        }
        return fechaf;
    }
    //////////

    render() {
        return (
            <>
                <ToastContainer />


                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}

                <div className="consulta-archivo-filtros">
                    <div className="consulta-archivo-usuarios">
                        <label style={{ textAlign: 'right' }}>Familia:</label>
                        {
                            (this.state.familias.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownfamily} toggle={this.togglefamilia} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.familiaclienteactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona una familia</DropdownItem>
                                            <DropdownItem onClick={() => this.familiaselected({ familia: "Todos", idcatalogofamilias: 0 })}>Todos</DropdownItem>
                                            {this.state.familias.map((family, index) => (
                                                <DropdownItem key={index} onClick={() => this.familiaselected(family)}>{family.familia}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-usuarios">
                        <label>Cliente:</label>
                        {
                            (this.state.clientes.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownusuarios} toggle={this.togglecuenta} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.nombreclienteactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un usuario</DropdownItem>
                                            <DropdownItem onClick={() => this.clienteselected({ cuenta: "", cliente: "Todos", idcuentas: 0 })}>Todos</DropdownItem>
                                            {this.state.clientes.map((cliente, index) => (
                                                <DropdownItem key={index} onClick={() => this.clienteselected(cliente)}>{cliente.cuenta} {cliente.cliente}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-usuarios">
                        <label>Tipo de servicio:</label>
                        {
                            (this.state.servicios.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownservicios} toggle={this.toggleservicio} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.servicioactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un servicio</DropdownItem>
                                            <DropdownItem onClick={() => this.servicioselected({ servicio: "Todos", idcatalogoservicios: 0 })}>Todos</DropdownItem>
                                            {this.state.servicios.map((servicio, index) => (
                                                <DropdownItem key={index} onClick={() => this.servicioselected(servicio)}>{servicio.servicio}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-fechas " >
                        <label>
                            Fecha:
                        </label>

                        <div style={{ background: "flex", width: "170px" }}>

                            <input className='bitacoragestion-fechafin blueoutlinebutton input_fecha_new'
                                type={'date'} value={this.regresaFecha(this.state.fechainicio)} onChange={this.escribir("fechainicio")}></input>


                            <input className='bitacoragestion-fechafin blueoutlinebutton input_fecha_new' style={{ marginTop: '5px' }}
                                type={'date'} value={this.regresaFecha(this.state.fechafin)} onChange={this.escribir("fechafin")}></input>

                        </div>
                    </div>

                    <div className="consulta-archivos-exportar" style={{ marginRight: "0px", marginLeft: "auto" }}>
                        <div style={{ display: "flex" }}>

                            <ExportarConsultaArchivos bandera={'excel'} cliente={this.state.nombreclienteactivo} tipo={this.state.servicioactivo} familia={this.state.familiaclienteactivo}
                                legado={this.state.legadoactivo} detalles={this.state.check_detalles} fechaI={this.state.fechainicio} fechaF={this.state.fechafin}
                                arraydatos={this.state.check_detalles == false ? (this.props.arrayjsoncsv) : this.state.jsoncsv} array_heders={this.props.array_heders} />

                            <ExportarConsultaArchivos bandera={'PDF'} cliente={this.state.nombreclienteactivo} tipo={this.state.servicioactivo} familia={this.state.familiaclienteactivo}
                                legado={this.state.legadoactivo} detalles={this.state.check_detalles} fechaI={this.state.fechainicio} fechaF={this.state.fechafin}
                                arraydatos={this.state.check_detalles == false ? (this.props.arraydatos) : this.state.arraydetalles} array_heders={this.props.array_heders} />


                        </div>

                        <label className="consulta-archivos-detalles" onClick={this.activa_detalles_check} >
                            {this.state.check_detalles ? <FaRegCheckSquare className="icodetalles" /> : <GrCheckbox className="icodetalles" />}
                            Detallado

                        </label>

                    </div>
                </div>
            </>
        );
    }
}

