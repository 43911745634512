import React from "react";
import { Datepicker } from "../../Modales/DatePicker/DatePicker";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { RiCalendarEventFill, RiFileExcel2Fill } from 'react-icons/ri';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import ExportarConsultaClientes from "./ExportarConsultaClientes";

export default class FiltrosConsultaClientes extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }


        this.state = {
            dropdownusuarios: false,
            dropdownmedios: false,
            dropdownservicios: false,
            dropdownfamily: false,
            showdatepicker: false,
            idclienteactivo: 0,
            idservicioactivo: 0,
            idmediosactivos: 0,
            idfamiliactivo: 0,
            nombreclienteactivo: "Todos",
            servicioactivo: "Todos",
            familiaclienteactivo: "Todos",
            medios: "Todos",
            fechaactiva: null,
            fechainicio: fechaaux,
            fechafin: fechaaux,
            clientes: this.props.listaclientes,
            respaldoclientes: this.props.respaldoclientes,
            servicios: this.props.listaservicios,
            familias: this.props.listafamilia,
            listamedios: this.props.listamedios,
            data: []
        }
    }

    componentDidMount() {
        this.buscarseleccionado();
    }

    opendatepicker = (opcion) => {
        this.setState({ showdatepicker: !this.state.showdatepicker, fechaactiva: opcion });
    }

    dateSelected = (dato) => {
        this.setState({ showdatepicker: !this.state.showdatepicker });
        if (dato != null) {
            if (dato != null) {
                this.handleDayClick(dato.day, dato.y);
            }
        }
    }

    handleDayClick = (day, { selected }) => {
        let fecha = day.toLocaleDateString();
        if (this.state.fechaactiva == 1)
            this.setState({
                fechainicio: fecha.toString(),
            });
        if (this.state.fechaactiva == 2)
            this.setState({
                fechafin: fecha.toString(),
            });
        this.buscarseleccionado();
    }

    togglecuenta = () => {
        this.setState(prevState => ({
            dropdownusuarios: !prevState.dropdownusuarios
        }));
    }

    togglemedios = () => {
        this.setState(prevState => ({
            dropdownmedios: !prevState.dropdownmedios
        }));
    }
    toggleservicio = () => {
        this.setState(prevState => ({
            dropdownservicios: !prevState.dropdownservicios
        }));
    }
    togglefamilia = () => {
        this.setState(prevState => ({
            dropdownfamily: !prevState.dropdownfamily
        }));
    }
    familiaselected = async (seleccionado) => {
        this.setState({ familiaclienteactivo: seleccionado.familia, idfamiliactivo: seleccionado.idcatalogofamilias })
        if (this.state.clientes.length > 0) {
            var filtroclientes = [];
            var array_clientes = JSON.parse(JSON.stringify(this.state.respaldoclientes));
            for (let i = 0; i < array_clientes.length; i++) {
                if (array_clientes[i].idcatalogofamilias == seleccionado.idcatalogofamilias) {
                    filtroclientes.push(array_clientes[i]);
                }
            }
            await this.setState({ clientes: filtroclientes })

            if (seleccionado.idcatalogofamilias == 0) {
                this.setState({ clientes: array_clientes })
            }
        }
        this.buscarseleccionado();

    }
    clienteselected = (seleccionado) => {
        this.setState({ nombreclienteactivo: seleccionado.cuenta + " " + seleccionado.cliente, idclienteactivo: seleccionado.idcuentas })
        this.buscarseleccionado();
    }
    servicioselected = (seleccionado) => {
        this.setState({ servicioactivo: seleccionado.servicio, idservicioactivo: seleccionado.idcatalogoservicios })
        this.buscarseleccionado();
    }
    mediosselected = (seleccionado) => {
        this.setState({ medios: seleccionado.canal, idmediosactivos: seleccionado.idcatalogocanalarchivos })
        this.buscarseleccionado();
    }

    buscarseleccionado = () => {
        setTimeout(() => {
            this.props.buscarseleccionado([this.state.fechainicio, this.state.fechafin, this.state.idclienteactivo,
            this.state.idservicioactivo, this.state.nombreclienteactivo, this.state.servicioactivo,
            this.state.idfamiliactivo, this.state.familiaclienteactivo,
            this.state.idmediosactivos, this.state.medios])
        }, 100);
    }

    render() {
        return (
            <>
                {(this.state.showdatepicker == true) ? (
                    <Datepicker show={this.state.showdatepicker} getDate={this.dateSelected} />
                ) : null}


                <div className="consulta-archivo-filtros">


                    <div className="consulta-archivo-usuarios">
                        <label style={{ textAlign: 'right' }}>Familia:</label>
                        {
                            (this.state.familias.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownfamily} toggle={this.togglefamilia} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.familiaclienteactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona una familia</DropdownItem>
                                            <DropdownItem onClick={() => this.familiaselected({ familia: "Todos", idcatalogofamilias: 0 })}>Todos</DropdownItem>
                                            {this.state.familias.map((family,index) => (
                                                <DropdownItem key={index} onClick={() => this.familiaselected(family)}>{family.familia}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-usuarios">
                        <label style={{ textAlign: 'right' }}>Cliente:</label>
                        {
                            (this.state.clientes.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownusuarios} toggle={this.togglecuenta} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.nombreclienteactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un cliente</DropdownItem>
                                            <DropdownItem onClick={() => this.clienteselected({ cuenta: "", cliente: "Todos", idcuentas: 0 })}>Todos</DropdownItem>
                                            {this.state.clientes.map((cliente, index) => (
                                                <DropdownItem key={index} onClick={() => this.clienteselected(cliente)}>{cliente.cuenta} {cliente.cliente}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="consulta-archivo-usuarios">
                        <label style={{ textAlign: 'right' }}>Tipo de medio:</label>
                        {
                            (this.state.listamedios.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownmedios} toggle={this.togglemedios} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.medios}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un medio</DropdownItem>
                                            <DropdownItem onClick={() => this.mediosselected({ canal: "Todos", idcatalogocanalarchivos: 0 })}>Todos</DropdownItem>
                                            {this.state.listamedios.map((medios,index) => (
                                                <DropdownItem key={index} onClick={() => this.mediosselected(medios)}>{medios.canal}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-usuarios">
                        <label style={{ textAlign: 'right' }}>Tipo de servicio:</label>
                        {
                            (this.state.servicios.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownservicios} toggle={this.toggleservicio} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.servicioactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un servicio</DropdownItem>
                                            <DropdownItem onClick={() => this.servicioselected({ servicio: "Todos", idcatalogoservicios: 0 })}>Todos</DropdownItem>
                                            {this.state.servicios.map((servicio,index) => (
                                                <DropdownItem key={index} onClick={() => this.servicioselected(servicio)}>{servicio.servicio}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-fechas " >

                    </div>

                    <div className="consulta-archivos-exportar" style={{ marginRight: "0px", marginLeft: "auto" }}>
                        <div style={{ display: "flex" }}>

                            <ExportarConsultaClientes bandera={'excel'} cliente={this.state.nombreclienteactivo} familia={this.state.familiaclienteactivo} medio={this.state.medios} tipo={this.state.servicioactivo}
                                legado={this.state.legadoactivo} detalles={this.state.check_detalles} fechaI={this.state.fechainicio} fechaF={this.state.fechafin}
                                arraydatos={this.props.arraydatos} array_heders={this.props.array_heders} arraycsv={this.props.arraycsv} />

                            <ExportarConsultaClientes bandera={'PDF'} cliente={this.state.nombreclienteactivo} familia={this.state.familiaclienteactivo} medio={this.state.medios} tipo={this.state.servicioactivo}
                                legado={this.state.legadoactivo} detalles={this.state.check_detalles} fechaI={this.state.fechainicio} fechaF={this.state.fechafin}
                                arraydatos={this.props.arraydatos} array_heders={this.props.array_heders} arraycsv={this.props.arraycsv} />


                        </div>


                    </div>
                </div>
            </>
        );
    }
}

