//respuestas rápidas 
const campovacio = "Este campo es obligatorio"


//validaciones login

export function validaruser(data) {
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe solo letras y números";

   if (data == "") {
      aviso = campovacio
   }

   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };

   // return { codigo:"valid", mensaje:aviso};
}

export function validarpassword(data) {
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe solo letras y números";

   if (data == "") {
      aviso = campovacio
   }

   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };

   // return { codigo:"valid", mensaje:aviso};
}


//validaciones situacion

export function validarsituacionclave(data) {
   let validator = /^[A-Z]{2}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras mayúsculas. Este campo no puede superar más de 2 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}

export function validarsituaciondescripcion(data) {
   let validator = /^[a-zA-z0-9ñÑáéíóúÁÉÍÓÚ ,.]{2,100}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras y números. Este campo no puede superar más de 100 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}


//VALIDACIONES envio resultados

//validaciones servicios

export function validacionruta(data) {
   let validator =/^([a-zA-Z0-9\/\-_\\:\\.]){5,300}$/
   let x = validator.test(data);

   var aviso = "Escribe una ruta válida. No se permiten caracteres especiales ni espacios en blanco";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}


/*validacion monedas */


export function validarmonedacodigo(data) {
   let validator = /^([A-Z]){3}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras mayúsculas. Necesarios 3 caracteres";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}


/*evalidacion errores */

export function validarerrorcodigo(data) {
   let validator = /^([0-9]){4}$/
   let x = validator.test(data);

   var aviso = "Escribe solo números. Necesarios 4 caracteres";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}



/* validar medios  */

export function validartodo(data) {
   let validator = /^[a-zA-z0-9ñÑáéíóúÁÉÍÓÚ:;,.$%?//()-_+=#@ ]{1,200}$/
   let x = validator.test(data);

   var aviso = "Escribe letras y números. Este campo no puede superar más de 200 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}

export function validarmedionombre(data) {
   let validator = /^[a-zA-z0-9ñÑáéíóúÁÉÍÓÚ ]{2,30}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras y números. Este campo no puede superar más de 30 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}




/* validar asesores */


export function validarasesornombre(data) {
   let validator = /^[a-zA-zñÑáéíóúÁÉÍÓÚ ]{2,60}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras. Este campo no puede superar más de 60 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}



export function validarEmail(data) {
   let validator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[[a-zA-Z0-9-]+[a-zA-Z])+$/
   let x = validator.test(data);

   var aviso = "Escribe un correo válido";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}





/* validar familias */
export function validarclientefamilia(data) {
   let validator = /^[a-zA-Z0-9]{1,15}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras y números. Este campo no puede superar más de 15 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}






/*validar tipo de operacion */

export function validartipotransaccion(data) {
   let validator = /^[A-Z]{1}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras mayúsculas. Este campo no puede superar 1 caracter.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}














/*cambiar password  */

export function validarnuevopassword(data) {
   let validator = /^[a-zA-Z0-9]{8,15}$/
   let x = validator.test(data);

   var aviso = "La contraseña debe tener una longitud minima 8 caracteres y máximo 15 caracteres, una letra mayúscula, una letra minúscula, un número ";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}





/*modal contacto formulario sms */
export function vaidarcuentafolio(data) {
   let validator = /^[0-9]{1,12}$/
   let x = validator.test(data);

   var aviso = "Solo se permiten números. Este campo no puede superar más de 12 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarnombresms(data) {
   let validator = /^[a-zA-zñÑáéíóúÁÉÍÓÚ ]{2,60}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras. Este campo no puede superar más de 60 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}



export function validartelefono(data) {
   console.log("validartelefono()", data)
   let validator = /^[0-9]{10}$/
   let x = validator.test(data);

   var aviso = "Escribe solo números. Necesarios 10 dígitos para el teléfono";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}
















export function validartipocuenta(data) {  //////<----------- estado de cuenta aun temporal
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe solo letrasy números";

   if (data == "") {
      aviso = campovacio
   }

   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };

}






export function validarnombrecuenta(data) {
   let validator = /^[a-zA-zñÑáéíóúÁÉÍÓÚ \-]{2,60}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras. Este campo no puede superar más de 60 caracteres.";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}















export function validarhora(data) {
   let validator = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
   let x = validator.test(data);

   var aviso = "Formato de hora debe ser HH:MM";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}












