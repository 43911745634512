import React from "react";
import { BsEyeFill, BsPlusLg } from "react-icons/bs";
import { RiEdit2Fill, RiRefreshLine } from "react-icons/ri";
import { FaSort } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { gestion_activarusuario, gestion_desactivarusuario, gestion_editarusuario, gestion_nuevosuario, gestion_usuarios, gestion_usuario_nuevopassword } from "../../Services/Api";
import { validarEmail, validarasesornombre, validaruser } from "../../Services/Validaciones";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import Nabvar from "../Panel/Nabvar";
import { listainputsUsuario, ordenarListainputsUsuario, itemactivoGestionusuarios, ordenarUsuarios } from "./Complementos/OrdenarFormularios";
import AreaMensajes from "../Modales/AreaMensajes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImKey } from "react-icons/im";
import ModalUsuarios from "../Modales/ModalUsuarios";
import Paginacion, { buildContent } from "../Panel/Paginacion";

export default class Usuarios2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Usuarios",
            botoncrear: "Crear nuevo usuario",
            tabla: {
                encabezados: ["ID", "Login", "E-mail", "Perfiles", "Fecha creación", "Editado por", "Fecha edición", "Situacion"],
                dimensiones: ["6%", "8%", "10%", "10%", "20%", "15%", "18%", "10%"],
                mindimensiones: ["70px", "100px", "150px", "150px", "180px", "120px", "200px", "100px"],
                elementos: []
            },

            modalelementos: false,
            tipoaccion: null,
            modaltitulo: "",
            modalelementosinput: [
                {
                    tituloinput: "Descripción:", contenido: "", tipoinput: "text", posicion: 1,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
                }
            ],
            itemactivo: null,
            itemtitulos: ["ID", "Login", "E-mail", "Perfiles", "Fecha creación", "Editado por", "Fecha edición", "Situacion", "Creado por", "Fecha de creación", "Actualizado por", "Fecha de actualización"],
            itemver: [],
            permisoedicion: false,

            actualordenado: "",
            loadingImage: true,
            tipomensaje: 1,

            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: "",
            modulo: null,

            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        const lista = await gestion_usuarios();
        if (lista.code == 0) {
            let listaitems = this.state.tabla;
            listaitems.elementos = lista.data.items;
            this.setState({ tabla: listaitems, actualordenado: "", showmensaje: false, modulo: lista.data.modulo })
            this.handleCommentsChange(this.state.activePage)

            //toast.success("Carga de tabla exitosa",  { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            //verificar permisos
            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        //console.log("Usuarios");
        await this.refreshInfo();
    }


    crearelemento = (item) => {
        this.setState({ tipoaccion: item.origen });
        let inputs = listainputsUsuario();

        if (item.origen == 1) {
            let elemento = this.guardaritemactivo("");
            let inputsordenados = ordenarListainputsUsuario(item.origen, inputs, elemento);
            this.setState({ modalelementosinput: inputsordenados })
            this.estadosmodalcatalogo([],
                ["Login", "Nombre", "Apellidos", "E-mail", "Idioma", "Perfiles", "Creado por", "Fecha de creación", "Actualizado por", "Fecha de actualización"],
                "Crear Usuario");
        }

        else if (item.origen == 2) {
            let elemento = this.guardaritemactivo(item.idelemento);
            let inputsordenados = ordenarListainputsUsuario(item.origen, inputs, elemento);
            this.setState({ modalelementosinput: inputsordenados })
            this.estadosmodalcatalogo([],
                ["Login", "Nombre", "Apellidos", "E-mail", "Idioma", "Perfiles", "Creado por", "Fecha de creación", "Actualizado por", "Fecha de actualización",],

                "Editar Usuario [" + elemento.login + "]");
        }

        else if (item.origen == 3) {
            let elemento = this.guardaritemactivo(item.idelemento)
            let lista = [
                elemento.login,
                elemento.nombre,
                elemento.apellido,
                elemento.email,
                elemento.idioma,
                elemento.tipo,
                elemento.usuariocreacion,
                elemento.creado,
                elemento.usuarioactualizacion,
                elemento.actualizado,


                elemento.estatus,
                elemento.idestatus


            ];
            this.estadosmodalcatalogo(lista,
                ["Login", "Nombre", "Apellidos", "E-mail", "Idioma", "Perfiles", "Creado por", "Fecha de creación", "Actualizado por", "Fecha de actualización"],
                "Usuario [" + elemento.login + "]");

        }
    }

    estadosmodalcatalogo = (lista, titulos, encabezadomodal) => {
        this.setState({
            modalelementos: true,
            loadingImage: true,
            itemver: lista,
            itemtitulos: titulos,
            modaltitulo: encabezadomodal
        });
    }

    guardaritemactivo = (idelemento) => {
        let elemento = [];
        if (idelemento != "")
            elemento = this.state.tabla.elementos.find(x => x.idusuario == idelemento);
        else elemento = []

        let nuevoelemento = itemactivoGestionusuarios(idelemento, elemento);
        this.setState({ itemactivo: nuevoelemento[0] });
        return nuevoelemento[0]
    }

    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false, modalelementosinput: listainputsUsuario(), showmensajemodal: false, loadingImage: false });
    }

    respuestaformulario = async (arreglo) => {
        this.setState({ loadingImage: true, showmensaje: false })
        let respuestavalidacion = await this.validarcontenido(arreglo);
        return respuestavalidacion
    }

    validarcontenido = async (contenido) => {
        let respuestavalidacion = true;

        let loginguardado = validaruser(contenido[1].valor)
        contenido[1].mensajevalidacion = loginguardado.mensaje;
        contenido[1].validacion = loginguardado.codigo;
        if (loginguardado.codigo == "invalid")
            respuestavalidacion = false;

        let nombreguardado = validarasesornombre(contenido[2].valor)
        contenido[2].mensajevalidacion = nombreguardado.mensaje;
        contenido[2].validacion = nombreguardado.codigo;
        if (nombreguardado.codigo == "invalid")
            respuestavalidacion = false;

        let apellidoguardado = validarasesornombre(contenido[3].valor)
        contenido[3].mensajevalidacion = apellidoguardado.mensaje;
        contenido[3].validacion = apellidoguardado.codigo;
        if (apellidoguardado.codigo == "invalid")
            respuestavalidacion = false;

        let correoguardado = validarEmail(contenido[4].valor)
        contenido[4].mensajevalidacion = correoguardado.mensaje;
        contenido[4].validacion = correoguardado.codigo;
        if (correoguardado.codigo == "invalid")
            respuestavalidacion = false;

        if (contenido[6].contenido == "") {
            toast.warn("Debes seleccionar un perfil", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            respuestavalidacion = false;
        }

        //detener spinner si invalid 
        if (respuestavalidacion == true) {
            respuestavalidacion = await this.enviardatos(contenido);

            if (respuestavalidacion == false) {
                setTimeout(() => {
                    this.setState({ loadingImage: false })
                }, 200);
            }
        }
        else {
            this.setState({ loadingImage: false })
        }

        return { aprobado: respuestavalidacion, nuevoarreglo: contenido }
    }

    finalizaraccion = async () => {
        this.cerrarmodalcatalogo();
        await this.refreshInfo();
    }

    enviardatos = async (datos) => {
        let login = datos[1].valor;
        let nombre = datos[2].valor;
        let apellido = datos[3].valor;
        let email = datos[4].valor;
        let idusuariotipo = datos[6].contenido;
        let idestatus = datos[5].contenido;
        let enviado = null;

        if (this.state.tipoaccion == 1) {
            enviado = await gestion_nuevosuario(login, nombre, apellido, email, idusuariotipo, idestatus);
        }
        else if (this.state.tipoaccion == 2) {
            enviado = await gestion_editarusuario(login, nombre, apellido, email, idusuariotipo, idestatus, this.state.itemactivo.idusuario);
        }

        if (enviado.code == 0) {
            toast.success("Usuario guardado exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else {
            if (enviado.code == 1) {
                toast.warn("No se pudo guardar, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (enviado.code == 2) {
                toast.warn(enviado.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensajemodalcatalogo(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            return false
        }
    }

    handleCommentsChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)
            });
        }, 0);
    }


    ordenar = (opcion) => {
        let actual = this.state.tabla;
        let x = ordenarUsuarios(opcion, actual, this.state.actualordenado);
        actual.elementos = x;
        this.setState({ tabla: actual, actualordenado: opcion });
        this.handleCommentsChange(this.state.activePage)
    }

    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }

    mensajemodalcatalogo(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensajemodal: titulo, descripcionmensajemodal: mensaje })
        setTimeout(() => {
            this.setState({ showmensajemodal: true, loadingImage: false })
        }, 200);
    }









    cambiarestatus = async (id) => {
        let nuevalista = this.state.tabla.elementos;
        nuevalista.forEach(async item => {
            if (item.idusuario == id) {
                //item.idestatus = (item.idestatus == 1) ? (2) : 1;
                //item.estatus = (item.idestatus == 1) ? ("Activo") : "Inactivo";

                let respuestaitemactivo = null;
                if (item.idestatus == 1) {
                    respuestaitemactivo = await gestion_desactivarusuario(id);
                    //console.log(respuestaitemactivo);
                }
                else {
                    respuestaitemactivo = await gestion_activarusuario(id);
                    //console.log(respuestaitemactivo);
                }

                if (respuestaitemactivo.code == 0) {
                    toast.success("Cambio realizado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    this.refreshInfo();
                }
                else if (respuestaitemactivo.opcion == 1) {
                    toast.warn("Cambio no realizado", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                else {
                    toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
            }
        });
        this.setState({ elementos: nuevalista })
    }



    nuevopassword = async (id) => {
        let respuestaitemactivo = await gestion_usuario_nuevopassword(id);
        if (respuestaitemactivo.code == 0) {
            toast.success("Correo enviado", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.refreshInfo();
        }
        else if (respuestaitemactivo.opcion == 1) {
            toast.warn("Cambio no realizado", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else {
            toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

    }








    avisolistasmodal = (respuestamodal) => {

        if (respuestamodal.validar == false) {

            if (respuestamodal.opcion == 0) {
                toast.warn("Debes tener perfiles para poder crear nuevos usuarios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (respuestamodal.opcion == 1) {
                toast.warn("Parece que tu sesión ha caducado, cierra y vuelve a iniciar sesión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensajemodalcatalogo(0, "Sin conexión", "Revisa tu conexión a internet")
            }

        } else {
            //toast.success("Carga de datos exitosa",  { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        this.setState({ loadingImage: false })
    }



    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                <ToastContainer />

                {(this.state.modalelementos) ? (
                    <ModalUsuarios
                        show={this.state.modalelementos}
                        tipoaccion={this.state.tipoaccion}
                        catalogo={"1"}
                        modaltitulo={this.state.modaltitulo}
                        elementosinput={this.state.modalelementosinput}
                        itemactivo={this.state.itemactivo}
                        itemtitulos={this.state.itemtitulos}
                        itemver={this.state.itemver}
                        changestate={() => this.cerrarmodalcatalogo()}
                        respuestaformulario={(e) => this.respuestaformulario(e)}
                        finalizaraccion={(e) => this.finalizaraccion(e)}
                        showmensaje={this.state.showmensajemodal}
                        titulo={this.state.titulomensajemodal}
                        mensaje={this.state.descripcionmensajemodal}

                        avisolistas={(e) => this.avisolistasmodal(e)}
                    />
                ) : null}


                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body ">

                        <div className="panel-body-header-table">
                            {this.state.titulotabla}
                            <div className="panel-body-header-table-buttons ">

                                <div>
                                    <Button className="greenoutlinebutton" onClick={this.refreshInfo}><RiRefreshLine /> Refrescar Lista</Button>
                                </div>
                                <div >
                                    <Button
                                        className={(this.state.permisoedicion) ? ("bluebutton") : "cancelbutton"}
                                        disabled={(this.state.permisoedicion) ? (false) : true}
                                        onClick={(this.state.permisoedicion) ? (
                                            () => this.crearelemento({ origen: 1, idelemento: "" })
                                        ) : (
                                            () => console.log("no tienes permiso")
                                        )
                                        }
                                    > <BsPlusLg /> {this.state.botoncrear}</Button>
                                </div>
                            </div>
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>
                                {(this.state.tabla.elementos.length > 0) ? (
                                    <Table className="panel-table" style={{ fontSize: "14px" }} >
                                        <thead>
                                            <tr>
                                                {this.state.tabla.encabezados.map((titulo, index) => (
                                                    <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                        style={{ width: this.state.tabla.dimensiones[index], minWidth: this.state.tabla.mindimensiones[index] }}
                                                        onClick={() => this.ordenar(index)}>
                                                        {titulo} <FaSort className={(this.state.actualordenado == index) ? ("tables-iconsort tables-actualsorting") : "tables-iconsort"} />
                                                    </th>
                                                ))}
                                                <th></th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {this.state.listapaginada.map((item, index) => (
                                                <tr key={"tablaitemtr" + index}>
                                                    <td className={"panel-table-cell" + 0}>
                                                        {item.idusuario}
                                                    </td>
                                                    <td className={"panel-table-cell" + 1}>
                                                        {item.login}
                                                    </td>
                                                    <td className={"panel-table-cell" + 2}>
                                                        {item.email}
                                                    </td>
                                                    <td className={"panel-table-cell" + 3}>
                                                        {item.tipo}
                                                    </td>
                                                    <td className={"panel-table-cell" + 4}>
                                                        {item.creado}
                                                    </td>
                                                    <td className={"panel-table-cell" + 5}>
                                                        {item.usuarioactualizacion}
                                                    </td>
                                                    <td className={"panel-table-cell" + 6}>
                                                        {item.actualizado}
                                                    </td>

                                                    <td className={"panel-table-cell" + 7}>

                                                        {this.state.permisoedicion ? (
                                                            <Button className={(item.idestatus == 1) ? ("btn-success genericbutton") : "btn-danger genericbutton"}
                                                                onClick={() => this.cambiarestatus(item.idusuario)}> {item.estatus}</Button>
                                                        ) : (
                                                            <Button
                                                                className={"cancelbutton"}
                                                                disabled={ true}
                                                                style={{padding:"0px"}}
                                                                onClick={(() => console.log("no tienes permiso"))}>
                                                                {item.estatus}</Button>
                                                        )}
                                                       
                                                    </td>
                                                    <td className="panel-table-buttons">
                                                        <div className="panel-table-buttons-aux">
                                                            <div>
                                                                <Button className="greenbutton" onClick={() => this.crearelemento({ origen: 3, idelemento: item.idusuario })}><BsEyeFill />  Ver</Button>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    className={(this.state.permisoedicion) ? ("bluebutton") : "cancelbutton"}
                                                                    disabled={(this.state.permisoedicion) ? (false) : true}
                                                                    style={{padding:"0px"}}
                                                                    onClick={(this.state.permisoedicion) ? (
                                                                        () => this.crearelemento({ origen: 2, idelemento: item.idusuario })
                                                                    ) : (
                                                                        () => console.log("no tienes permiso")
                                                                    )
                                                                    }>
                                                                    <RiEdit2Fill /> Editar</Button>
                                                            </div>
                                                            <div style={{ width: "150px" }}>

                                                                <Button
                                                                    className={(this.state.permisoedicion) ? ("greenbutton") : "cancelbutton"}
                                                                    disabled={(this.state.permisoedicion) ? (false) : true}
                                                                    style={{padding:"0px"}}
                                                                    onClick={(this.state.permisoedicion) ? (
                                                                        () => this.nuevopassword(item.idusuario)
                                                                    ) : (
                                                                        () => console.log("no tienes permiso")
                                                                    )
                                                                    }>
                                                                    <ImKey /> Restablecer</Button>


                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }
                            </>
                        }

                        <div className="panel-pagination-section">
                            <Paginacion
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsperpage}
                                totalItemsCount={this.state.tabla.elementos}
                                pageRangeDisplayed={this.state.rangepages}
                                handleCommentsChange={(e) => this.handleCommentsChange(e)}
                            />
                        </div>

                    </div>

                    <div className="panel-footer">
                        <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>
            </>
        );
    }
}