import React from "react";
import { Redirect } from "react-router-dom";
import { administracion_bitacora, administracion_bitacora_desglose, administracion_cuentas, catalogo_familia, catalogo_mediosServicios, catalogo_servicios, Reporte_consulta_clientes } from "../../Services/Api";
import ModalBitacora from "../Modales/ModalBitacora";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import AreaMensajes from "../Modales/AreaMensajes";
import { Button, Table } from "reactstrap";
import { FaSort } from "react-icons/fa";
import Paginacion, { buildContent } from "../Panel/Paginacion";
import Nabvar from "../Panel/Nabvar";
import { BsEyeFill } from "react-icons/bs";
import { ordenarbitacoragestion, ordenartipodeservicio } from "../Administracion/Complementos/CuentasOrdenar";
import FiltrosConsultaClientes from "./Complementos/FiltrosConsultaClientes";


export default class ConsultarClientes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Tipo de servicio por medio y cuenta",
            tabla: {
                encabezados: [
                    "ID FOLIO",
                    "FAMILIA",
                    "CUENTA",
                    "CLIENTE",
                    "TIPO DE MEDIO",
                    "TIPO DE SERVICIO",
                    "SITUACIÓN"],
                dimensiones: [
                    "7%",
                    "20%",
                    "13%",
                    "20%",
                    "10%",
                    "20%",
                    "10%"],
                mindimensiones: [
                    "100px",
                    "250px",
                    "150px",
                    "250px",
                    "150px",
                    "250px",
                    "100px"],
                elementos: [],
            },
            tipomedios: [],
            familias: [],
            respaldoclientes: [],
            clientes: [],
            servicios: [],
            listamodal: [],
            modalelementos: false,
            tipoaccion: null,


            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            loadingImage: true,
            actualordenado: "100",
            tipomensaje: 1,

            jsoncsv: "",
            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: ""
        }
    }



    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {

        this.setState({ loadingImage: true, showmensaje: false })

        const lista = await administracion_cuentas();
        if (lista.code == 0) {
            let listaitems = this.state.clientes;
            listaitems = lista.data.items;

            let listamedio = await catalogo_mediosServicios();
            //console.log(JSON.stringify(listamedio));
            let listafamilia = await catalogo_familia();
            //console.log(JSON.stringify(listafamilia));
            let listaservicios = await catalogo_servicios();
            //console.log(JSON.stringify(listaservicios));


            this.setState({ clientes: listaitems, respaldoclientes: listaitems, servicios: listaservicios.data.items, familias: listafamilia.data.items, tipomedios: listamedio.data.items, actualordenado: "100", showmensaje: false });

            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
            this.setState({ loadingImage: false })
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            this.setState({ loadingImage: false })
        }



    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        this.setState({ loadingImage: true })
        this.refreshInfo()
    }




    async buscarseleccionado(seleccionado) {
        //console.log("llega aki XD")
        //console.log(JSON.stringify(seleccionado));
        //console.log(seleccionado[2] + ", " + seleccionado[3]);
        this.setState({ loadingImage: true, showmensaje: false })
        let bitacora = await Reporte_consulta_clientes(seleccionado[2], seleccionado[3], seleccionado[6], seleccionado[8]);


        if (bitacora.code == 0) {
            let nuevatabla = this.state.tabla;
            nuevatabla.elementos = bitacora.data.items;
            //paginarlista
            this.setState({ tabla: nuevatabla });
            this.handleCommentsChange(1)
            var temarray = nuevatabla.elementos;

            let date = new Date()
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let fechaaux = null

            if (month < 10) {
                fechaaux = (`${day}/0${month}/${year}`)
            } else {
                fechaaux = (`${day}/${month}/${year}`)
            }

            // console.log(JSON.stringify(dapapa))
            var array_datos = [];
            var array_datosr = [];

            array_datos = ["Familia", "Cliente", "Tipo de medio", "Tipo de servicio", "Fecha de gestión"];
            array_datosr.push(array_datos);
            array_datos = [seleccionado[7], seleccionado[4], seleccionado[9], seleccionado[5], fechaaux];
            array_datosr.push(array_datos);

            array_datosr.push(this.state.tabla.encabezados)

            for (let i = 0; i < temarray.length; i++) {

                array_datos = [temarray[i].idcuentacanalarchivosservicio,
                temarray[i].familia,
                temarray[i].cuenta,
                temarray[i].cliente,
                temarray[i].canal,
                temarray[i].servicio,
                temarray[i].clave]

                array_datosr.push(array_datos)
            }

            this.setState({ jsoncsv: array_datosr })

        } else {
            if (bitacora.code == 1) {
                toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (bitacora.code == 2) {
                toast.warn(bitacora.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false });
    }


    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }


    handleCommentsChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)
            });
        }, 0);

    }







    crearelemento = async (origen) => {
        this.setState({ loadingImage: true, showmensaje: false })

        let tituloactivo = (origen[0] == 2) ? ("Detalle de integracion a los sistemas legados") : "Detalle";

        //llenar la tabla 
        let lista = await administracion_bitacora_desglose(origen[1]);


        if (lista.code == 0) {
            if (origen[0] == 1) {
                this.setState({ tipoaccion: origen, modalelementos: !this.state.modalelementos, opcion: origen[0], modaltitulo: tituloactivo, listamodal: lista.data.items.errores });
            }

            else {
                this.setState({ tipoaccion: origen, modalelementos: !this.state.modalelementos, opcion: origen[0], modaltitulo: tituloactivo, listamodal: lista.data.items.correctos });
            }

        }
        else {
            if (lista.code == 1) {
                toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }
        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);

    }


    ordenar = (opcion) => {
        let actual = this.state.tabla;
        let x = ordenartipodeservicio(opcion, actual, this.state.actualordenado);
        actual.elementos = x;
        this.setState({ tabla: actual, actualordenado: opcion });
        this.handleCommentsChange(this.state.activePage)
    }



    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                {(this.state.modalelementos) ? (
                    <ModalBitacora
                        show={this.state.modalelementos}
                        tipoaccion={this.state.tipoaccion}
                        modaltitulo={this.state.modaltitulo}
                        listamodal={this.state.listamodal}
                        opcion={this.state.opcion}
                        changestate={() => this.cerrarmodalcatalogo()} />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body panel-bitacora ">

                        <div className="panel-body-header-table" style={{ height: "initial", display: "block", minWidth: "450px" }}>
                            {this.state.titulotabla}
                            {(this.state.clientes.length > 0) ? (
                                <FiltrosConsultaClientes listafamilia={this.state.familias} listamedios={this.state.tipomedios}
                                    listaclientes={this.state.clientes} respaldoclientes={this.state.respaldoclientes}
                                    listaservicios={this.state.servicios} array_heders={this.state.tabla.encabezados}
                                    buscarseleccionado={(e) => this.buscarseleccionado(e)} arraydatos={this.state.tabla.elementos} 
                                    arraycsv={this.state.jsoncsv}
                                />
                            ) : null
                            }
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :
                            <>
                                {(this.state.tabla.elementos.length > 0) ? (
                                    <>
                                        <Table className="panel-table" >
                                            <thead style={{ verticalAlign: "top" }}>
                                                <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                            style={{ width: this.state.tabla.dimensiones[index], minWidth: this.state.tabla.mindimensiones[index], fontSize: "13px" }}
                                                            onClick={() => this.ordenar(index)}>
                                                            {titulo}
                                                            {(index < 6) ? (
                                                                <FaSort className={(this.state.actualordenado == index) ? ("tables-iconsort tables-actualsorting") : "tables-iconsort"} />
                                                            ) : null}

                                                        </th>
                                                    ))}
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.listapaginada.map((item, index) => (
                                                    <tr key={"tablaitemtr" + index} style={{ fontSize: "13px" }}>
                                                        <td className={"panel-table-cell" + 0}>
                                                            {item.idcuentacanalarchivosservicio}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.familia}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cuenta}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cliente}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.canal}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.servicio}
                                                        </td>

                                                        <td className={"panel-table-cell" + 4}>
                                                            <b>{item.clave}</b>
                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>

                                    </>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }

                                <div className="panel-pagination-section">
                                    <Paginacion
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsperpage}
                                        totalItemsCount={this.state.tabla.elementos}
                                        pageRangeDisplayed={this.state.rangepages}
                                        handleCommentsChange={(e) => this.handleCommentsChange(e)}
                                    />
                                </div>
                            </>
                        }

                    </div>

                    <div className="panel-footer">
                        <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>
            </>
        );
    }
}