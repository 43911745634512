import React from "react";
import Pagination from "react-js-pagination";

export default class Paginacion extends React.Component {
    constructor(props) {
        super(props);
     
    }

    render() {
        return (
            <>
                <Pagination
                    className="pagination-container"
                    activePage={this.props.activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={this.props.totalItemsCount.length}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.props.handleCommentsChange}

                    prevPageText={"Anterior"}
                    nextPageText={"Siguiente"}

                    activeClass={"paginacionActiva"}
                    itemClass={"paginacionItem"}

                    itemClassPrev={"paginacionPrevio"}
                    itemClassNext={"paginacionSiguiente"}

                    disabledClass={"paginacionDisabled"}
                    hideFirstLastPages={true}
                    
                />
            </>
        )
    }
}




export function buildContent(itemsperpage, page, lista) {
    let list = lista;
    let content = [];
    let itemfinal = ((itemsperpage * page) - 1);

    if (itemfinal > list.length)
        itemfinal = list.length - 1;
    let iteminicial = (itemsperpage * (page - 1));
    for (let i = iteminicial; i <= itemfinal; i++) {
        if (i < list.length) {
            content.push(list[i]);
        }
    }
    return content;
}

