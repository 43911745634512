import React from "react";
import { BiEdit } from "react-icons/bi";
import { BsCheckLg, BsFillTrashFill } from "react-icons/bs";
import { Button, Label, Table } from "reactstrap";
import Inputform from "../../Inputform/Inputform";
import { TbEraser } from 'react-icons/tb'




export default class SeccionContactos extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>

                {/* mensajes sms*/}





                <div className="row">
                    <div className=" col-12 modal-cuentas-tablecontainer">
                        <p><b>Mensajes SMS</b></p>

                        {(this.props.tipovista != 3) ? (

                            <div className="formulario-sms">
                                <p><b>Contacto</b></p>
                                <div className="row">


                                    <div className="col-2 col-md-4 col-lg-2 col-sm-6 col-xs-12">
                                        <Inputform datosinput={this.props.inputsformulariosms[0]} valoringresado={(e) => this.props.guardarvalorsms(e)} />
                                    </div>


                                    <div className="col-3 col-md-4 col-lg-3 col-sm-6 col-xs-12">
                                        <Inputform datosinput={this.props.inputsformulariosms[1]} valoringresado={(e) => this.props.guardarvalorsms(e)} />
                                    </div>

                                    <div className="col-3 col-md-4 col-lg-3 col-sm-6 col-xs-12">
                                        <Inputform datosinput={this.props.inputsformulariosms[2]} valoringresado={(e) => this.props.guardarvalorsms(e)} />
                                    </div>
                                    <div className="col-2 col-md-4 col-lg-2 col-sm-6 col-xs-12">
                                        <Inputform datosinput={this.props.inputsformulariosms[3]} valoringresado={(e) => this.props.guardarvalorsms(e)} />
                                    </div>
                                    <div className="col-2 col-md-4 col-lg-2 col-sm-6 col-xs-12">

                                        <Label className="labelform-theme" >
                                            Situación:
                                        </Label>
                                        {(this.props.listasituaciones.length > 0) ? (

                                            <>
                                                <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                                    onChange={(e) => this.props.cambiarestadosms(e.target.value)}>
                                                    {(this.props.inputsformulariosms[4].contenido == "") ?
                                                        <option value={""} >Selecciona</option>
                                                        : null}
                                                    {this.props.listasituaciones.map((estado, indexestatus) => (
                                                        <>

                                                            {(estado.idcatalogoestatus == this.props.inputsformulariosms[4].contenido) ? (
                                                                <option value={indexestatus} selected>{estado.estatus}</option>
                                                            ) :
                                                                <option value={indexestatus} >{estado.estatus}</option>
                                                            }
                                                        </>
                                                    ))}
                                                </select>

                                            </>
                                        ) : "Necesario crear situaciones"}
                                    </div>


                                </div>
                                <div className="formulario-sms-botones-guardar">
                                    <Button className="cancelbutton" onClick={this.props.limpiarformulariosms}> <TbEraser /> Limpiar</Button>
                                    <Button className="bluebutton" onClick={() => this.props.agregarelemento()}> <BsCheckLg /> Guardar</Button>
                                </div>
                            </div>


                        ) :
                            null}



                        {(this.props.cuenta.contactossms.length > 0) ? (
                            <div style={{ width: "100%", overflowX: "scroll" }}>
                                <Table className="panel-table" >
                                    <thead>
                                        <tr>
                                            {this.props.headersms.map(head => (
                                                <th>{head}</th>
                                            ))}

                                            {(this.props.tipovista != 3) ? (// solo se activa cuando es nuevo o editar 
                                                <th></th>
                                            ) : null}

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.props.cuenta.contactossms.map((item, indexcontacto) => (
                                            <tr>
                                                <td style={{ width: "6%" }}>{item.idcuentacontactossms}</td>
                                                <td style={{ width: "10%" }}>{item.nombrecontacto}</td>
                                                <td style={{ width: "10%" }}>{item.puesto}</td>
                                                <td style={{ width: "10%" }}>{item.telefono}</td>
                                                <td style={{ width: "10%" }}>{item.estatus}</td>
                                                <td style={{ width: "10%" }}>{item.usuariocreacion}</td>
                                                <td style={{ width: "10%" }}>{item.creado}</td>
                                                <td style={{ width: "13%" }}>{item.usuarioactualizacion}</td>
                                                <td style={{ width: "10%" }}>{item.actualizado}</td>

                                                {(this.props.tipovista != 3) ? (// solo se activa cuando es nuevo o editar 
                                                    <td>
                                                        <div className="panel-table-buttons-aux" >
                                                            {(item.idcuentacontactossms == "") ? (
                                                                <>
                                                                    <div>
                                                                        <Button className="bluebutton modal-cuentas-tablero-button modal-cuentas-iconostableros"

                                                                            onClick={() => this.props.editarcontacto({ opcion: 1, indexcontacto: indexcontacto })}><BiEdit className="modal-cuentas-tablero-button" /> </Button>
                                                                    </div>
                                                                    <div>
                                                                        <Button className="redbutton modal-cuentas-tablero-button modal-cuentas-iconostableros" onClick={() => this.props.eliminarcontactosms(indexcontacto)}><BsFillTrashFill className="modal-cuentas-tablero-button" /> </Button>
                                                                    </div>
                                                                </>
                                                            ) :
                                                                <div>
                                                                    <Button className="bluebutton modal-cuentas-tablero-button modal-cuentas-iconostableros" onClick={() => this.props.editarcontacto({ opcion: 2, indexcontacto: indexcontacto })} ><BiEdit className="modal-cuentas-tablero-button" /> </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                ) : null}

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) :
                            <p><b>No hay contactos registrados</b></p>
                        }


                    </div>

                </div>


            </>
        )
    }
}