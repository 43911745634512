import React, { useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Font } from '@react-pdf/renderer'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ExportarConsultaBitacora extends React.Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <>
                <ToastContainer />
                {this.props.totalitems.length > 0 && this.props.arraycsv.length >0 ?
                    <CSVLink data={this.props.arraycsv} filename={"Bitacora.csv"} enclosingCharacter={``} separator={","} style={{margin:'auto' }} >
                        <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px", margin:'auto' }} />
                    </CSVLink>
                    :
                    <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px", margin:'auto' }} />
                }

            </>
        )
    }
}

