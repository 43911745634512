import React from "react";
import { Redirect } from "react-router-dom";
import { administracion_bitacora_desglose, administracion_cuentas, catalogo_familia, catalogo_servicios, obtener_volumen, obtener_volumen_detalles, obtener_volumen_detalles_general } from "../../Services/Api";
import ModalBitacora from "../Modales/ModalBitacora";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import AreaMensajes from "../Modales/AreaMensajes";
import { Button, Table } from "reactstrap";
import Paginacion, { buildContent } from "../Panel/Paginacion";
import Nabvar from "../Panel/Nabvar";
import FiltrosVolumenOperado from "./Complementos/FiltrosVolumenOperado";
import { BsEyeFill } from "react-icons/bs";

export default class VolumenOperado extends React.Component {

    constructor(props) {
        super(props)

        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }

        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Volumen Operado por Clientes y Tipos de servicio",
            tabla: {
                encabezados: [
                    "Tipo medio",
                    "Tipo Servicio",
                    "Familia",
                    "Cliente",
                    "Cta Origen",
                    "Divisa",
                    "Importe",
                    "Importe Operado",
                    "Importe No Operado",
                    "",
                    "",
                    ""
                ],
                dimensiones: [
                    "5%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                    "8%",
                    "12%",
                    "12%",
                    "12%",
                    "6%",
                    "6%"],
                elementos: [],
                detalles: []

            },
            arrayPDF: [],

            header4: [
                { titulo: "", longitud: "50px" },
                { titulo: "ID Bitácora", longitud: "90px" },
                { titulo: "ID Folio", longitud: "90px" },
                { titulo: "Fecha proceso", longitud: "110px" },
                { titulo: "No. Renglón", longitud: "70px" },
                { titulo: "Divisa", longitud: "70px" },
                { titulo: "Importe Operación", longitud: "140px" },
                { titulo: "Importe Operado", longitud: "140px" },
                { titulo: "Importe No Operado", longitud: "140px" },
                { titulo: "Archivo", longitud: "170px" },
                { titulo: "Código Descripción", longitud: "190px" },
                { titulo: "Descripción del pago", longitud: "180px" }
            ],

            fechaactual: fechaaux,
            jsoncsv: "",
            familia: [],
            clientes: [],
            servicios: [],
            legado: [{
                idlegado: 1,
                legado: "Procesados legado"
            },
            {
                idlegado: 2,
                legado: "Incorrecto de legado"
            }],
            listamodal: [],
            modalelementos: false,
            tipoaccion: null,


            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            loadingImage: true,
            actualordenado: "",
            tipomensaje: 1,

            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: "",

            fechainicio: '',
            fechafin: '',
            opcion: 0,
            abrirdetalles: "normal"
        }
    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        this.setState({ loadingImage: true })


        await this.refreshInfo();


    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {
        this.setState({ loadingImage: true, showmensaje: false })

        const lista = await administracion_cuentas();
        if (lista.code == 0) {
            let listaitems = this.state.clientes;
            listaitems = lista.data.items;


            let listafamilia = await catalogo_familia();
            let listaservicios = await catalogo_servicios();
            this.setState({ clientes: listaitems, servicios: listaservicios.data.items, actualordenado: "", familia: listafamilia.data.items, showmensaje: false });

            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }


    }



    async activa_detalles_check(seleccionado) {

        /*
            "idcuentassolicitudesservicios":"4",
            "idcuentassolicitudesserviciosvalidados":"55",
            "fila":"16",
            "archivo":"PruebaS104.csv",
            "fechasolicitudlegado":"2023-01-27",
            "divisa":"USD",
            "importe":"0.00",
            "operado":"0.00",
            "nooperado":"0.00",
            "codigodescripcion":"9002 La transacción no fue procesada en el sistema legado",
            "concepto":""
        */



    }

    async buscarseleccionado(seleccionado) {

        this.setState({ abrirdetalles: seleccionado[10] })
        let nuevatabla = this.state.tabla;
        await this.setState({ loadingImage: true, arrayPDF: [], showmensaje: false, fechainicio: seleccionado[6], fechafin: seleccionado[7] })
        //console.log(JSON.stringify(seleccionado))
        if (seleccionado[10] == "detalles") {


            //console.log("llego aqui ")
            let resp = await obtener_volumen_detalles_general(seleccionado[0], seleccionado[2], seleccionado[4], seleccionado[6], seleccionado[7], seleccionado[9]);
            if (resp.code == 0) {


                var newitems = resp.data.items;
                nuevatabla.detalles = resp.data.items;


                await this.setState({ tabla: nuevatabla });

                // console.log(JSON.stringify(nuevatabla))
                this.handleCommentsChange(1)


                var array_datos = [];
                var array_datosr = [];


                array_datos = ["Volumen Operado por Clientes y Tipos de servicios"];
                array_datosr.push(array_datos);

                array_datos = ["FECHA", "FAMILIA", "CLIENTE", "TIPO DE SERVICIO", "FECHA INICIO", "FECHA FIN", "PROCESADO LEGADO"];
                array_datosr.push(array_datos);
                array_datos = [this.state.fechaactual, seleccionado[1], seleccionado[3], seleccionado[5], seleccionado[6], seleccionado[7], seleccionado[8]];
                array_datosr.push(array_datos);
                array_datosr.push(nuevatabla.encabezados);


                array_datos = ["", "ID Bitácora", "ID Folio", "Fecha proceso", "No. Renglón", "Divisa", "Importe Operación", "Importe Operado", "Importe No Operado", "Archivo", "Código Descripción", "Descripción del pago"];
                array_datosr.push(array_datos);

                var temarray = newitems;
                for (let i = 0; i < temarray.length; i++) {

                    if (temarray[i].totales == false) {

                        array_datos = [
                            temarray[i].canal,
                            temarray[i].servicio,
                            temarray[i].familia,
                            temarray[i].cliente,
                            temarray[i].cuenta,
                            temarray[i].divisa,
                            temarray[i].importe,
                            temarray[i].operado,
                            temarray[i].nooperado
                        ]

                        array_datosr.push(array_datos)

                        var arraydetalles = [];
                        for (let j = 0; j < temarray[i].detalles.length; j++) {
                            if (temarray[i].detalles[j].totales == false) {
                                arraydetalles = [
                                    "",
                                    temarray[i].detalles[j].idcuentassolicitudesservicios,
                                    temarray[i].detalles[j].idcuentassolicitudesserviciosvalidados,
                                    temarray[i].detalles[j].fechasolicitudlegado,
                                    temarray[i].detalles[j].fila,
                                    temarray[i].detalles[j].divisa,
                                    temarray[i].detalles[j].importe,
                                    temarray[i].detalles[j].operado,
                                    temarray[i].detalles[j].nooperado,
                                    temarray[i].detalles[j].archivo,
                                    temarray[i].detalles[j].codigodescripcion,
                                    temarray[i].detalles[j].concepto

                                ];
                            }
                            else {
                                arraydetalles = [
                                    "Sub Total",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    temarray[i].detalles[j].importe,
                                    temarray[i].detalles[j].operado,
                                    temarray[i].detalles[j].nooperado,
                                ]
                            }
                            array_datosr.push(arraydetalles)
                        }


                    }
                    else {
                        array_datos = [
                            "Total",
                            "",
                            "",
                            "",
                            "",
                            "",
                            temarray[i].importe,
                            temarray[i].operado,
                            temarray[i].nooperado
                        ]
                        array_datosr.push(array_datos);
                    }





                }
                await this.setState({ arrayPDF: newitems });
                //  console.log(JSON.stringify(array_datosr))
                await this.setState({ jsoncsv: array_datosr });

                this.setState({ abrirdetalles: seleccionado[10] })

            } else {
                if (resp.code == 1) {
                    toast.warn("Ha ocurrido un problema, vuelve a intentara", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                else if (resp.code == 2) {
                    toast.warn(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                else {
                    toast.error("Revisa tu conexión a interneta", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    this.mensaje(0, "Sin conexión", "Revisa tu conexión a interneta")
                }
            }


        }
        else {

            nuevatabla.elementos = [];

            //console.log("llego al else ")

            let bitacora = await obtener_volumen(seleccionado[0], seleccionado[2], seleccionado[4], seleccionado[6], seleccionado[7], seleccionado[9]);
            if (bitacora.code == 0) {


                var newitems = bitacora.data.items;
                let nuevatabla = this.state.tabla;
                nuevatabla.elementos = bitacora.data.items;

                //console.log(JSON.stringify(nuevatabla))
                this.setState({ tabla: nuevatabla });
                this.handleCommentsChange(1)


                var array_datos = [];
                var array_datosr = [];

 
                array_datos = ["Volumen Operado por Clientes y Tipos de servicios"];
                array_datosr.push(array_datos);
             
                array_datos = ["FECHA", "FAMILIA", "CLIENTE", "TIPO DE SERVICIO", "FECHA INICIO", "FECHA FIN", "PROCESADO LEGADO"];
                array_datosr.push(array_datos);
                array_datos = [this.state.fechaactual, seleccionado[1], seleccionado[3], seleccionado[5], seleccionado[6], seleccionado[7], seleccionado[8]];
                array_datosr.push(array_datos);
                array_datosr.push(nuevatabla.encabezados);
                var temarray = newitems;

                for (let i = 0; i < temarray.length; i++) {

                    if (temarray[i].totales == false) {
                        array_datos = [
                            temarray[i].canal,
                            temarray[i].servicio,
                            temarray[i].familia,
                            temarray[i].cliente,
                            temarray[i].cuenta,
                            temarray[i].divisa,
                            temarray[i].importe,
                            temarray[i].operado,
                            temarray[i].nooperado
                        ]
                    }
                    else {
                        array_datos = [
                            "Total",
                            "",
                            "",
                            "",
                            "",
                            "",
                            temarray[i].importe,
                            temarray[i].operado,
                            temarray[i].nooperado
                        ]
                    }
                    array_datosr.push(array_datos);
                }

                await this.setState({ arrayPDF: newitems });
                //console.log(JSON.stringify(nuevatabla))
                await this.setState({ jsoncsv: array_datosr });
                this.setState({ abrirdetalles: seleccionado[10] })

            } else {
                if (bitacora.code == 1) {
                    toast.warn("Ha ocurrido un problema, vuelve a intentara", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                else if (bitacora.code == 2) {
                    toast.warn(bitacora.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                else {
                    toast.error("Revisa tu conexión a interneta", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    this.mensaje(0, "Sin conexión", "Revisa tu conexión a interneta")
                }
            }


        }
        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 800);

    }


    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false });
    }


    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }


    handleCommentsChange = (pageNumber) => {


        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)
            });
        }, 0);

    }


    verdetalles = async (origen) => {
        //this.setState({ loadingImage: true, showmensaje: false })


        //console.log(JSON.stringify(origen));
        //console.log(this.state.fechainicio + " " + this.state.fechafin);


        var resp = await obtener_volumen_detalles(origen.idcatalogocanalarchivos, origen.idcuentas,
            origen.idcatalogoservicios, this.state.fechainicio, this.state.fechafin, origen.divisa);
        //console.log(JSON.stringify(resp));


        this.setState({
            opcion: 13, modaltitulo: "Volumen Operado por Clientes y Tipos de servicio Detalles",
            listamodal: resp.data.items, modalelementos: !this.state.modalelementos
        });


        //var resp = await obtener_volumen_detalles(idcatalogocanalarchivos, idcuentas, idcatalogoservicios, this.state.fech, "30/1/2023", "USD");
        //console.log(JSON.stringify(resp));


        /**
         {
  "token": "79FFE29697BCDDFE73059E74000D336D3695B016",
  "modulo": "1000",
  "idcatalogocanalarchivos": "1",
  "idcuentas": "1",
  "idcatalogoservicios": "1",
  "fechainicio": "1/1/2022",
  "fechafin": "30/1/2023",
  "divisa": "USD"
} 
         
         */

        //console.log(JSON.stringify(origen))



    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }

    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                {(this.state.modalelementos) ? (
                    <ModalBitacora
                        show={this.state.modalelementos}
                        tipoaccion={this.state.tipoaccion}
                        modaltitulo={this.state.modaltitulo}
                        listamodal={this.state.listamodal}
                        opcion={this.state.opcion}
                        changestate={() => this.cerrarmodalcatalogo()} />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body panel-bitacora ">

                        <div className="panel-body-header-table" style={{ height: "initial", display: "block", minWidth: "450px" }}>
                            {this.state.titulotabla}
                            {(this.state.clientes.length > 0) ? (
                                <FiltrosVolumenOperado
                                    buscarseleccionado={(e) => this.buscarseleccionado(e)}
                                    listaclientes={this.state.clientes}
                                    listafamilia={this.state.familia}
                                    listaservicios={this.state.servicios}
                                    listalegado={this.state.legado}
                                    array_heders={this.state.tabla.encabezados}
                                    sub_heders={this.state.header4}
                                    arraydatos={this.state.abrirdetalles == "normal" ? this.state.tabla.elementos : this.state.tabla.detalles}
                                    arraycsv={this.state.jsoncsv} />
                            ) : null
                            }
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :
                            <>
                                {(this.state.tabla.elementos.length > 0) ? (
                                    <>
                                        <Table className="panel-table">

                                            <thead style={{ verticalAlign: "top" }}>
                                                <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                            style={{ minWidth: this.state.tabla.dimensiones[index], fontSize: "12px" }}>
                                                            {titulo}


                                                        </th>
                                                    ))}
                                                    <th></th>
                                                </tr>
                                            </thead>


                                            <tbody>
                                                {this.state.listapaginada.map((item, index) => (

                                                    <>
                                                        {(item.totales == false) ?
                                                            <>
                                                                <tr key={"tablaitemtr" + index} style={{ fontSize: "13px" }}>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[1] }}>
                                                                        {item.canal}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[2] }}>
                                                                        {item.servicio}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[3] }}>
                                                                        {item.familia}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[4] }}>
                                                                        {item.cliente}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[5] }}>
                                                                        {item.cuenta}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[6] }}>
                                                                        {item.divisa}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[7] }}>
                                                                        {this.format_moneda(item.importe)}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[8] }}>
                                                                        {this.format_moneda(item.operado)}
                                                                    </td>
                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[9] }}>
                                                                        {this.format_moneda(item.nooperado)}
                                                                    </td>

                                                                    <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[10] }}>
                                                                        <div style={{ width: "29px", height: "29px", margin: 'auto' }}>
                                                                            <Button className="bluebutton" style={{ width: "29px", height: "29px", border: 'none' }}
                                                                                onClick={this.verdetalles.bind(this, item)}><BsEyeFill style={{ marginTop: '-4' }} /></Button>
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                            </>
                                                            :

                                                            <tr key={"tablaitemtr"}>

                                                                <td className={"panel-table-cell" + 3} style={{ minWidth: this.state.tabla.dimensiones[0], width: this.state.tabla.dimensiones[0], fontSize: "14px" }}>
                                                                    <b>Total</b>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[1] }}>
                                                                </td>

                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[3] }}>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[4] }}>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[5] }}>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[6] }}>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[7] }}>
                                                                    <b>{this.format_moneda(item.importe)}</b>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[8] }}>
                                                                    <b>{this.format_moneda(item.operado)}</b>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[9] }}>
                                                                    <b>{this.format_moneda(item.nooperado)}</b>
                                                                </td>
                                                                <td className={"panel-table-cell" + 3} style={{ width: this.state.tabla.dimensiones[5] }}>

                                                                </td>

                                                            </tr>

                                                        }

                                                    </>

                                                ))}
                                            </tbody>

                                        </Table>


                                    </>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }

                                <div className="panel-pagination-section">
                                    <Paginacion
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsperpage}
                                        totalItemsCount={this.state.tabla.elementos}
                                        pageRangeDisplayed={this.state.rangepages}
                                        handleCommentsChange={(e) => this.handleCommentsChange(e)}
                                    />
                                </div>
                            </>
                        }

                    </div>

                    <div className="panel-footer">
                        <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>
            </>
        );
    }
}

function Formatocoma({ total }) {
    return <>{total.toString().replace("\.", ",")}</>;
}