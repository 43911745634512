import React from "react"
import { Button, Modal, ModalBody } from 'reactstrap';
import { BsCheckLg } from 'react-icons/bs';
import { GoX } from 'react-icons/go';
import { validarmedionombre, validartodo } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { nuevo_actualizar_cuenta } from "../../Services/Api";
import SpinnerModal from "./SpinnerModal/SpinnerModal";




export default class ModalParametrosSistema extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            loadingImage: false,
            show: this.props.show,
            inputsform: this.props.elementosinput,
            modaltitulo: "Nuevo valor para " + props.valores.parametro,
            txtvalor: props.valores.valor,
            txtdescripcion: props.valores.descripcion,

        }
    }


    async componentDidMount() {

        //obtener lista de situaciones

    }

    guardarvalor = (item) => {
        let listainputs = this.state.inputsform
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;
        });
        this.setState({ inputsform: listainputs })
    }

    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }

    verestatus = () => {
        this.setState({ colapsar: !this.state.colapsar })
    }
    verservicio = () => {
        this.setState({ colapsarservicio: !this.state.colapsarservicio })
    }


    async guardarinfo() {
        this.setState({ loadingImage: true })

        let respuestavalidacion = true;
        let nombre = validartodo(this.state.txtvalor.trim())
        if (nombre.codigo == "invalid") {
            respuestavalidacion = false;
        }

        if (respuestavalidacion == false) {
            this.setState({ loadingImage: false })
            toast.warn(nombre.mensaje, { autoClose: 3000, position: toast.POSITION.TOP_LEFT })
        } else {

            //console.log(this.state.txtvalor)
            var resp = await nuevo_actualizar_cuenta(this.props.valores.parametro, this.state.txtvalor.trim());
            //console.log(resp)

            this.showmodal(1);
        }

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }



    render() {
        return (
            <>

                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    // toggle={this.showmodal}
                    className="modal-catalogo">
                    <ModalBody>
                        <div>

                            <p className="modal-titulo">{this.state.modaltitulo}</p>
                            <br />
                            <input className="inputform-theme" onChange={this.escribir("txtvalor")} style={{ padding: '8px', fontSize: "medium", textAlign: "center", width: "100%" }} placeholder="Escribe un valor" value={this.state.txtvalor} />

                            <br />
                            <label style={{fontSize:"small", width: "100%", textAlign:"justify", paddingLeft:"5px"}}><b>Descripción:</b> {this.state.txtdescripcion}</label>
                            <br />
                            <br />

                            <div className="modal-catalogo-checkinput" style={{ margin: "auto", marginBottom: "15px" }} onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar envío de formulario
                            </div>

                            <div className="modal-catalogos-buttons" style={{ marginTop: "0px" }}>
                                <Button className="cancelbutton" onClick={() => this.showmodal()}><GoX /> Cancelar</Button>
                                <Button disabled={!this.state.confirmarenvio} className="bluebutton" onClick={() => this.guardarinfo()}> <BsCheckLg /> Guardar</Button>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

