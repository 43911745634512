import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { RiRefreshLine } from "react-icons/ri";
import { FaSort } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { catalogo_editarsituacion, catalogo_nuevasituacion, catalogo_situaciones } from "../../Services/Api";
import { validarsituacionclave, validarsituaciondescripcion } from "../../Services/Validaciones";
import ModalCatalogo from "../Modales/ModalCatalogo";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import { BotonesVerEditar } from "../Panel/BotonesVerEditar";
import Nabvar from "../Panel/Nabvar";
import { itemactivoSituacion, listainputsSituacion, ordenarListainputsSituacion } from "./Complementos/OrdenarFormularios";
import { ordenarsituacion } from "./Complementos/Ordenamientos";
import AreaMensajes from "../Modales/AreaMensajes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paginacion, { buildContent } from "../Panel/Paginacion";

export default class Situacion2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Situación",
            botoncrear: "Crear nueva situación",
            tabla: {
                encabezados: ["ID", "Descripción", "Situación"],
                dimensiones: ["30%", "40%", "20%"],
                elementos: []
            },
            modalelementos: false,
            tipoaccion: null,
            modaltitulo: "",
            modalelementosinput: listainputsSituacion(),
            itemactivo: null,
            itemtitulos: ["ID", "Situacion", "Descripción", "Creado por", "Fecha de creación", "Actualizado por", "Fecha de actualización"],
            itemver: [],
            permisoedicion: false,

            actualordenado: "",
            loadingImage: true,
            tipomensaje: 1,

            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: "",


            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        const lista = await catalogo_situaciones();
        if (lista.code == 0) {
            let listaitems = this.state.tabla;
            listaitems.elementos = lista.data.items;
            this.setState({ tabla: listaitems, actualordenado: "", showmensaje: false })
            this.handleCommentsChange(this.state.activePage)
            //toast.success("Carga de tabla exitosa", { autoClose: 4000, theme: "dark",  position: toast.POSITION.TOP_CENTER })
            //verificar permisos
            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        //console.log("Situacion");
        await this.refreshInfo();
    }


    crearelemento = (item) => {
        this.setState({ tipoaccion: item.origen });
        let inputs = listainputsSituacion();

        if (item.origen == 1) {
            let elemento = this.guardaritemactivo("");
            let inputsordenados = ordenarListainputsSituacion(item.origen, inputs, elemento);
            this.setState({ modalelementosinput: inputsordenados })
            this.estadosmodalcatalogo([], "Crear Situación");
        }

        else if (item.origen == 2) {
            let elemento = this.guardaritemactivo(item.idelemento);
            let inputsordenados = ordenarListainputsSituacion(item.origen, inputs, elemento);
            this.setState({ modalelementosinput: inputsordenados })
            this.estadosmodalcatalogo([], "Editar Situación");
        }

        else if (item.origen == 3) {
            let elemento = this.guardaritemactivo(item.idelemento)
            let lista = [
                elemento.idcatalogoestatus,
                elemento.clave,
                elemento.estatus,
                elemento.usuariocreacion,
                elemento.creado,
                elemento.usuarioactualizacion,
                elemento.actualizado
            ];
            //  let itemtitulos = ["ID", "Situación", "Descripción", "Creado por", "Fecha de creación", "Actualizado por", "Fecha de actualización"]
            this.estadosmodalcatalogo(lista, "Información Situación");
        }
    }

    estadosmodalcatalogo = (lista, encabezadomodal) => {
        this.setState({
            modalelementos: true,
            itemver: lista,
            // itemtitulos: itemtitulos,
            modaltitulo: encabezadomodal
        });
    }

    guardaritemactivo = (idelemento) => {
        let elemento = [];
        if (idelemento != "")
            elemento = this.state.tabla.elementos.find(x => x.idcatalogoestatus == idelemento);
        else elemento = []

        let nuevoelemento = itemactivoSituacion(idelemento, elemento);
        this.setState({ itemactivo: nuevoelemento[0] });
        return nuevoelemento[0]
    }

    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false, modalelementosinput: listainputsSituacion(), showmensajemodal: false, loadingImage: false });
    }

    respuestaformulario = async (arreglo) => {
        this.setState({ loadingImage: true, showmensaje: false })
        let respuestavalidacion = await this.validarcontenido(arreglo);
        return respuestavalidacion
    }

    validarcontenido = async (contenido) => {
        let respuestavalidacion = true;
        if (this.state.tipoaccion == 1) {
            let respuestasituacion = validarsituacionclave(contenido[1].valor)
            contenido[1].mensajevalidacion = respuestasituacion.mensaje;
            contenido[1].validacion = respuestasituacion.codigo;
            if (respuestasituacion.codigo == "invalid")
                respuestavalidacion = false;
        }

        let respuestadescripcion = validarsituaciondescripcion(contenido[2].valor)
        contenido[2].mensajevalidacion = respuestadescripcion.mensaje;
        contenido[2].validacion = respuestadescripcion.codigo;
        if (respuestadescripcion.codigo == "invalid")
            respuestavalidacion = false;

        //detener spinner si invalid 
        if (respuestavalidacion == true) {
            respuestavalidacion = await this.enviardatos(contenido);

            if (respuestavalidacion == false) {
                setTimeout(() => {
                    this.setState({ loadingImage: false })
                }, 200);                
            }
        }
        else {
            this.setState({ loadingImage: false })
        }

        return { aprobado: respuestavalidacion, nuevoarreglo: contenido }
    }

    finalizaraccion = async () => {
        this.cerrarmodalcatalogo();
        await this.refreshInfo();
    }

    enviardatos = async (datos) => {
        let clave = datos[1].valor;
        let estatus = datos[2].valor;
        let enviado = null;

        if (this.state.tipoaccion == 1) {
            enviado = await catalogo_nuevasituacion(clave, estatus);
        }
        else if (this.state.tipoaccion == 2) {
            enviado = await catalogo_editarsituacion(clave, estatus, this.state.itemactivo.idcatalogoestatus);
        }

        if (enviado.code == 0) {
            toast.success("Situación guardada exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else {
            if (enviado.code == 1) {
                toast.warn("No se pudo guardar, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (enviado.code == 2) {
                toast.warn(enviado.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensajemodalcatalogo(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            return false
        }
    }

    handleCommentsChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)
            });
        }, 0);
    }


    ordenar = (opcion) => {
        let actual = this.state.tabla;
        let x = ordenarsituacion(opcion, actual, this.state.actualordenado);
        actual.elementos = x;
        this.setState({ tabla: actual, actualordenado: opcion });
        this.handleCommentsChange(this.state.activePage)
    }

    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);

    }

    mensajemodalcatalogo(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensajemodal: titulo, descripcionmensajemodal: mensaje })
        setTimeout(() => {
            this.setState({ showmensajemodal: true, loadingImage: false })
        }, 200);
    }



    listaToast(opcion) {

    }



    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                <ToastContainer />

                {(this.state.modalelementos) ? (
                    <ModalCatalogo
                        show={this.state.modalelementos}
                        tipoaccion={this.state.tipoaccion}
                        catalogo={"1"}
                        modaltitulo={this.state.modaltitulo}
                        elementosinput={this.state.modalelementosinput}
                        itemactivo={this.state.itemactivo}
                        itemtitulos={this.state.itemtitulos}
                        itemver={this.state.itemver}
                        changestate={() => this.cerrarmodalcatalogo()}
                        respuestaformulario={(e) => this.respuestaformulario(e)}
                        finalizaraccion={(e) => this.finalizaraccion(e)}
                        showmensaje={this.state.showmensajemodal}
                        titulo={this.state.titulomensajemodal}
                        mensaje={this.state.descripcionmensajemodal}
                    />
                ) : null}


                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body ">

                        <div className="panel-body-header-table">
                            {this.state.titulotabla}
                            <div className="panel-body-header-table-buttons ">

                                <div>
                                    <Button className="greenoutlinebutton" onClick={this.refreshInfo}><RiRefreshLine /> Refrescar Lista</Button>
                                </div>
                                <div >
                                    <Button
                                        className={(this.state.permisoedicion) ? ("bluebutton") : "cancelbutton"}
                                        disabled={(this.state.permisoedicion) ? (false) : true}
                                        onClick={(this.state.permisoedicion) ? (
                                            () => this.crearelemento({ origen: 1, idelemento: "" })
                                        ) : (
                                            () => console.log("no tienes permiso")
                                        )
                                        }
                                    > <BsPlusLg /> {this.state.botoncrear}</Button>
                                </div>
                            </div>
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>
                                {(this.state.tabla.elementos.length > 0) ? (
                                    <Table className="panel-table"  >
                                        <thead>
                                            <tr>
                                                {this.state.tabla.encabezados.map((titulo, index) => (
                                                    <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                        style={{ width: this.state.tabla.dimensiones[index] }}
                                                        onClick={() => this.ordenar(index)}>
                                                        {titulo} <FaSort className={(this.state.actualordenado == index) ? ("tables-iconsort tables-actualsorting") : "tables-iconsort"} />
                                                    </th>
                                                ))}
                                                <th></th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {this.state.listapaginada.map((item, index) => (
                                                <tr key={"tablaitemtr" + index}>
                                                    <td className={"panel-table-cell" + 0}>
                                                        {item.idcatalogoestatus}
                                                    </td>
                                                    <td className={"panel-table-cell" + 1}>
                                                        {item.estatus}
                                                    </td>
                                                    <td className={"panel-table-cell" + 4 + " item-status"}>
                                                        {item.clave}
                                                    </td>
                                                    <td className="panel-table-buttons">
                                                        <BotonesVerEditar permisoedicion={this.state.permisoedicion} id={item.idcatalogoestatus} crearelemento={this.crearelemento} />
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }

                            </>




                        }


                        <div className="panel-pagination-section">
                            <Paginacion
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsperpage}
                                totalItemsCount={this.state.tabla.elementos}
                                pageRangeDisplayed={this.state.rangepages}
                                handleCommentsChange={(e) => this.handleCommentsChange(e)}
                            />
                        </div>

                    </div>

                    <div className="panel-footer">
                    <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>
            </>
        );
    }
}