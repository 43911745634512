import React from "react"
import { Dropdown, Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Label, FormFeedback, FormGroup } from 'reactstrap';
import Inputform from "../Inputform/Inputform";
import { BsCheckLg } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import { GoX } from 'react-icons/go';
import { catalogo_servicios, catalogo_situaciones } from "../../Services/Api";
import AreaMensajes from "./AreaMensajes";

export default class ModalCatalogo extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            show: this.props.show,
            inputsform: this.props.elementosinput,
            //fecha: this.fecha(),
            colapsar: false,
            colapsarservicio: false,
            listasituacion: [],
            listaservicios: [],
            iteminfo: JSON.parse(JSON.stringify(this.iteminfo())),

            confirmarenvio: false,
            botonguardarinactivo: true,
            mostrarbotonesformulario: false
        }
    }

    iteminfo = () => {
        let x = []
        x.push(this.props.itemactivo)
        return x[0]
    }

    fecha = () => {
        let fecha = new Date();
        let now = fecha.toLocaleDateString('es-MX');
        return now
    }

    async componentDidMount() {

        //obtener lista de situaciones
        const listasituaciones = await catalogo_situaciones();
        if (listasituaciones.code == 0) {
            let listaitems = [];
            listaitems = listasituaciones.data.items;
            this.setState({ listasituacion: listaitems })
        }
        else
            this.setState({ listasituacion: [] })

        const listaservicios = await catalogo_servicios();
        if (listaservicios.code == 0) {
            let listaitems = [];
            listaitems = listaservicios.data.items;
            this.setState({ listaservicios: listaitems })
        }
        else
            this.setState({ listasituacion: [] })



        if (
            this.props.catalogo == "2" ||
            this.props.catalogo == "3" ||
            this.props.catalogo == "4" ||
            this.props.catalogo == "5" ||
            this.props.catalogo == "6" ||
            this.props.catalogo == "8" ||
            this.props.catalogo == "9"

        ) {
            if (this.state.listasituacion.length > 0) {
                this.setState({ mostrarbotonesformulario: true });
                this.props.avisolistas({ validar: true, opcion: 0 });
            }
            else {
                this.setState({ mostrarbotonesformulario: false });
                if (listasituaciones.code == 0)
                    this.props.avisolistas({ validar: false, opcion: 0 });
                else if (listasituaciones.code == 1)
                    this.props.avisolistas({ validar: false, opcion: 1 });
                else
                    this.props.avisolistas({ validar: false, opcion: 2 });
            }
        }
        else if (this.props.catalogo == "7") {

            setTimeout(() => {


                if (this.state.listasituacion.length > 0 && this.state.listaservicios.length > 0) {
                    this.setState({ mostrarbotonesformulario: true });
                    //console.log(listasituaciones.code, listaservicios.code)

                    this.props.avisolistas({ validar: true, opcion: 100 });
                }
                else {
                    this.setState({ mostrarbotonesformulario: false });
                    //console.log(listasituaciones.code, listaservicios.code);
                    let opcionactiva = ""
                    if (listasituaciones.code == 0)
                        opcionactiva = 0
                    else if (listasituaciones.code == 1)
                        opcionactiva = 1
                    else if (listaservicios.code == 0)
                        opcionactiva = 2
                    else if (listaservicios.code == 1)
                        opcionactiva = 3
                    else
                        opcionactiva = ""

                    this.props.avisolistas({ validar: false, opcion: opcionactiva });
                }

            }, 300);


        }

        else {
            this.setState({ mostrarbotonesformulario: true });
        }


    }

    guardarvalor = (item) => {
        //console.log("guardarvalor ern modal");
        let listainputs = this.state.inputsform
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;
        });
        this.setState({ inputsform: listainputs })
    }

    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }

    verestatus = () => {
        this.setState({ colapsar: !this.state.colapsar })
    }
    verservicio = () => {
        this.setState({ colapsarservicio: !this.state.colapsarservicio })
    }

    cambiarestado = (nuevoestado) => {
        let item = this.state.iteminfo;
        item.estatus = nuevoestado.estatus

        let inputsform = this.state.inputsform
        if (this.props.catalogo == "2") {//envio de resultados
            inputsform[2].valor = nuevoestado.estatus;
            inputsform[2].contenido = nuevoestado.idcatalogoestatus;

        }
        if (this.props.catalogo == "3") {//servicios
            inputsform[5].valor = nuevoestado.estatus
            inputsform[5].contenido = nuevoestado.idcatalogoestatus;
        }
        if (this.props.catalogo == "4") {//monedas
            inputsform[3].valor = nuevoestado.estatus
            inputsform[3].contenido = nuevoestado.idcatalogoestatus;
        }
        if (this.props.catalogo == "5") {//errores
            inputsform[3].valor = nuevoestado.estatus
            inputsform[3].contenido = nuevoestado.idcatalogoestatus;
        }
        if (this.props.catalogo == "6") {//medios de servicio
            inputsform[3].valor = nuevoestado.estatus
            inputsform[3].contenido = nuevoestado.idcatalogoestatus;
        }
        if (this.props.catalogo == "7") {//asesores de servicio
            inputsform[4].valor = nuevoestado.estatus
            inputsform[4].contenido = nuevoestado.idcatalogoestatus;
            //inputsform[3].valor = nuevoestado.servicio
            //inputsform[3].contenido = nuevoestado.idcatalogoservicios;
        }
        if (this.props.catalogo == "8") {//familia
            inputsform[3].valor = nuevoestado.estatus
            inputsform[3].contenido = nuevoestado.idcatalogoestatus;
        }
        if (this.props.catalogo == "9") {//tipo de operacion
            inputsform[5].valor = nuevoestado.estatus
            inputsform[5].contenido = nuevoestado.idcatalogoestatus;
        }
        if (this.props.catalogo == "10") {//estado de cuenta
            inputsform[3].valor = nuevoestado.estatus
            inputsform[3].contenido = nuevoestado.idcatalogoestatus;
        }

        this.setState({ iteminfo: item, inputsform: inputsform })
        //console.log("nuevos valores ")
        //console.log(item)
    }

    cambiarestadoservicio = (nuevoestado) => {
        let item = this.state.iteminfo;
        item.servicio = nuevoestado.servicio;
        item.idcatalogoservicios = nuevoestado.idcatalogoservicios;

        let inputsform = this.state.inputsform;
        inputsform[3].valor = nuevoestado.servicio;
        inputsform[3].contenido = nuevoestado.idcatalogoservicios;


        this.setState({ iteminfo: item, inputsform: inputsform })
        // console.log("nuevos valores ")
        // console.log(item)
    }


    async guardarinfo() {
        //console.log(this.state.inputsform);
        let nuevovalor = await this.props.respuestaformulario(this.state.inputsform); // enviar lista

        this.setState({ inputsform: nuevovalor.nuevoarreglo });// respuesta validada

        if (nuevovalor.aprobado) {
            setTimeout(() => {
                this.props.finalizaraccion(nuevovalor.nuevoarreglo);// si aprobado entonces cerrar modal
                this.setState({ show: false })
            }, 200);
        }
    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    // toggle={this.showmodal}
                    className="modal-catalogo">
                    <ModalBody>
                        <div>

                            {(this.props.tipoaccion == 3) ? ( //mostrar vista de contenido completo
                                <div>
                                    <p className="modal-titulo">{this.props.modaltitulo}</p>
                                    {this.props.itemtitulos.map((item, index) => (
                                        <>
                                            {
                                                (this.props.itemver[index] == "") ? (null)
                                                    :
                                                    <>
                                                        <div className={(index < (this.props.itemtitulos.length-4)) ? ("modal-catalogo-vista-tabla" ) : "modal-catalogo-vista-tabla-secundario" }
                                                            style={(index < (this.props.itemtitulos.length-4)) ? ({ background: "#e9ecef" }) : null}>
                                                            <label>{item}: </label>
                                                            <div>{this.props.itemver[index]}</div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                    ))}
                                </div>
                            ) :

                                //mostrar formulario de creaci[on y edici[on]]
                                <>
                                    <p className="modal-titulo">{this.props.modaltitulo}</p>

                                    {this.state.inputsform.map((item, index) => (
                                        <>
                                            {(item.tipoinput == "vista") ? (
                                                 <>
                                                <div className={(index < (this.props.itemtitulos.length-4)) ? ("modal-catalogo-vista-tabla" ) : "modal-catalogo-vista-tabla-secundario" }
                                                     style={(index < (this.props.itemtitulos.length-4)) ? ({ background: "#e9ecef" }) : null}>
                                                    <label>{item.tituloinput} </label>
                                                     <div>{item.valor}</div>
                                                 </div>


                                                 {/* <div className="modal-catalogo-vista-tabla" style={(index % 2 == 0) ? ({ background: "#e9ecef" }) : { background: "#e9ecef" }}>
                                                    <label>{item.tituloinput} </label>
                                                    <div>{item.valor}</div>
                                                </div>*/}
                                             </>
                                                
                                            ) : null}

                                            {(item.tipoinput == "text" || item.tipoinput == "textarea") ? (
                                                <>
                                                    <Inputform datosinput={item} valoringresado={(e) => this.guardarvalor(e)} />
                                                    <br />
                                                </>
                                            ) : null}

                                            {(item.tipoinput == "listaestados") ? (
                                                <>
                                                    {(this.state.listasituacion.length > 0) ? (
                                                        <>
                                                            <Label className="labelform-theme" >
                                                                Situación:
                                                            </Label>
                                                            <div>
                                                                <Dropdown isOpen={this.state.colapsar} toggle={() => this.verestatus()} >
                                                                    <DropdownToggle tag="span" className="navbar-web-menuitem-button" caret>
                                                                        <Button className={(this.state.iteminfo.estatus == "") ? ("blackoutlinebutton") : "blueoutlinebutton"} onClick={() => this.verestatus()}>
                                                                            {(this.state.iteminfo.estatus != "") ? (this.state.iteminfo.estatus) : "Selecciona una situación"}
                                                                            <AiFillCaretDown />
                                                                        </Button>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu style={{ width: "100%", background: "", maxHeight: "300px", overflowY: "scroll" }}>
                                                                        {this.state.listasituacion.map(estado => (
                                                                            <><DropdownItem onClick={() => this.cambiarestado(estado)}>{estado.estatus} </DropdownItem>
                                                                                <DropdownItem divider />
                                                                            </>

                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                            <br />
                                                        </>
                                                    ) :
                                                        <>
                                                            <Label className="labelform-theme" >
                                                                Situación:
                                                            </Label>
                                                            <div className="modal-catalogo-listavacia">
                                                                Necesario crear situaciones
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            ) : null}

                                            {(item.tipoinput == "listaservicios") ? (
                                                <>
                                                    {(this.state.listaservicios.length > 0) ? (
                                                        <>
                                                            <Label className="labelform-theme" >
                                                                Servicio:
                                                            </Label>
                                                            <div>
                                                                <Dropdown isOpen={this.state.colapsarservicio} toggle={() => this.verservicio()} >
                                                                    <DropdownToggle tag="span" className="navbar-web-menuitem-button" caret>
                                                                        <Button className={(this.state.iteminfo.servicio == "") ? ("blackoutlinebutton") : "blueoutlinebutton"} onClick={() => this.verservicio()}>
                                                                            {(this.state.iteminfo.servicio != "") ? (this.state.iteminfo.servicio) : "Selecciona un servicio"}
                                                                            <AiFillCaretDown />
                                                                        </Button>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu style={{ width: "100%", background: "" }}>
                                                                        {this.state.listaservicios.map(estado => (
                                                                            <DropdownItem onClick={() => this.cambiarestadoservicio(estado)}>{estado.servicio} </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                            <br />
                                                        </>
                                                    ) :
                                                        <>
                                                            <Label className="labelform-theme" >
                                                                Servicio:
                                                            </Label>
                                                            <div className="modal-catalogo-listavacia">
                                                                Necesario crear servicios
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            ) : null}



                                        </>
                                    ))}
                                    <br />


                                    {(this.props.showmensaje) ? (
                                        <AreaMensajes
                                            showmensaje={this.props.showmensaje}
                                            titulo={this.props.titulo}
                                            mensaje={this.props.mensaje} />
                                    ) : null}


                                    <div className="modal-catalogo-checkinput" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                        <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar envío de formulario
                                    </div>

                                </>
                            }

                            {
                                (this.props.tipoaccion == 1 || this.props.tipoaccion == 2) ? (
                                    <>
                                        {(this.state.mostrarbotonesformulario) ? (
                                            <div className="modal-catalogos-buttons">
                                                <Button className="cancelbutton" onClick={() => this.showmodal()}><GoX /> Cancelar</Button>
                                                <Button disabled={!this.state.confirmarenvio} className="bluebutton" onClick={() => this.guardarinfo()}> <BsCheckLg /> Guardar</Button>
                                            </div>

                                        ) :
                                            <div className="modal-catalogos-buttons-ver">
                                                <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> Cerrar</Button>
                                            </div>

                                        }

                                    </>

                                ) :
                                    <div className="modal-catalogos-buttons-ver">
                                        <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> Cerrar</Button>
                                    </div>
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

