/*Situaci[on ] */
export function itemactivoSituacion(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogoestatus: "",
            clave: "",
            estatus: "",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
        })
    } else {
        nuevoelemento.push({
            idcatalogoestatus: elemento.idcatalogoestatus,
            clave: elemento.clave,
            estatus: elemento.estatus,
            usuariocreacion: elemento.usuariocreacion,
            usuarioactualizacion: elemento.usuarioactualizacion,
            creado: elemento.creado,
            actualizado: elemento.actualizado
        })
    }
    return nuevoelemento;
}

export function listainputsSituacion() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsSituacion(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogoestatus;
        inputs[1].valor = elemento.clave;
        inputs[2].valor = elemento.estatus;
        inputs[3].valor = elemento.usuariocreacion;
        inputs[4].valor = elemento.creado;
        inputs[5].valor = elemento.usuarioactualizacion;
        inputs[6].valor = elemento.actualizado;

        inputs[2].tipoinput = "text";
        inputs[2].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "null";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
    }

    return inputs

}




/*Envio resultados */
export function itemactivoEnvioresultados(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogoenviarresultados: "",
            estatus: "",
            idcatalogoestatus:"",
            descripcion: "",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
        })

    } else {
        nuevoelemento.push( elemento )
    }

    return nuevoelemento;
}

export function listainputsEnvioResultados() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 3,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },       
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 4,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        }
    ]
    return lista
}

export function ordenarListainputsEnvioresultados(origen, inputs, elemento) {


    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogoenviarresultados;
        inputs[1].valor = elemento.descripcion;
        inputs[2].valor = elemento.estatus;
        inputs[2].contenido = elemento.idcatalogoestatus;
        inputs[3].valor = elemento.usuariocreacion;
        inputs[4].valor = elemento.creado;
        inputs[5].valor = elemento.usuarioactualizacion;
        inputs[6].valor = elemento.actualizado;

        inputs[1].tipoinput="text"
        inputs[2].tipoinput = "listaestados";
        inputs[1].disabled=false;
        inputs[2].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "listaestados";
        inputs[3].tipoinput = "null";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
    }

    return inputs

}





/*Servicios */
export function itemactivoServicios(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogoservicios: "",
            servicio: "",
            carpetaentrada: "",
            carpetasalida: "",
            tiempoespera: "",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
            estatus: "",
            idcatalogoestatus:"",
        })

    } else {
        nuevoelemento.push( elemento )
    }

    return nuevoelemento;
}

export function listainputsServicios() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Ruta recibido:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Ruta guardado:", contenido: "", tipoinput: "vista", posicion: 4,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        }, 
        {
            tituloinput: "Tiempo espera:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        }, 
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 6,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },       
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 9,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 10,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        }
    ]
    return lista
}

export function ordenarListainputsServicios(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogoservicios;
        inputs[1].valor = elemento.servicio;
        inputs[2].valor = elemento.carpetaentrada;
        inputs[3].valor = elemento.carpetasalida;
        inputs[4].valor = elemento.tiempodeespera;

        inputs[5].valor = elemento.estatus;
        inputs[5].contenido = elemento.idcatalogoestatus;

        inputs[6].valor = elemento.usuariocreacion;
        inputs[7].valor = elemento.creado;
        inputs[8].valor = elemento.usuarioactualizacion;
        inputs[9].valor = elemento.actualizado;

        inputs[2].tipoinput="textarea"
        inputs[3].tipoinput = "textarea";
        inputs[4].tipoinput = "textarea";
        inputs[5].tipoinput = "listaestados";
        inputs[2].disabled=false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;
        inputs[5].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";
        inputs[8].valor = "";
        inputs[9].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "textarea";
        inputs[3].tipoinput = "textarea";
        inputs[4].tipoinput = "textarea";
        inputs[5].tipoinput = "listaestados";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";
        inputs[8].tipoinput = "null";
        inputs[9].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;
        inputs[5].disabled = false;
    }

    return inputs

}




















/*monedas */

export function itemactivoMonedas(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogomonedas: "",
            descripcion: "",
            codigo: "",

            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
            estatus: "",
            idcatalogoestatus:"",
        })

    } else {
        nuevoelemento.push( elemento )
    }

    return nuevoelemento;
}

export function listainputsMoneda() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Código:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsMonedas(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogomonedas;
        inputs[1].valor = elemento.codigo;
        inputs[2].valor = elemento.descripcion;
        inputs[3].valor = elemento.estatus;
        inputs[3].contenido = elemento.idcatalogoestatus;
        inputs[4].valor = elemento.usuariocreacion;
        inputs[5].valor = elemento.creado;
        inputs[6].valor = elemento.usuarioactualizacion;
        inputs[7].valor = elemento.actualizado;

        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }

    return inputs

}







/* errores */

export function itemactivoErrores(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogoerrores: "",
            descripcion: "",
            codigo: "",

            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
            estatus: "",
            idcatalogoestatus:"",
        })

    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}

export function listainputsErrores() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Código:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsErrores(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogoerrores;
        inputs[1].valor = elemento.codigo;
        inputs[2].valor = elemento.descripcion;
        inputs[3].valor = elemento.estatus;
        inputs[3].contenido = elemento.idcatalogoestatus;
        inputs[4].valor = elemento.usuariocreacion;
        inputs[5].valor = elemento.creado;
        inputs[6].valor = elemento.usuarioactualizacion;
        inputs[7].valor = elemento.actualizado;

        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }

    return inputs

}






/*medios de servicios */

export function itemactivoMediosServicio(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogocanalarchivos: "",
            descripcion: "",
            canal: "",

            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
            estatus: "",
            idcatalogoestatus:"",
        })

    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}

export function listainputsMedios() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Nombre:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsMedios(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogocanalarchivos;
        inputs[1].valor = elemento.canal;
        inputs[2].valor = elemento.descripcion;
        inputs[3].valor = elemento.estatus;
        inputs[3].contenido = elemento.idcatalogoestatus;
        inputs[4].valor = elemento.usuariocreacion;
        inputs[5].valor = elemento.creado;
        inputs[6].valor = elemento.usuarioactualizacion;
        inputs[7].valor = elemento.actualizado;

        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }

    return inputs

}




/* asesores */

export function itemactivoAsesores(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogoasesores: "",
            nombre: "",
            correo: "",
            servicio: "",
            estatus: "",
            idcatalogoestatus:"",
            usuariocreacion: "",
            usuarioactualizacion: "",
            idcatalogoservicios:"",
            creado: "",
            actualizado: "",
           
        })
    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}

export function listainputsAsesores() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Nombre:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Email:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Servicio:", contenido: "", tipoinput: "listaservicios", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },      
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 9,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsAsesores(origen, inputs, elemento) {

    if (origen == 2) {
               inputs[0].valor = elemento.idcatalogoasesores;
        inputs[1].valor = elemento.nombre;
        inputs[2].valor = elemento.correo;
        inputs[3].valor = elemento.servicio;
        inputs[3].contenido = elemento.idcatalogoservicios;
        inputs[4].valor = elemento.estatus;
        inputs[4].contenido = elemento.idcatalogoestatus;

        inputs[5].valor = elemento.usuariocreacion;
        inputs[6].valor = elemento.creado;
        inputs[7].valor = elemento.usuarioactualizacion;
        inputs[8].valor = elemento.actualizado;

        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaservicios";
        inputs[4].tipoinput = "listaestados";
       
        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";
        inputs[8].valor = "";


        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaservicios";
        inputs[4].tipoinput = "listaestados";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";
        inputs[8].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;

    }

    return inputs

}






/* familias */

export function itemactivoFamilias(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogofamilias: "",
            familia: "",
            estatus: "",
            cliente:"",
            idcatalogoestatus:"",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
           
        })
    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}

export function listainputsFamilias() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Cliente:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsFamilias(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogofamilias;
        inputs[1].valor = elemento.familia;
        inputs[2].valor = elemento.cliente;
        inputs[3].valor = elemento.estatus;
        inputs[3].contenido = elemento.idcatalogoestatus;
        inputs[4].valor = elemento.usuariocreacion;
        inputs[5].valor = elemento.creado;
        inputs[6].valor = elemento.usuarioactualizacion;
        inputs[7].valor = elemento.actualizado;

        inputs[2].tipoinput = "text";       
        inputs[2].disabled = false;
        inputs[3].tipoinput = "listaestados";       
        inputs[3].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }

    return inputs

}









/*tipo operaciones */

export function itemactivoTipoOperaciones(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogotipooperacion: "",
            folio: "",
            descripcion: "",
            tipo:"",
            codigo:"",
            estatus:"",
            idcatalogoestatus:"",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
           
        })
    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}

export function listainputsTipoOperacion() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Folio de control:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción de movimiento:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Tipo de transacción:", contenido: "", tipoinput: "vista", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Código de operación:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 9,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 10,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsTipoOperacion(origen, inputs, elemento) {

    if (origen == 2) {
       
        inputs[0].valor = elemento.idcatalogotipooperacion;
        inputs[1].valor = elemento.folio;
        inputs[2].valor = elemento.descripcion;
        inputs[3].valor = elemento.tipo;
        inputs[4].valor = elemento.codigo;
        inputs[5].valor = elemento.estatus;
        inputs[5].contenido = elemento.idcatalogoestatus;

        inputs[6].valor = elemento.usuariocreacion;
        inputs[7].valor = elemento.creado;
        inputs[8].valor = elemento.usuarioactualizacion;
        inputs[9].valor = elemento.actualizado;
       
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "text";
        inputs[4].tipoinput = "text";
        inputs[5].tipoinput = "listaestados";       
       
        inputs[2].disabled = false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;
        inputs[5].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";
        inputs[8].valor = "";
        inputs[9].valor = "";


        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "null";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "text";
        inputs[4].tipoinput = "text";
        inputs[5].tipoinput = "listaestados";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";
        inputs[8].tipoinput = "null";
        inputs[9].tipoinput = "null";

       
        inputs[2].disabled = false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;
        inputs[5].disabled = false;

    }

    return inputs

}




//////estados de cuenta

/*Estado de cuenta */
export function itemactivoEstadodecuenta(idelemento, elemento) {// <----estado de cuenta cambiar es temporal
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogoestadodecuenta: "",
            tipo: "",
            descripcion:"",
            estatus:"",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
        })
    } else {
        nuevoelemento.push({
            idcatalogoestadodecuenta: elemento.idcatalogoestadodecuenta,
            tipo: elemento.tipo,
            descripcion:elemento.descripcion,
            estatus:elemento.estatus,
            idcatalogoestatus:elemento.idcatalogoestatus,
            usuariocreacion: elemento.usuariocreacion,
            usuarioactualizacion: elemento.usuarioactualizacion,
            creado: elemento.creado,
            actualizado: elemento.actualizado
        })
    }
    return nuevoelemento;
}



export function listainputsEstadodecuenta() { // <----estado de cuenta cambiar es temporal


    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Clave de tipo estado de cuenta:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Descripción:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Situacion:", contenido: "", tipoinput: "vista", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsEstadodecuenta(origen, inputs, elemento) { // <----estado de cuenta cambiar es temporal


    if (origen == 2) {
        inputs[0].valor = elemento.idcatalogoestadodecuenta;
        inputs[1].valor = elemento.tipo;
        inputs[2].valor = elemento.descripcion;
        inputs[3].valor = elemento.estatus;
        inputs[3].contenido = elemento.idcatalogoestatus;
        inputs[4].valor = elemento.usuariocreacion;
        inputs[5].valor = elemento.creado;
        inputs[6].valor = elemento.usuarioactualizacion;
        inputs[7].valor = elemento.actualizado;

        //inputs[1].tipoinput = "text";
        inputs[1].disabled = false;
        inputs[2].tipoinput = "text";
        inputs[2].disabled = false;
        inputs[3].tipoinput = "listaestados";
        inputs[3].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[3].contenido = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[6].valor = "";
        inputs[7].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "listaestados";
        inputs[4].tipoinput = "null";
        inputs[5].tipoinput = "null";
        inputs[6].tipoinput = "null";
        inputs[7].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
    }

    return inputs

}



































//administraci[on ] cuentas

export function itemactivoAdministracioncuentas(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogotipooperacion: "",
            folio: "",
            descripcion: "",
            tipo:"",
            codigo:"",
            estatus:"",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
           
        })
    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}





//administraci[on ] cuentas

export function itemactivoGestionusuarios(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            idcatalogotipooperacion: "",
            folio: "",
            descripcion: "",
            tipo:"",
            codigo:"",
            estatus:"",
            usuariocreacion: "",
            usuarioactualizacion: "",
            creado: "",
            actualizado: "",
           
        })
    } else {
        nuevoelemento.push( elemento )
    }
    return nuevoelemento;
}



