import React from "react";
import { Modal, ModalBody } from 'reactstrap';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import es from 'date-fns/locale/es';

export class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: props.show, selectedDay: null, };
        this.handleDayClick = this.handleDayClick.bind(this);
    }


    showmodal = () => {
        this.props.getDate(null);
    }

    handleDayClick(day, { selected }) {
        this.setState({
            selectedDay: selected ? undefined : day,
        });
        let y = { selected };
        let x = { day, y };
        this.props.getDate(x);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    toggle={this.showmodal}
                    className="modal-select-date modaltent">
                    <ModalBody className="estilomodal">
                        <DayPicker
                            mode="single"
                            showOutsideDays fixedWeeks
                            locale={es} 
                            selected={this.state.selectedDay}
                            onDayClick={this.handleDayClick}
                        />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
