export function ordenarCuenta(elemento) {
    let nuevoelemento = [];
    nuevoelemento.push({
        idcuentas: elemento.idcuentas,
        cuenta: elemento.cuenta,
        familia: elemento.familia,
        cliente: elemento.cliente,
        notificaciones: elemento.notificaciones,
        correo: elemento.correo,

        estatus: elemento.estatus,
        usuariocreacion: elemento.usuariocreacion,
        usuarioactualizacion: elemento.usuarioactualizacion,
        creado: elemento.creado,
        actualizado: elemento.actualizado,
    })

    return nuevoelemento;

}




export function listainputsCuentas() {
    let lista = [

        {
            tituloinput: "Cuenta:", contenido: "", tipoinput: "text", posicion: 0,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Nombre:", contenido: "", tipoinput: "text", posicion: 1,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

        {
            tituloinput: "Email:", contenido: "", tipoinput: "text", posicion: 2,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

    ]

    return lista

}


/*ordenar cuentas */
export function ordenartablacuentas(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcuentas) > parseInt(b.idcuentas)) {
                    return 1;
                }
                if (parseInt(a.idcuentas) < parseInt(b.idcuentas)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.cuenta.toUpperCase() > b.cuenta.toUpperCase()) {
                    return 1;
                }
                if (a.cuenta.toUpperCase() < b.cuenta.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.cliente.toUpperCase() > b.cliente.toUpperCase()) {
                    return 1;
                }
                if (a.cliente.toUpperCase() < b.cliente.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }






        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.correo.toUpperCase() > b.correo.toUpperCase()) {
                    return 1;
                }
                if (a.correo.toUpperCase() < b.correo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.familia.toUpperCase() > b.familia.toUpperCase()) {
                    return 1;
                }
                if (a.familia.toUpperCase() < b.familia.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.notificaciones.toUpperCase() > b.notificaciones.toUpperCase()) {
                    return 1;
                }
                if (a.notificaciones.toUpperCase() < b.notificaciones.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 6) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }



        return lista;
    }


}




/* formularios sms */

export function listainputsFormularioSMS() {
    let lista = [

        {
            tituloinput: "Id Folio:", contenido: "", tipoinput: "vista", posicion: 0,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Nombre de contacto:", contenido: "", tipoinput: "text", posicion: 1,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

        {
            tituloinput: "Cargo del contacto:", contenido: "", tipoinput: "text", posicion: 2,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Teléfono:", contenido: "", tipoinput: "text", posicion: 3,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 4,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        }

    ]

    return lista

}



export function ordenarlistainputsFormularioSMS(inputs, elemento) {

    inputs[0].valor = elemento.idcuentacontactossms;
    inputs[1].valor = elemento.nombrecontacto;
    inputs[2].valor = elemento.puesto;
    inputs[3].valor = elemento.telefono;
    inputs[4].valor = elemento.estatus;
    inputs[4].contenido = elemento.idcatalogoestatus;
    inputs[5].valor = elemento.usuariocreacion;
    inputs[6].valor = elemento.creado;
    inputs[7].valor = elemento.usuarioactualizacion;
    inputs[8].valor = elemento.actualizado;

    if (elemento.idcuentacontactossms != "") {
        inputs[0].tipoinput = "vista";
        inputs[1].tipoinput = "vista";
        inputs[1].disabled = "false";
    }

    inputs[2].tipoinput = "text";
    inputs[3].tipoinput = "text";
    inputs[4].tipoinput = "listaestados";
    inputs[5].valor = elemento.usuariocreacion;
    inputs[6].valor = elemento.creado;
    inputs[7].valor = elemento.usuarioactualizacion;
    inputs[8].valor = elemento.actualizado;




    return inputs

}































/* ordenar Archivos */

export function ordenararchivos(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.id) > parseInt(b.id)) {
                    return 1;
                }
                if (parseInt(a.id) < parseInt(b.id)) {
                    return -1;
                }

                return 0;
            });
        }

        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.familia.toUpperCase() > b.familia.toUpperCase()) {
                    return 1;
                }
                if (a.familia.toUpperCase() < b.familia.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.cuenta.toUpperCase() > b.cuenta.toUpperCase()) {
                    return 1;
                }
                if (a.cuenta.toUpperCase() < b.cuenta.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.cliente.toUpperCase() > b.cliente.toUpperCase()) {
                    return 1;
                }
                if (a.cliente.toUpperCase() < b.cliente.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }






        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.medio.toUpperCase() > b.medio.toUpperCase()) {
                    return 1;
                }
                if (a.medio.toUpperCase() < b.medio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.servicio.toUpperCase() > b.servicio.toUpperCase()) {
                    return 1;
                }
                if (a.servicio.toUpperCase() < b.servicio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.archivo.toUpperCase() > b.archivo.toUpperCase()) {
                    return 1;
                }
                if (a.archivo.toUpperCase() < b.archivo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }



        return lista;
    }


}



/* ordenar bit[acora gesti[on]] */

export function ordenarbitacoragestion(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.id) > parseInt(b.id)) {
                    return 1;
                }
                if (parseInt(a.id) < parseInt(b.id)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.cuenta.toUpperCase() > b.cuenta.toUpperCase()) {
                    return 1;
                }
                if (a.cuenta.toUpperCase() < b.cuenta.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.cliente.toUpperCase() > b.cliente.toUpperCase()) {
                    return 1;
                }
                if (a.cliente.toUpperCase() < b.cliente.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }






        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.medio.toUpperCase() > b.medio.toUpperCase()) {
                    return 1;
                }
                if (a.medio.toUpperCase() < b.medio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.servicio.toUpperCase() > b.servicio.toUpperCase()) {
                    return 1;
                }
                if (a.servicio.toUpperCase() < b.servicio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.archivo.toUpperCase() > b.archivo.toUpperCase()) {
                    return 1;
                }
                if (a.archivo.toUpperCase() < b.archivo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 6) {
            lista = temporal.sort(function (a, b) {
                if (a.fecharecepcion.toUpperCase() > b.fecharecepcion.toUpperCase()) {
                    return 1;
                }
                if (a.fecharecepcion.toUpperCase() < b.fecharecepcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 7) {
            lista = temporal.sort(function (a, b) {
                if (a.totaltransacciones.toUpperCase() > b.totaltransacciones.toUpperCase()) {
                    return 1;
                }
                if (a.totaltransacciones.toUpperCase() < b.totaltransacciones.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 8) {
            lista = temporal.sort(function (a, b) {
                if (a.totaltransaccionesenviadas.toUpperCase() > b.totaltransaccionesenviadas.toUpperCase()) {
                    return 1;
                }
                if (a.totaltransaccionesenviadas.toUpperCase() < b.totaltransaccionesenviadas.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 9) {
            lista = temporal.sort(function (a, b) {
                if (a.totaltransaccionesenviadascorrectas.toUpperCase() > b.totaltransaccionesenviadascorrectas.toUpperCase()) {
                    return 1;
                }
                if (a.totaltransaccionesenviadascorrectas.toUpperCase() < b.totaltransaccionesenviadascorrectas.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 10) {
            lista = temporal.sort(function (a, b) {
                if (a.fechavalidacion.toUpperCase() > b.fechavalidacion.toUpperCase()) {
                    return 1;
                }
                if (a.fechavalidacion.toUpperCase() < b.fechavalidacion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 11) {
            lista = temporal.sort(function (a, b) {
                if (a.fechanotificacioncliente.toUpperCase() > b.fechanotificacioncliente.toUpperCase()) {
                    return 1;
                }
                if (a.fechanotificacioncliente.toUpperCase() < b.fechanotificacioncliente.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 12) {
            lista = temporal.sort(function (a, b) {
                if (a.codigoestructura.toUpperCase() > b.codigoestructura.toUpperCase()) {
                    return 1;
                }
                if (a.codigoestructura.toUpperCase() < b.codigoestructura.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 13) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }


        return lista;
    }


}













/*    par[ametro de sistema]   */



export function listaInputsParametrosDeSistema() {
    let lista = [

        {
            tituloinput: "Id Folio:", contenido: "", tipoinput: "vista", posicion: 0,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Tipo Parámetro:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Hr. Inicio MT940:", contenido: "", tipoinput: "text", posicion: 2,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Hr. Fin MT940:", contenido: "", tipoinput: "text", posicion: 3,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Hr. Inicio MT942:", contenido: "", tipoinput: "text", posicion: 4,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Hr. Fin MT942:", contenido: "", tipoinput: "text", posicion: 5,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Vigencia dias archivos:", contenido: "", tipoinput: "text", posicion: 6,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },




        {
            tituloinput: "Vigencia dias archivos clientes:", contenido: "", tipoinput: "text", posicion: 7,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Ruta home:", contenido: "", tipoinput: "text", posicion: 8,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Ruta certificado cliente:", contenido: "", tipoinput: "text", posicion: 9,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

        {
            tituloinput: "Ruta Repositorio Certificados:", contenido: "", tipoinput: "text", posicion: 10,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

        {
            tituloinput: "Ruta llave publica intercam:", contenido: "", tipoinput: "text", posicion: 11,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

        {
            tituloinput: "Ruta llave privada intercam", contenido: "", tipoinput: "text", posicion: 12,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },

        {
            tituloinput: "Situación:", contenido: "", tipoinput: "listaestados", posicion: 12,
            validacion: "", mensajevalidacion: "Error", disabled: false, valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 13,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 14,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 15,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 16,
            validacion: "", mensajevalidacion: "Error", disabled: "false", valor: ""
        }

    ]

    return lista

}



export function ordenarListaInputsParametrosDeSistema(inputs, elemento) {

    inputs[0].valor = elemento.idconfigestadocuenta;
    inputs[1].valor = elemento.tipoparametro;
    inputs[2].valor = elemento.horainiciomt940;
    inputs[3].valor = elemento.horafinmt940; 
    inputs[4].valor = elemento.horainiciomt942;
    inputs[5].valor = elemento.horafinmt942;
    inputs[6].valor = elemento.vigenciadiasarchivos;
    inputs[7].valor = elemento.vigenciadiasarchivoscliente;
    inputs[8].valor = elemento.rutahome;
    inputs[9].valor = elemento.rutacertificadocliente;
    inputs[10].valor = elemento.rutarepositoriocertificados;

    inputs[11].valor = elemento.rutallavepublicaintercam;
    inputs[12].valor = elemento.rutallaveprivadaintercam;

    inputs[13].valor = elemento.estatus;
    inputs[13].contenido = elemento.idcatalogoestatus;
    inputs[13].valor = elemento.usuariocreacion;
    inputs[14].valor = elemento.creado;
    inputs[15].valor = elemento.usuarioactualizacion;
    inputs[16].valor = elemento.actualizado;


    inputs[2].tipoinput = "text";
    inputs[3].tipoinput = "text";
    inputs[4].tipoinput = "text";
    inputs[5].tipoinput = "text";
    inputs[6].tipoinput = "text";
    inputs[7].tipoinput = "text";
    inputs[8].tipoinput = "text";
    inputs[9].tipoinput = "text";
    inputs[10].tipoinput = "text";
    inputs[11].tipoinput = "text";
    inputs[12].tipoinput = "text";
    inputs[13].tipoinput = "listaestados";

    return inputs

}

































//  ordenar tipo de servicio por medio y cuenta  ////////////////////////////////////////////////////////////////////////////////////////

/* ordenar bit[acora gesti[on]] */

export function ordenartipodeservicio(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });




    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcuentacanalarchivosservicio) > parseInt(b.idcuentacanalarchivosservicio)) {
                    return 1;
                }
                if (parseInt(a.idcuentacanalarchivosservicio) < parseInt(b.idcuentacanalarchivosservicio)) {
                    return -1;
                }

                return 0;
            });
        }

        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.familia.toUpperCase() > b.familia.toUpperCase()) {
                    return 1;
                }
                if (a.familia.toUpperCase() < b.familia.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.cuenta.toUpperCase() > b.cuenta.toUpperCase()) {
                    return 1;
                }
                if (a.cuenta.toUpperCase() < b.cuenta.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }


        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.cliente.toUpperCase() > b.cliente.toUpperCase()) {
                    return 1;
                }
                if (a.cliente.toUpperCase() < b.cliente.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.canal.toUpperCase() > b.canal.toUpperCase()) {
                    return 1;
                }
                if (a.canal.toUpperCase() < b.canal.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.servicio.toUpperCase() > b.servicio.toUpperCase()) {
                    return 1;
                }
                if (a.servicio.toUpperCase() < b.servicio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }



        return lista;
    }


}