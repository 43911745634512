import React from "react";
import { Datepicker } from "../../Modales/DatePicker/DatePicker";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { RiCalendarEventFill, RiFileExcel2Fill, RiRefreshLine } from 'react-icons/ri';
import ExportarConsultaBitacora from "../../Reportes/Complementos/ExportarConsultaBitacora";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { administracion_bitacora_exportar } from "../../../Services/Api";
import SpinnerModal from "../../Modales/SpinnerModal/SpinnerModal";
import { GrCheckbox } from "react-icons/gr";
import { FaRegCheckSquare } from "react-icons/fa";


export default class BitacoragestionFiltros extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date()
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let fechaaux = null

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }


        this.state = {
            jsoncsv: [],
            dropdownusuarios: false,
            showdatepicker: false,
            idclienteactivo: 0,
            nombreclienteactivo: "Todos",
            fechaactiva: null,
            fechainicio: fechaaux,
            fechafin: fechaaux,
            check_detalles: false,
            loadingImage: false,
            clientes: this.props.listaclientes
        }
    }

    componentDidMount() {
        this.buscarseleccionado();
    }

    opendatepicker = (opcion) => {
        this.setState({ showdatepicker: !this.state.showdatepicker, fechaactiva: opcion });
    }

    dateSelected = (dato) => {
        this.setState({ showdatepicker: !this.state.showdatepicker });
        if (dato != null) {
            if (dato != null) {
                this.handleDayClick(dato.day, dato.y);
            }
        }
    }

    handleDayClick = (day, { selected }) => {
        let fecha = day.toLocaleDateString();
        //console.log(fecha)
        if (this.state.fechaactiva == 1)
            this.setState({
                fechainicio: fecha.toString(),
            });
        if (this.state.fechaactiva == 2)
            this.setState({
                fechafin: fecha.toString(),
            });
        this.buscarseleccionado();
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownusuarios: !prevState.dropdownusuarios
        }));
    }

    clienteselected = (seleccionado) => {
        this.setState({ check_detalles: false });
        this.setState({ nombreclienteactivo: seleccionado.cuenta + " " + seleccionado.cliente, idclienteactivo: seleccionado.idcuentas })
        this.buscarseleccionado();
    }

    buscarseleccionado = () => {
        setTimeout(() => {
            this.setState({ check_detalles: false });
            this.props.buscarseleccionado([this.state.fechainicio, this.state.fechafin, this.state.idclienteactivo, this.state.nombreclienteactivo])
        }, 100);
    }


    activa_detalles_check = async () => {
        try {




            if (this.props.totalitems.length > 0) {

                var checkD = !this.state.check_detalles;
                await this.setState({ check_detalles: checkD });

                if (checkD == true) {
                    this.setState({ loadingImage: true })
                    await this.setState({ check_detalles: checkD });



                    let bitacora = await administracion_bitacora_exportar(this.state.idclienteactivo, this.state.fechainicio, this.state.fechafin);
                    this.setState({ loadingImage: false })
                    if (bitacora.code == 0) {

                        var temarray = bitacora.data.items;

                        let date = new Date();
                        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                        let month = date.getMonth() + 1;
                        let year = date.getFullYear();
                        let fechaaux = null;

                        if (month < 10) {
                            fechaaux = (`${day}/0${month}/${year}`)
                        } else {
                            fechaaux = (`${day}/${month}/${year}`)
                        }


                        var array_datos = [];
                        var array_datosr = [];


                        array_datosr.push(["Fecha de gestión", "Cliente", "Fecha inicio", "Fecha Fin"])
                        array_datos = [fechaaux, this.state.nombreclienteactivo, this.state.fechainicio, this.state.fechafin];
                        array_datosr.push(array_datos);





                        var array_secundario = [];
                        for (let i = 0; i < temarray.length; i++) {
                            array_datosr.push([""]);
                            array_datosr.push(this.props.array_heders)
                            array_secundario = [];



                            array_datos = [temarray[i].id,
                            temarray[i].cuenta,
                            temarray[i].cliente,
                            temarray[i].medio,
                            temarray[i].servicio,
                            temarray[i].archivo,
                            temarray[i].fecharecepcion,
                            temarray[i].totaltransacciones,
                            temarray[i].totaltransaccioneserror,
                            temarray[i].totaltransaccionesenviadas,
                            temarray[i].totaltransaccionesenviadaserror,
                            temarray[i].fechavalidacion,
                            temarray[i].fechanotificacioncliente,
                            temarray[i].codigoestructura,
                            temarray[i].estatus

                            ]
                            array_datosr.push(array_datos)
                            array_datosr.push([""]);
                            array_datosr.push([

                                "ID",
                                "Fila",
                                "Columna",
                                "Valor campo",
                                "Comentarios",
                                "Error",
                                "Fecha"
                            ])
                            for (let j = 0; j < temarray[i].detalles.length; j++) {

                                array_secundario = [temarray[i].detalles[j].idcuentassolicitudesservicioserrores,
                                temarray[i].detalles[j].fila,
                                temarray[i].detalles[j].columna,
                                temarray[i].detalles[j].valorcampo,
                                temarray[i].detalles[j].comentarios,
                                temarray[i].detalles[j].error,
                                temarray[i].detalles[j].fecha
                                ]
                                array_datosr.push(array_secundario)
                            }

                        }
                        this.setState({ jsoncsv: array_datosr })

                    } else {

                        if (bitacora.code == 1) {
                            toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                        }
                        else if (bitacora.code == 2) {
                            toast.warn(bitacora.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                        }
                        else {
                            toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                            this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
                        }
                    }
                    setTimeout(() => {
                        this.setState({ loadingImage: false })
                    }, 200);


                }

            }
            else {
                toast.warn("No hay datos que exportar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

        } catch (error) {
            setTimeout(() => {
                this.setState({ loadingImage: false })
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }, 200);
        }
    }

    /////////////
    escribir = (prop) => (event) => {
        if (event.target.value == undefined) { return; }
        var dias = event.target.value.split("-");
        var fechaf = dias[2] + '/' + dias[1] + '/' + dias[0];
        this.setState({ [prop]: fechaf });
        if (this.state.fechaactiva == 1)
            this.setState({
                fechainicio: fechaf,
            });
        if (this.state.fechaactiva == 2)
            this.setState({
                fechafin: fechaf,
            });
        this.buscarseleccionado();
    }
    regresaFecha = (fecha) => {
        var [day, month, year] = fecha.split("/");
        var fechaf = "";
        if (day != undefined && month != undefined && year != undefined) {
            fechaf = `${year}-${month}-${day}`
            return fechaf;
        } else {
            fechaf = fecha;
        }
        return fechaf;
    }
    //////////

    render() {
        return (
            <>

                <ToastContainer />
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}


                <div className="bitacoragestion-filtros">
                    <div className="bitacoragestion-fechas">
                        De:

                        <input className='bitacoragestion-fechafin blueoutlinebutton input_fecha_new'
                            type={'date'} value={this.regresaFecha(this.state.fechainicio)} onChange={this.escribir("fechainicio")}></input>

                        <>  </>a:

                        <input className='bitacoragestion-fechafin blueoutlinebutton input_fecha_new'
                            type={'date'} value={this.regresaFecha(this.state.fechafin)} onChange={this.escribir("fechafin")}></input>
                    </div>

                    <div className="bitacoragestion-usuarios">
                        <label>Cliente:</label>
                        {
                            (this.state.clientes.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownusuarios} toggle={this.toggle} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="bitacoragestion-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.nombreclienteactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un usuario</DropdownItem>
                                            <DropdownItem onClick={() => this.clienteselected({ cuenta: "", cliente: "Todos", idcuentas: 0 })}>Todos</DropdownItem>
                                            {this.state.clientes.map((cliente) => (
                                                <DropdownItem onClick={() => this.clienteselected(cliente)}>{cliente.cuenta} {cliente.cliente}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>


                    <div style={{ marginRight: "0px", marginLeft: "auto", width: "auto" }}>
                        <div style={{ display: "flex" }}>

                            <Button className="greenoutlinebutton" style={{
                                width: "170px",
                                height: "45px",
                                marginTop: "20px",
                                marginRight: "20px"
                            }} onClick={this.buscarseleccionado}><RiRefreshLine /> Refrescar Lista</Button>


                            <div className="consulta-archivos-exportar" style={{ marginRight: "0px", marginLeft: "auto", width: "auto" }}>
                                <div style={{ display: "flex" }}>



                                    {this.state.check_detalles == false ?
                                        this.props.totalitems.length > 0 ?
                                            <ExportarConsultaBitacora bandera={'excel'} cliente={this.state.nombreclienteactivo}
                                                fechaI={this.state.fechainicio} fechaF={this.state.fechafin} totalitems={this.props.totalitems}
                                                array_heders={this.props.array_heders} arraycsv={this.props.arraycsv} />
                                            :
                                            <RiFileExcel2Fill onClick={this.activa_detalles_check} className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px", margin: 'auto' }} />
                                        :
                                        <ExportarConsultaBitacora bandera={'excel'} cliente={this.state.nombreclienteactivo}
                                            fechaI={this.state.fechainicio} fechaF={this.state.fechafin} totalitems={this.props.totalitems}
                                            array_heders={this.props.array_heders} arraycsv={this.state.jsoncsv} />
                                    }

                                    {/*this.state.check_detalles == false ?
                                <RiFileExcel2Fill onClick={this.mensajesindatos} className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px",  margin: 'auto' }} />
                                :
                                <ExportarConsultaBitacora bandera={'excel'} cliente={this.state.nombreclienteactivo}
                                    fechaI={this.state.fechainicio} fechaF={this.state.fechafin} totalitems={this.props.totalitems}
                                    array_heders={this.props.array_heders} arraycsv={this.state.jsoncsv} />
                            */}
                                </div>


                                <label className="consulta-archivos-detalles" onClick={this.activa_detalles_check} >
                                    {this.state.check_detalles ? <FaRegCheckSquare className="icodetalles" /> : <GrCheckbox className="icodetalles" />}
                                    Detallado

                                </label>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        );
    }
}

