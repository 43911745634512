import React from "react";

import { FiAlertCircle } from 'react-icons/fi';


export default class AreaMensajes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: this.props.showmensaje
        }

        //console.log("areamensaje")
    }

    render() {
        return (
            <>
                <div style={{ background: "#ff6fa422", borderRadius: "10px", padding: "10px", textAlign: "center" }}>
                    <div>
                        <FiAlertCircle style={{ color: "#ff6fa4", width: "100px", height: "auto" }} />
                    </div>
                    <div style={{ color: "#d5186f", fontSize: "medium" }}>
                        <label>
                            <b>{this.props.titulo}</b>
                        </label>
                        <label></label>
                    </div>


                    <div>{this.props.mensaje}</div>
                </div>
            </>
        )
    }
}
