import React from "react";
import { BsEyeFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import { Button } from "reactstrap";





export class BotonesVerEditar extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<>
            <div className="panel-table-buttons-aux">
                <div>
                    <Button className="greenbutton" onClick={() => this.props.crearelemento({ origen: 3, idelemento: this.props.id })}><BsEyeFill />  Ver</Button>
                </div>
                <div>
                    <Button
                        className={(this.props.permisoedicion) ? ("bluebutton") : "cancelbutton"}
                        disabled={(this.props.permisoedicion) ? (false) : true}
                        style={{padding:"0px"}}
                        onClick={(this.props.permisoedicion) ? (
                            () => this.props.crearelemento({ origen: 2, idelemento: this.props.id })
                        ) : (
                            () => console.log("no tienes permiso")
                        )
                        }><RiEdit2Fill /> Editar</Button>
                </div>
            </div>
        </>);
    }
}