import React from "react"
import { Dropdown, Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Label, Table, Row, Col } from 'reactstrap';
import Inputform from "../Inputform/Inputform";
import { BsCheckLg } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsEyeFill } from 'react-icons/bs';
import { RiCalendarEventFill, RiEdit2Fill } from 'react-icons/ri'
import { BiEdit } from 'react-icons/bi';
import { BsTrash, BsCircleFill } from 'react-icons/bs';

import { GoX } from 'react-icons/go';
import { actualizar_940, administracion_cuentadetalles, catalogo_envioresultados, catalogo_familia, catalogo_mediosServicios, catalogo_servicios, catalogo_situaciones, verinfo } from "../../Services/Api";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "./SpinnerModal/SpinnerModal";

export default class ModalCuentasAsociadas extends React.Component {
    constructor(props) {
        super(props);


        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }


        this.state = {
            show: this.props.show,
            inputs: this.props.elementosinput,
            itemactivo: this.props.itemactivo,
            itemactivo2: '',
            selectintervalo: '',
            txthorainiciogeneracion: '',
            txthorafingeneracion: '',
            colapsar: false,
            colapsarhora: false,
            colapsar2: false,
            colapsefamilia: false,
            colapsenotificaciones: false,
            listasituacion: [],
            listasituacion942: [],
            listaenvioresultados: [],
            listaservicios: [],
            arrayAPI942: [],
            listamedios: [],
            listamediosServicios: [],
            headerservicios942: ["Usuario creación", "Fecha creación", "Usuario actualización", "Fecha actualización"],
            headerservicios: ["Folio cuenta", "Fecha inicio", "Fecha final", "Fecha aplicación", "Hora aplicacion", "Indicador generado", "Situacion", "Usuario creación", "Fecha creación", "Usuario actualización", "Fecha actualización", ""],
            arraycuentasEliminadas: [],
            arraycuentas: [],
            arraytime: [],
            arraytime_horario_base: [],
            arraytime_hora_inicio: [],
            arraytime_hora_fin: [],
            arrayintervalo: [{ "intervalo": "30 min", "elegir": false }, { "intervalo": "1 hora", "elegir": false }, { "intervalo": "2 horas", "elegir": false }, { "intervalo": "3 horas", "elegir": false }],
            arraytipo942: [{ "titulo": "Todos", "elegir": false }, { "titulo": "Solo débito", "elegir": false }, { "titulo": "Solo crédito", "elegir": false }],
            arrayselecthora: [],
            fechaactual: fechaaux,
            accionsolicitud: "nuevo",
            idcatalogoestatus942: '',
            txtestatus942: '',
            idcatalogoestatus940: '',
            txtestatus940: '',
            idmt940: "",
            idmt942: "",
            txtidfolio: '',
            txtmontobase: '',
            txthorariobase: '',
            txttipodetalle: '',
            fechainicio: fechaaux,
            fechafin: fechaaux,
            fechaaplicacion: fechaaux,
            horaaplicacion: '',
            estatus: '',
            showdatepicker: false,
            aplicaGeneracion: false,
            confirmarenvio942: false,
            confirmarenvio940: false,
            vercheckcambios942: false,
            vercheckcambios940: false,
            fechaactiva: '',
            errorfolio: false,
            nuevasolicitud: false,
            errorfechainicio: false,
            errorfechafin: false,
            txtultimahoradetalles: '',
            pos_modificarcuentas: -1,
            vertabla940: false,
            vertabla942: false,
            tiene_indicador_generado940: false,
            idcuentaestadodecuentaregistros942: "0",
            activarformu: false,
            errormonto: false,
            loadingImage: false,


        }

        //console.log(this.props)
    }

    horaEnSegundos = (q) => {
        return q * 60 * 60;
    }

    minutosEnSegundos = (q) => {
        return q * 60;
    }


    async componentDidMount() {

        this.setState({ loadingImage: true });

        this.setState({ selectintervalo: this.state.arrayintervalo[0].intervalo });

        var hora = 0;
        var horaInicio = this.horaEnSegundos(0);
        var horaFin = this.horaEnSegundos(24);
        var progresion = this.minutosEnSegundos(30);
        var arraytime2 = [];

        let con = 0;
        while (horaInicio < horaFin) {
            horaInicio = horaInicio + progresion;

            var hora = parseInt(horaInicio / 3600) % 24;
            var minutos = parseInt(horaInicio / 60) % 60;
            var resultado = (hora < 10 ? "0" + hora : hora) + ":" + (minutos < 10 ? "0" + minutos : minutos);

            if (con == 0) {
                arraytime2.push({ "hora": resultado, "estatus": true });
            }
            else {
                arraytime2.push({ "hora": resultado, "estatus": false });
            }
            con++;

        }
        var da = [];
        da.push(arraytime2[0]);
        this.setState({ arrayselecthora: da })
        this.setState({ arraytime: JSON.parse(JSON.stringify(arraytime2)) })
        this.setState({ arraytime_hora_fin: JSON.parse(JSON.stringify(arraytime2)) })
        this.setState({ arraytime_hora_inicio: JSON.parse(JSON.stringify(arraytime2)) })
        this.setState({ arraytime_horario_base: JSON.parse(JSON.stringify(arraytime2)) })






        //console.log("el activo " + this.props.tipoaccion);
        //console.log(this.state.itemactivo.idcuentas);
        const listaservicios = await catalogo_situaciones();
        //const listainfo = { "code": 0, "data": { "mt940": 1, "mt942": 1, "mt940-data": [], "mt942-data": [] }, "message": "Successful" };// await verinfo(this.state.itemactivo.idcuentas);
        const listainfo = await verinfo(this.state.itemactivo.idcuentas); //{ "code": 0, "data": { "mt940": 1, "mt942": 1, "mt940-data": [{ "idcuentaestadodecuentaregistros": "1", "fechainicio": "01/10/2022", "fechafin": "10/10/2022", "fechaaplicacion": "11/10/2022", "horaaplicacion": "11:30", "usuariocreacion": "Administrator Administrator", "creado": "22/03/2022 08:09:12 PM", "usuarioactualizacion": "", "actualizado": "", "generado": "1", "idcatalogoestatus": "1", "estatus": "Activo" }], "mt942-data": [{ "idcuentaestadodecuentaregistros": "2", "tipodetalle": "Todos", "montobase": "1000.10", "horainicio": "08:30", "horafin": "09:30", "intervalo": "30min", "ultimahorageneracion": "10:20", "usuariocreacion": "Administrator Administrator", "creado": "22/03/2022 08:09:12 PM", "usuarioactualizacion": "", "actualizado": "" }] }, "message": "Successful" };// await verinfo(this.state.itemactivo.idcuentas);

        //console.log(JSON.stringify(listainfo))
        //const cuentadetalles = await administracion_cuentadetalles(1);

        if (listaservicios.code == 0 && listainfo.code == 0) {

            var newarray = listaservicios.data.items; //agrego un compo extra al json que es elegir 
            for (let i = 0; i < newarray.length; i++) {

                if (i == 0) {
                    newarray[i].elegir = true;
                }
                else {
                    newarray[i].elegir = false;
                }
            }

            setTimeout(async () => {
                this.setState({ listasituacion: JSON.parse(JSON.stringify(newarray)) })
                this.setState({ listasituacion942: JSON.parse(JSON.stringify(newarray)) })

            }, 0);
            ////////////////////////////////////////////////////////////////////////////////////////
            this.setState({ idmt940: listainfo.data.idmt940 })
            if (listainfo.data.idmt940 == 0) {
                this.setState({ vertabla940: false })
            } else {
                this.setState({ vertabla940: true })
                var array_temp = listainfo.data['mt940-data'];

                for (let i = 0; i < array_temp.length; i++) {
                    array_temp[i].tipo = 0;
                    array_temp[i].accion = "ninguna";

                }
                this.setState({ arraycuentas: array_temp });
                //console.log(JSON.stringify(array_temp))
            }
            ///////////////////////////////////////////////////////////////////////
            this.setState({ idmt942: listainfo.data.idmt942 })
            if (listainfo.data.idmt942 >= 1) {

                var array_temp = listainfo.data['mt942-data'];
                this.setState({ arrayAPI942: array_temp });
                //console.log("----**----")
                //console.log(JSON.stringify(array_temp))



                if (array_temp.length > 0) {


                    for (let i = 0; i < this.state.arraytipo942.length; i++) {
                        if (array_temp[0].tipodetalle == this.state.arraytipo942[i].titulo) {
                            this.cambiartipo942(i, "no");
                            break;
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////////////////
                    for (let i = 0; i < this.state.arrayintervalo.length; i++) {
                        if (array_temp[0].intervalo == this.state.arrayintervalo[i].intervalo) {
                            this.seleccionarintervalo(i, "no");
                            break;
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////////////////
                    for (let i = 0; i < this.state.arraytime_horario_base.length; i++) {
                        if (array_temp[0].horariobase == this.state.arraytime_horario_base[i].hora) {
                            this.selecthorariobase(i, "no");
                            break;
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////////////////
                    for (let i = 0; i < this.state.arraytime_hora_inicio.length; i++) {
                        if (array_temp[0].horainicio == this.state.arraytime_hora_inicio[i].hora) {
                            this.selecthorainiciogeneracion(i, "no");
                            break;
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////////////////
                    for (let i = 0; i < this.state.arraytime_hora_fin.length; i++) {
                        if (array_temp[0].horafin == this.state.arraytime_hora_fin[i].hora) {
                            this.selecthorafingeneracion(i, "no");
                            break;
                        }
                    }

                    setTimeout(async () => {
                        /////////////////////////////////////////////////////////////////////////////////////////

                        for (let i = 0; i < this.state.listasituacion942.length; i++) {
                            if (array_temp[0].idcatalogoestatus == this.state.listasituacion942[i].idcatalogoestatus) {
                                this.cambiarestado2(i, "no");
                                break;
                            }
                        }

                        this.setState({ idcuentaestadodecuentaregistros942: array_temp[0].idcuentaestadodecuentaregistros });
                        this.setState({ activarformu: array_temp[0].activado == 1 ? true : false });
                        this.setState({ aplicaGeneracion: array_temp[0].activado == 1 ? true : false });
                        this.setState({ txtmontobase: array_temp[0].activado == 1 ? array_temp[0].montobase : '0' });
                        this.setState({ txtultimahoradetalles: array_temp[0].ultimahorageneracion });
                    }, 100);

                }
                this.setState({ vertabla942: true })


            } else {
                this.setState({ vertabla942: false })
            }

            /*
                      //console.log("los resultados")
            
                        let plantilla = crearplantilla([], [], listaservicios, this.state.itemactivo)
            
                        //llenar a plantilla con la info de edición
                        if (this.props.tipoaccion == 2) {
                            let plantillaeditada = editarplantilla(plantilla, this.props.itemactivo);
                            let nuevoformulario = this.props.elementosinput;
                            nuevoformulario[2].valor = this.props.itemactivo.correo;
                            this.setState({ itemactivo: plantillaeditada, inputs: nuevoformulario })
                        }
                        else if (this.props.tipoaccion == 1) {
                            let nuevoformulario = this.props.elementosinput;
                            this.setState({ itemactivo: plantilla, inputs: nuevoformulario })
                        }
                        else if (this.props.tipoaccion == 3) {
                            this.setState({ itemactivo: this.props.itemactivo })
                        }
            */
        }
        this.setState({ loadingImage: false });
    }






    cambiartipo942 = (pos, ban) => {

        if (ban != "no") {
            this.setState({ vercheckcambios942: true })
        }
        var da = this.state.arraytipo942;
        for (let i = 0; i < da.length; i++) {
            da[i].elegir = false;
        }
        da[pos].elegir = true;
        this.setState({ arraytipo942: da })
        this.setState({ txttipodetalle: da[pos].titulo })

    }

    cambiarestado1 = (pos) => {

        var da = this.state.listasituacion;
        for (let i = 0; i < da.length; i++) {
            da[i].elegir = false;
            if (i == pos) {
                da[pos].elegir = true;
            }
        }
        this.setState({ listasituacion: da })
        this.setState({ idcatalogoestatus940: da[pos].idcatalogoestatus })
        this.setState({ txtestatus940: da[pos].estatus })

    }

    cambiarestado2 = (pos, ban) => {
        //console.log("cambiarestado2()", pos, ban);
        if (ban != "no") {
            this.setState({ vercheckcambios942: true })
        }

        var da = this.state.listasituacion942;
        for (let i = 0; i < da.length; i++) {
            da[i].elegir = false;
        }
        da[pos].elegir = true;
        this.setState({ listasituacion942: da })
        this.setState({ idcatalogoestatus942: da[pos].idcatalogoestatus })
        this.setState({ txtestatus942: da[pos].estatus })

    }

    seleccionarintervalo = (pos, ban) => {
        if (ban != "no") {
            this.setState({ vercheckcambios942: true })
        }
        var da = this.state.arrayintervalo;
        for (let i = 0; i < da.length; i++) {
            da[i].elegir = false;
        }
        da[pos].elegir = true;
        this.setState({ arrayintervalo: da })
        this.setState({ selectintervalo: da[pos].intervalo })
    }

    ///
    selecthorariobase = (pos, ban) => {
        if (ban != "no") {
            this.setState({ vercheckcambios942: true })
        }
        var da = this.state.arraytime_horario_base;
        for (let i = 0; i < da.length; i++) {
            da[i].estatus = false;
        }
        da[pos].estatus = true;
        this.setState({ arraytime_horario_base: da })
        this.setState({ txthorariobase: da[pos].hora })
    }
    selecthorainiciogeneracion = (pos, ban) => {
        if (ban != "no") {
            this.setState({ vercheckcambios942: true })
        }
        var da = this.state.arraytime_hora_inicio;
        for (let i = 0; i < da.length; i++) {
            da[i].estatus = false;
        }
        da[pos].estatus = true;
        this.setState({ arraytime_hora_inicio: da })
        this.setState({ txthorainiciogeneracion: da[pos].hora })
    }
    selecthorafingeneracion = (pos, ban) => {
        if (ban != "no") {
            this.setState({ vercheckcambios942: true })
        }
        var da = this.state.arraytime_hora_fin;
        for (let i = 0; i < da.length; i++) {
            da[i].estatus = false;
        }
        da[pos].estatus = true;
        this.setState({ arraytime_hora_fin: da })
        this.setState({ txthorafingeneracion: da[pos].hora })

    }
    selecthoraultimageneracion = (pos) => {
        this.setState({ vercheckcambios942: true })
        this.setState({ txtultimahoradetalles: this.state.arraytime[pos].hora })
    }
    ///


    /*cambiarenvio = (envio) => {
      //console.log("cambiarenvio")
      //console.log(envio)

        let nuevalista = this.state.itemactivo

        nuevalista.notificaciones = envio.descripcion;

      //console.log("la nuevalista ")
      //console.log(nuevalista)
        this.setState({ itemactivo: nuevalista, colapsenotificaciones: false })

    }*/


    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }



    async guardarinfo() {



        this.setState({ errormonto: false })
        var array_temp = this.state.arraycuentas;
        var mt940 = [];
        for (let i = 0; i < array_temp.length; i++) {
            if (array_temp[i].accion != "ninguna") {

                mt940.push({
                    "accion": array_temp[i].accion,
                    "idcuentaestadodecuentaregistros": array_temp[i].accion == "nuevo" ? "0" : array_temp[i].idcuentaestadodecuentaregistros,
                    "idcuentaestadodecuenta": this.state.idmt940,
                    "fechainicio": array_temp[i].fechainicio,
                    "fechafin": array_temp[i].fechafin,
                    "fechaaplicacion": array_temp[i].fechaaplicacion,
                    "horaaplicacion": array_temp[i].horaaplicacion,
                    "idcatalogoestatus": array_temp[i].idcatalogoestatus
                })
            }
        }


        var array_temp2 = this.state.arraycuentasEliminadas;
        for (let i = 0; i < array_temp2.length; i++) {
            if (array_temp2[i].idcuentaestadodecuentaregistros != '' || array_temp2[i].idcuentaestadodecuentaregistros != 0) {
                mt940.push({
                    "accion": "eliminar",
                    "idcuentaestadodecuentaregistros": array_temp2[i].idcuentaestadodecuentaregistros,
                    "idcuentaestadodecuenta": this.state.idmt940,
                    "fechainicio": array_temp2[i].fechainicio,
                    "fechafin": array_temp2[i].fechafin,
                    "fechaaplicacion": array_temp2[i].fechaaplicacion,
                    "horaaplicacion": array_temp2[i].horaaplicacion,
                    "idcatalogoestatus": array_temp2[i].idcatalogoestatus
                })
            }
        }

        //console.log(JSON.stringify(mt940));
        //console.log("idmt940 " + this.state.idmt940);
        //console.log("idmt942 " + this.state.idmt942);
        /*
                var datos = []
                datos.push({
                    "verifica942": this.state.vercheckcambios942,
                    "tipodetalle": this.state.txttipodetalle,
                    "montobase": this.state.txtmontobase,
                    "horainicio": this.state.txthorainiciogeneracion,
                    "horafin": this.state.txthorafingeneracion,
                    "intervalo": this.state.selectintervalo,
                    "ultimahorageneracion": this.state.txtultimahoradetalles,
                    "idcatalogoestatus": this.state.idcatalogoestatus940,
                    "estatus": this.state.txtestatus940
        
                });*/
        var json942 = 0;
        //console.log("ttttttt");
        if (this.state.activarformu == true) {
            //console.log("aaaaaaa");
            if (this.state.vercheckcambios942 == true) {
                //console.log("bbbbbbb");

                //console.log("ccccccc");

                var monto = this.state.txtmontobase;
                if (monto == "") {
                    this.setState({ errormonto: true })
                    return;
                }
                else {
                    json942 = {};
                    if (this.state.vercheckcambios942 == true) {
                        json942 = {
                            "idcuentaestadodecuentaregistros": this.state.idcuentaestadodecuentaregistros942,
                            "idcuentaestadodecuenta": this.state.idmt942,
                            "activado": this.state.aplicaGeneracion == 1 ? 1 : 0,
                            "tipodetalle": this.state.txttipodetalle,
                            "montobase": this.state.txtmontobase,
                            "horariobase": this.state.txthorariobase,
                            "horainicio": this.state.txthorainiciogeneracion,
                            "horafin": this.state.txthorafingeneracion,
                            "intervalo": this.state.selectintervalo,
                            //  "ultimahorageneracion": this.state.txtultimahoradetalles,
                            "idcatalogoestatus": this.state.idcatalogoestatus942
                        }
                    }
                    //console.log(JSON.stringify(json942));
                }

            }
            else {
                //console.log("kkkkkkkkkkk");

            }


        }
        else {
            //console.log("BBBBB");
            if (this.state.vercheckcambios942 == true) {


                json942 = {};

                json942 = {
                    "idcuentaestadodecuentaregistros": this.state.idcuentaestadodecuentaregistros942,
                    "idcuentaestadodecuenta": this.state.idmt942,
                    "activado": this.state.aplicaGeneracion == 1 ? 1 : 0,
                    "tipodetalle": this.state.txttipodetalle,
                    "montobase": this.state.txtmontobase,
                    "horariobase": this.state.txthorariobase,
                    "horainicio": this.state.txthorainiciogeneracion,
                    "horafin": this.state.txthorafingeneracion,
                    "intervalo": this.state.selectintervalo,
                    //  "ultimahorageneracion": this.state.txtultimahoradetalles,
                    "idcatalogoestatus": this.state.idcatalogoestatus942
                }


            }
        }


        var resp = await actualizar_940(this.state.itemactivo.idcuentas, mt940, json942);

        //console.log(JSON.stringify(this.state.arraycuentasEliminadas));
        //        var resp = await actualizar_940(this.state.itemactivo.idcuentas, mt940);
        //console.log(JSON.stringify(resp));
        if (resp.code == 0) {
            toast.success("Guardado exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            this.showmodal();
        }
        else if (resp.code == 2) {
            toast.warn(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else {
            toast.error("Error al guardar, intente nuevamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }


    }




    verestatus = (opcion) => {
        //console.log("veresttus simple");
        this.setState({ colapsar: !this.state.colapsar })
    }

    verhoras = (opcion) => {
        //console.log("veresttus simple");
        this.setState({ colapsarhora: !this.state.colapsarhora })
    }


    seleccionarhora = (array) => {

        var da = this.state.arraytime;
        for (let i = 0; i < da.length; i++) {
            da[i].estatus = false;
        }
        da[array].estatus = true;
        let nuevoitem = [];
        nuevoitem.push(da[array]);
        this.setState({ arrayselecthora: nuevoitem })
        this.setState({ arraytime: da })

    }

    save_input_montobase = async (e) => {

        this.setState({ vercheckcambios942: true })
        let value = e.target.value;
        if (value.length > 10) {
            e.target.value = value.substring(0, 10);
            return;
        }
        this.setState({ txtmontobase: e.target.value });
    }

    save_input_tipodetalle = async (e) => {
        this.setState({ vercheckcambios942: true })
        this.setState({ txttipodetalle: e.target.value });
    }


    save_input_idfolio = async (e) => {
        this.setState({ txtidfolio: e.target.value });
    }

    cancelarsolicitud = () => {

        this.setState({ errorfechainicio: false });
        this.setState({ errorfechafin: false });
        this.setState({ pos_modificarcuentas: -1 });
        this.setState({ vercheckcambios940: true });
        this.setState({ txtidfolio: '' })
        this.setState({ fechainicio: this.state.fechaactual })
        this.setState({ fechafin: this.state.fechaactual })
        this.setState({ fechaaplicacion: this.state.fechaactual })
        this.cambiarestado1(0);
        this.seleccionarhora(0);
        this.setState({ nuevasolicitud: !this.state.nuevasolicitud })

    }

    agregaritem = (pos) => {


        this.setState({ errorfechafin: false })
        this.setState({ errorfechainicio: false })

        var fechainiciotemp = this.state.fechainicio.toString().split("/");
        var finicio = new Date(fechainiciotemp[2], fechainiciotemp[1] - 1, fechainiciotemp[0]);

        var fechafintemp = this.state.fechafin.toString().split("/");
        var ffin = new Date(fechafintemp[2], fechafintemp[1] - 1, fechafintemp[0]);

        var fechaactual1 = this.state.fechaactual.toString().split("/");
        var fechaactual = new Date(fechaactual1[2], fechaactual1[1] - 1, fechaactual1[0]);


        //console.log(fechainiciotemp)
        //console.log(fechafintemp)
        //console.log(fechaactual1)


        if (finicio < fechaactual) {
            //console.log("aaaaaaaaaa")
            if (ffin >= finicio && ffin < fechaactual) {
                //console.log("eeeeeeee")
            }
            else {
                this.setState({ errorfechafin: true })
                //console.log("ooooooooooo")
                return;
            }

        }
        else if (finicio > fechaactual) {
            //console.log(finicio + " > " + fechaactual);
            //console.log("iiiiiiiiiiii")
            this.setState({ errorfechainicio: true })
            return;
        }
        else {
            //console.log("uuuuuuuuuuuuuuu")
            this.setState({ errorfechainicio: true })
            return;
        }


        var array_temp = this.state.arraycuentas;
        if (this.state.pos_modificarcuentas != -1) {

            let accion = "";
            if (array_temp[this.state.pos_modificarcuentas].idcuentaestadodecuentaregistros != '0') {
                accion = "actualizar";
            }
            else {
                accion = "nuevo";
            }

            array_temp[this.state.pos_modificarcuentas].idcuentaestadodecuentaregistros = this.state.txtidfolio;
            array_temp[this.state.pos_modificarcuentas].fechainicio = this.state.fechainicio;
            array_temp[this.state.pos_modificarcuentas].fechafin = this.state.fechafin;
            array_temp[this.state.pos_modificarcuentas].fechaaplicacion = this.state.fechaaplicacion;
            array_temp[this.state.pos_modificarcuentas].horaaplicacion = this.state.arrayselecthora[0].hora;
            array_temp[this.state.pos_modificarcuentas].accion = accion;
            array_temp[this.state.pos_modificarcuentas].idcatalogoestatus = this.state.idcatalogoestatus940
            array_temp[this.state.pos_modificarcuentas].estatus = this.state.txtestatus940
        }

        else {
            array_temp.push({

                "idcuentaestadodecuentaregistros": this.state.txtidfolio,
                "fechainicio": this.state.fechainicio,
                "fechafin": this.state.fechafin,
                "fechaaplicacion": this.state.fechaaplicacion,
                "horaaplicacion": this.state.arrayselecthora[0].hora,
                "generado": 'Sin generar',
                "idcatalogoestatus": this.state.idcatalogoestatus940,
                "estatus": this.state.txtestatus940,
                "tipo": 0,//0 = nuevo --- 1 = existe
                "accion": "nuevo"

            });
        }

        //console.log(JSON.stringify(array_temp));

        this.setState({ pos_modificarcuentas: -1 });
        this.setState({ vercheckcambios940: true });
        this.setState({ txtidfolio: '' })
        this.setState({ fechainicio: this.state.fechaactual })
        this.setState({ fechafin: this.state.fechaactual })
        this.setState({ fechaaplicacion: this.state.fechaactual })
        this.cambiarestado1(0);
        this.seleccionarhora(0);


        this.setState({ nuevasolicitud: !this.state.nuevasolicitud });
        this.setState({ arraycuentas: array_temp });

    }
    activar942 = () => {

        this.setState({ aplicaGeneracion: !this.state.aplicaGeneracion })
        this.setState({ activarformu: !this.state.activarformu });

        this.setState({ txtmontobase: '0' })
        this.cambiartipo942(0);
        this.selecthorariobase(0);
        this.selecthorainiciogeneracion(0);
        this.selecthorafingeneracion(0);
        this.seleccionarintervalo(0);
        this.cambiarestado2(0);



    }
    eliminar = (pos) => {

        //console.log("pos " + pos)
        var array_temp = this.state.arraycuentas;

        if (array_temp[pos].idcuentaestadodecuentaregistros != '0') {
            var array_eliminar = this.state.arraycuentasEliminadas;
            array_temp[pos].accion = "Eliminado";
            array_eliminar.push(array_temp[pos]);
            this.setState({ arraycuentasEliminadas: array_eliminar });
        }
        array_temp.splice(pos, 1);
        array_temp.length <= 0 ? this.setState({ vercheckcambios940: false }) : this.setState({ vercheckcambios940: true });
        this.setState({ arraycuentas: array_temp });

    }


    editar = (pos) => {

        this.setState({ pos_modificarcuentas: pos });
        this.setState({ errorfolio: false });
        var array_temp = this.state.arraycuentas[pos];
        this.setState({ tiene_indicador_generado940: array_temp.generado == 1 ? true : false });

        this.setState({ txtidfolio: array_temp.idcuentaestadodecuentaregistros })
        this.setState({ fechainicio: array_temp.fechainicio })
        this.setState({ fechafin: array_temp.fechafin })
        this.setState({ fechaaplicacion: array_temp.fechaaplicacion });

        var poss = this.state.arraytime.findIndex(x => x.hora === array_temp.horaaplicacion);
        this.cambiarestado1(0);
        this.seleccionarhora(poss);
        this.setState({ accionsolicitud: "Editar" });
        this.setState({ nuevasolicitud: true });

    }


    opendatepicker = (opcion) => {
        this.setState({ showdatepicker: !this.state.showdatepicker, fechaactiva: opcion });
    }

    funnuevasolicitud = () => {
        this.cambiarestado1(0);
        this.setState({
            accionsolicitud: "Nuevo",
            tiene_indicador_generado940: false,
            nuevasolicitud: !this.state.nuevasolicitud
        });

    }


    handleDayClick = async (day, opcion) => {


        //console.log("1" + opcion);
        let fecha = day;
        var fecselect = fecha.split("/");
        var fechaactual = this.state.fechaactual.toString().split("/");

        if (opcion == 1) {

            this.setState({ errorfechainicio: false })
            var fechaactual = new Date(fechaactual[2], fechaactual[1] - 1, fechaactual[0]);
            var fechaelegir = new Date(fecselect[2], fecselect[1] - 1, fecselect[0]);

            if (fechaelegir < fechaactual) {
                this.setState({ fechainicio: fecha });
            }
            else if (fechaelegir > fechaactual) {
                this.setState({ errorfechainicio: true })
            }
            else {
                this.setState({ errorfechainicio: true })
            }

        }

        else if (opcion == 2) {

            this.setState({ errorfechafin: false })
            var fechainiciotemp = this.state.fechainicio.toString().split("/");
            var finicio = new Date(fechainiciotemp[2], fechainiciotemp[1] - 1, fechainiciotemp[0]);
            var fechaactual = new Date(fechaactual[2], fechaactual[1] - 1, fechaactual[0]);
            var fechaelegir = new Date(fecselect[2], fecselect[1] - 1, fecselect[0]);

            if (fechaelegir < fechaactual) {

                if (fechaelegir >= finicio) {
                    this.setState({ fechafin: fecha });
                }
                else {
                    this.setState({ errorfechafin: true })
                }

            }
            else if (fechaelegir > fechaactual) {
                this.setState({ errorfechafin: true })
            }
            else {
                this.setState({ errorfechafin: true })
            }
            this.setState({ fechafin: fecha });

        }

        else if (opcion == 3) {
            this.setState({ fechaaplicacion: fecha });
        }


    }


    /////////////////////////////////////////////////////////////////////////
    escribir = (prop) => (event) => {

        if (event.target.value == undefined) { return; }

        var dias = event.target.value.split("-");
        var fechaf = dias[2] + '/' + dias[1] + '/' + dias[0];
        this.setState({ fechaactiva: event.target.id });

        this.setState({ [prop]: fechaf });
        this.handleDayClick(fechaf, event.target.id);

    }

    regresaFecha = (fecha) => {

        var [day, month, year] = fecha.split("/");
        var fechaf = "";
        if (day != undefined && month != undefined && year != undefined) {
            fechaf = `${year}-${month}-${day}`;
            return fechaf;
        } else {
            fechaf = fecha;
        }
        return fechaf;

    }
    //////////////////////////////////////////////////////////////////////


    render() {
        return (
            <>

                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}


                <Modal isOpen={this.state.show}
                    aria-labelledby=""
                    centered
                    size="xl"
                    className="modal-cuentas ancho_modal_asociados"  >
                    <ModalBody>
                        <div>
                            <p className="modal-titulo">
                                <label className="modal-titulo-aux">{this.props.modaltitulo}
                                </label>

                                <label className="modal-cuentas-cerrar">
                                    <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> </Button>
                                </label>
                            </p>


                            <ToastContainer />

                            {/* medios ///////////////////////////////////////////////////////////// */}

                            {(this.state.vertabla940) ? (
                                <div className="row">

                                    <div className=" col-12 modal-cuentas-tablecontainer">

                                        {/* servicios ///////////////////////////////////////////////////////////// */}


                                        <p className="modal-titulo">MT940 Solicita estado de cuenta </p>


                                        {this.state.nuevasolicitud == true ?
                                            <>
                                                <div className="modal-cuentas-mainbody fondoblancocuentas">
                                                    <Row className="sinmarginrow" >
                                                        <Col>
                                                            <p className="modal-titulo">{this.state.accionsolicitud == "Nuevo" ? "Nueva solicitud" : "Editar solicitud"}</p>
                                                        </Col>

                                                        {this.state.tiene_indicador_generado940 ?
                                                            <Col className="col-auto">
                                                                <p className="lbltitlesr">Indicado de generación <BsCircleFill className="icoazulasociadas" /></p>
                                                            </Col>
                                                            : null}

                                                    </Row>
                                                    <Row className="sinmarginrow align-items-end" >

                                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                            <p className="lbltitlesr">Fecha Inicio:</p>

                                                            {this.state.tiene_indicador_generado940 ?
                                                                <button className="txtnuevasolicitud txtnuevasolicitud2" title="No modificable">
                                                                    <b>{this.state.fechainicio}</b>
                                                                </button>
                                                                :
                                                                <input className='bitacoragestion-fechafin txtnuevasolicitud input_fecha_new' id={'1'} type={'date'} value={this.regresaFecha(this.state.fechainicio)} onChange={this.escribir("fechainicio")}></input>
                                                            }

                                                            {this.state.errorfechainicio ? <p className=" lblmessagefile_mensaerror lblmessagefile-red">Fecha de inicio inválida</p> : null}

                                                        </Col>

                                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                            <p className="lbltitlesr">Fecha final:</p>
                                                            {this.state.tiene_indicador_generado940 ?
                                                                <button toltip className="txtnuevasolicitud txtnuevasolicitud2" title="No modificable">
                                                                    <b>{this.state.fechafin}</b>
                                                                </button>
                                                                :
                                                                <input className='bitacoragestion-fechafin txtnuevasolicitud input_fecha_new' id={'2'}
                                                                    type={'date'} value={this.regresaFecha(this.state.fechafin)} onChange={this.escribir("fechafin")}></input>
                                                            }
                                                            {this.state.errorfechafin ? <p className=" lblmessagefile_mensaerror lblmessagefile-red">Fecha de terminación inválida</p> : null}
                                                        </Col>


                                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                            <p className="lbltitlesr">Situacion:</p>

                                                            <select className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.cambiarestado1(event.target.value)}>
                                                                {this.state.listasituacion.map((estado, index) => (


                                                                    estado.elegir == true ?
                                                                        <option selected style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                            value={index} key={"selext_" + index}>{estado.estatus}
                                                                        </option>
                                                                        :
                                                                        <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                            value={index} key={"selext_" + index}>{estado.estatus}
                                                                        </option>

                                                                ))}
                                                            </select>

                                                        </Col>


                                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                            <p className="lbltitlesr">Fecha aplicacion:</p>

                                                            <input className='bitacoragestion-fechafin txtnuevasolicitud input_fecha_new' id={'3'}
                                                                type={'date'} value={this.regresaFecha(this.state.fechaaplicacion)} onChange={this.escribir("fechaaplicacion")}></input>

                                                        </Col>

                                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                            <p className="lbltitlesr">Hora aplicacion:</p>

                                                            <select className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.seleccionarhora(event.target.value)}>
                                                                {this.state.arraytime.map((i, index) => (

                                                                    i.estatus == true ?
                                                                        <option selected style={{ padding: '5px', color: 'black' }}
                                                                            value={index} key={"hora_" + index}>{i.hora}
                                                                        </option>
                                                                        :
                                                                        <option style={{ padding: '5px', color: 'black' }}
                                                                            value={index} key={"hora_" + index}>{i.hora}
                                                                        </option>

                                                                ))}
                                                            </select>

                                                        </Col>







                                                        <Col className="col-lg-2 col-md-5 col-sm-5 col-12 ">
                                                            <br />
                                                            <Button className="cancelbutton btnmodalcuentaasociada" disabled={''} onClick={this.cancelarsolicitud}>Cancelar</Button>
                                                        </Col>


                                                        <Col className="col-lg-2 col-md-5 col-sm-4 col-12">
                                                            <br />
                                                            <Button className="bluebutton btnmodalcuentaasociada" disabled={''} onClick={() => this.agregaritem()}>{this.state.accionsolicitud == "Nuevo" ? "Agregar" : "Actualizar"}</Button>
                                                        </Col>






                                                    </Row>
                                                    <br />
                                                    {/*
                                                      
                                                        <Row className="sinmarginrow" >
                                                            <Col className="ocultarcol"></Col>

                                                            <Col className="col-auto">
                                                                <Button className="cancelbutton btnmodalcuentaasociada" disabled={''} onClick={this.cancelarsolicitud}>Cancelar</Button>
                                                            </Col>

                                                            <Col className="col-auto">
                                                                <Button className="bluebutton btnmodalcuentaasociada" disabled={''} onClick={() => this.agregaritem()}>{this.state.accionsolicitud == "Nuevo" ? "Agregar" : "Actualizar"}</Button>
                                                            </Col>
                                                        </Row>*/}

                                                </div>

                                                <br /></>
                                            : null}

                                        {this.state.nuevasolicitud == false && this.props.tipoaccion != 3 ?
                                            <Row className="sinmarginrow" >

                                                <Col className="ocultarcol"></Col>



                                                <Col className="col-auto">
                                                    <Button className="bluebutton btnmodalcuentaasociada" disabled={''}
                                                        onClick={this.funnuevasolicitud}>Nueva Solicitud</Button>
                                                </Col>

                                            </Row>
                                            : null}
                                        <br />


                                        <div style={{ width: "100%", overflowX: "scroll" }}>
                                            <Table className="panel-table">


                                                <thead>
                                                    <tr>
                                                        {this.state.headerservicios.map(head => (
                                                            <th>{head}</th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                {this.state.arraycuentas.length > 0 ? (
                                                    <tbody>


                                                        {this.state.arraycuentas.map((servicio, index) => (
                                                            <tr>
                                                                <td>{servicio.idcuentaestadodecuentaregistros}</td>
                                                                <td>{servicio.fechainicio}</td>


                                                                <td>{servicio.fechafin}</td>
                                                                <td>{servicio.fechaaplicacion}</td>
                                                                <td>{servicio.horaaplicacion}</td>
                                                                <td>{servicio.generado == 1 ? 'Generado' : "Sin generar"}</td>
                                                                <td>{servicio.estatus}</td>


                                                                <td>{servicio.usuariocreacion}</td>
                                                                <td>{servicio.creado}</td>
                                                                <td>{servicio.usuarioactualizacion}</td>
                                                                <td>{servicio.actualizado}</td>
                                                                <td>{servicio.tipo == 0 ? <>
                                                                    {this.props.tipoaccion != 3 ? <>
                                                                        <BiEdit className="icoedit" onClick={this.editar.bind(this, index)} />
                                                                        {/*<BsTrash className="icodelate" onClick={this.eliminar.bind(this, index)} />*/}
                                                                    </> : null}
                                                                </>
                                                                    : servicio.tipo == 1 ? <BiEdit className="icoedit" onClick={this.editar.bind(this, index)} /> : null}
                                                                </td>
                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                ) :
                                                    null}
                                            </Table>

                                        </div>

                                        {this.state.arraycuentas.length < 1 ? (
                                            <p className="text-center"> Tabla vacía  </p>
                                        ) : null}


                                        {/*this.state.vercheckcambios940 ?
                                                <div className="modal-catalogo-checkinput" style={{ margin: 'auto' }} onClick={() => this.setState({ confirmarenvio940: !this.state.confirmarenvio940 })}>
                                                    <input type="checkbox" checked={this.state.confirmarenvio940} /> Confirmar envío de formulario
                                                </div>
                                                : null*/}

                                    </div>

                                </div>
                            ) :
                                null
                            }


                            {this.state.vertabla942 ? (

                                <div className="row">

                                    <div className=" col-12 modal-cuentas-tablecontainer">

                                        {/* servicios ///////////////////////////////////////////////////////////// */}

                                        <p className="modal-titulo">
                                            <p className="modal-titulo-aux">Factores MT942 Generación de estados de cuenta intardía
                                            </p>
                                        </p>



                                        <div className="modal-cuentas-mainbody fondoblancocuentas">

                                            {(this.props.tipoaccion == 3) ? (
                                                <>
                                                    <div className="modal-catalogo-checkinput">
                                                        <input type="checkbox" checked={this.state.aplicaGeneracion} /><b>Aplicar generación MT942</b>
                                                    </div>
                                                </>
                                            ) :
                                                <div className="modal-catalogo-checkinput" onClick={this.activar942}>
                                                    <input type="checkbox" checked={this.state.aplicaGeneracion} /><b>Aplicar generación MT942</b>
                                                </div>


                                            }


                                            {/*(this.props.tipoaccion != 3 && this.state.activarformu != false) ?
                                                (
                                                    <div className="modal-catalogo-checkinput" onClick={this.activar942}>
                                                        <input type="checkbox" checked={this.state.aplicaGeneracion} /><b>Aplicar generación MT942</b>
                                                    </div>
                                                ) :
                                                <div className="modal-catalogo-checkinput" onClick={this.activar942}>
                                                    <input type="checkbox" checked={this.state.aplicaGeneracion} /><b>Aplicar generación MT942</b>
                                                </div>
                                            */}


                                            {/*<Row className="sinmarginrow" >
                                                <Col className="col-12">
                                                    {(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (<div className="modal-catalogo-checkinput" onClick={() => this.setState({ aplicaGeneracion: !this.state.aplicaGeneracion, vercheckcambios942: true })}>
                                                        <input type="checkbox" checked={this.state.aplicaGeneracion} /><b>Aplicar generación MT942</b>
                                                    </div>) :


                                                        <div className="modal-catalogo-checkinput" >
                                                            <input type="checkbox" checked={false} /><b>Aplicar generación MT942</b>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className="col-auto">
                                                  
                                                    {this.state.activarformu == false && this.props.tipoaccion != 3 ?
                                                        <Button className="bluebutton btnmodalcuentaasociada" onClick={this.activar942}>Activar</Button>
                                                        : null}
                                                </Col>
                                            </Row>
                                            */}

                                            <Row className="sinmarginrow" >


                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr">Tipo de <br className="saltostitles" />detalle:</p>

                                                    <select disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} onChange={(event) => this.cambiartipo942(event.target.value)}
                                                        className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" >
                                                        {this.state.arraytipo942.map((estado, index) => (

                                                            <>
                                                                {this.state.activarformu == false ? (
                                                                    <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                        value={-1} key={"selext_" + index}>---------------
                                                                    </option>
                                                                ) : <>
                                                                    {
                                                                        estado.elegir == true ?
                                                                            <option selected style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                                value={index} key={"selext_" + index}>{estado.titulo}
                                                                            </option>
                                                                            :
                                                                            <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                                value={index} key={"selext_" + index}>{estado.titulo}
                                                                            </option>
                                                                    }
                                                                </>
                                                                }
                                                            </>

                                                        ))}
                                                    </select>

                                                </Col>


                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr">Monto base <br className="saltostitles" />a generar:</p>

                                                    <input disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} type={'number'}
                                                        value={this.state.txtmontobase} onChange={this.save_input_montobase}
                                                        className="txtnuevasolicitud txtnuevasolicitud1" ></input>

                                                    {this.state.errormonto ? <p className=" lblmessagefile_mensaerror lblmessagefile-red">Campo obligatorio</p> : null}

                                                </Col>


                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr">Horario <br className="saltostitles" />base: </p>


                                                    <select disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.selecthorariobase(event.target.value)}>
                                                        {this.state.arraytime_horario_base.map((i, index) => (
                                                            <>
                                                                {this.state.activarformu == false ? (
                                                                    <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                        value={-1} key={"selext_" + index}>---------------
                                                                    </option>
                                                                ) : <>
                                                                    {
                                                                        i.estatus == true ?
                                                                            <option selected style={{ padding: '5px', color: 'black' }}
                                                                                value={index} key={"hora_" + index}>{i.hora}
                                                                            </option>
                                                                            :
                                                                            <option style={{ padding: '5px', color: 'black' }}
                                                                                value={index} key={"hora_" + index}>{i.hora}
                                                                            </option>
                                                                    }
                                                                </>
                                                                }
                                                            </>


                                                        ))}
                                                    </select>



                                                </Col>





                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr">Hora inicio <br className="saltostitles" />generación:</p>


                                                    <select disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.selecthorainiciogeneracion(event.target.value)}>
                                                        {this.state.arraytime_hora_inicio.map((i, index) => (
                                                            <>
                                                                {this.state.activarformu == false ? (
                                                                    <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                        value={-1} key={"selext_" + index}>---------------
                                                                    </option>
                                                                ) : <>
                                                                    {
                                                                        i.estatus == true ?
                                                                            <option selected style={{ padding: '5px', color: 'black' }}
                                                                                value={index} key={"hora_" + index}>{i.hora}
                                                                            </option>
                                                                            :
                                                                            <option style={{ padding: '5px', color: 'black' }}
                                                                                value={index} key={"hora_" + index}>{i.hora}
                                                                            </option>
                                                                    }
                                                                </>
                                                                }
                                                            </>


                                                        ))}
                                                    </select>



                                                </Col>






                                            </Row>

                                            <br />

                                            <Row className="sinmarginrow" >

                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr">Hora fin <br className="saltostitles" />generación:</p>
                                                    <select disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.selecthorafingeneracion(event.target.value)}>
                                                        {this.state.arraytime_hora_fin.map((i, index) => (

                                                            <>
                                                                {this.state.activarformu == false ? (
                                                                    <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                        value={-1} key={"selext_" + index}>---------------
                                                                    </option>
                                                                ) : <>
                                                                    {
                                                                        i.estatus == true ?
                                                                            <option selected style={{ padding: '5px', color: 'black' }}
                                                                                value={index} key={"hora_" + index}>{i.hora}
                                                                            </option>
                                                                            :
                                                                            <option style={{ padding: '5px', color: 'black' }}
                                                                                value={index} key={"hora_" + index}>{i.hora}
                                                                            </option>
                                                                    }
                                                                </>
                                                                }
                                                            </>

                                                        ))}
                                                    </select>
                                                </Col>



                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr">Intervalo de <br className="saltostitles" />generación:</p>
                                                    <select disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.seleccionarintervalo(event.target.value)}>
                                                        {this.state.arrayintervalo.map((i, index) => (

                                                            <>
                                                                {this.state.activarformu == false ? (
                                                                    <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                        value={-1} key={"selext_" + index}>---------------
                                                                    </option>
                                                                ) : <>
                                                                    {
                                                                        i.elegir == true ?
                                                                            <option selected style={{ padding: '5px', color: 'black' }} value={index} key={"i_" + index}>{i.intervalo}</option>
                                                                            :
                                                                            <option style={{ padding: '5px', color: 'black' }} value={index} key={"i_" + index}>{i.intervalo}</option>
                                                                    }
                                                                </>
                                                                }
                                                            </>


                                                        ))}
                                                    </select>
                                                </Col>


                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">

                                                    <p className="lbltitlesr">Hora última <br className="saltostitles" />generación:</p>
                                                    <input disabled={true} value={this.state.txtultimahoradetalles} placeholder={'No editable'}
                                                        className="txtnuevasolicitud txtnuevasolicitud1" ></input>

                                                </Col>



                                                <Col className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <p className="lbltitlesr"><br className="saltostitles" />Situación:</p>
                                                    <select disabled={(this.props.tipoaccion != 3 && this.state.activarformu != false) ? (false) : true} className="blueoutlinebutton txtnuevasolicitud txtnuevasolicitud6" onChange={(event) => this.cambiarestado2(event.target.value)}>
                                                        {this.state.listasituacion942.map((estado, index) => (
                                                            <>

                                                                {this.state.activarformu == false ? (
                                                                    <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                        value={-1} key={"selext_" + index}>---------------
                                                                    </option>
                                                                ) : <>
                                                                    {
                                                                        estado.elegir == true ?
                                                                            <option selected style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                                value={index} key={"selext_" + index}>{estado.estatus}
                                                                            </option>
                                                                            :
                                                                            <option style={{ paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
                                                                                value={index} key={"selext_" + index}>{estado.estatus}
                                                                            </option>
                                                                    }
                                                                </>
                                                                }
                                                            </>
                                                        ))}
                                                    </select>

                                                </Col>

                                                <Col className="col-auto">
                                                    {/* <p className="lbltitlesr">Generación diaria activa MT942 <BsCircleFill className="icoazulasociadas" /></p>*/}

                                                </Col>


                                            </Row>

                                            <br />




                                            {(this.state.arrayAPI942.length > 0) ? ( //info de [ultima edici[on de elemento]]
                                                <div className="col-12">
                                                    <div className="row  modal-cuentas-maifooter">
                                                        <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-creacion" >
                                                            <label>Creado por: </label>
                                                            <b style={{ fontWeight: '600' }}>
                                                                <div>{this.state.arrayAPI942[0].usuariocreacion}</div>
                                                            </b>
                                                        </div>
                                                        <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-creacion" >
                                                            <label>Fecha de creación: </label>
                                                            <b style={{ fontWeight: '600' }}>
                                                                <div>{this.state.arrayAPI942[0].creado}</div>
                                                            </b>
                                                        </div>

                                                        <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-actualizacion" >
                                                            <label>Actualziado por: </label>
                                                            <b style={{ fontWeight: '600' }}>
                                                                <div>{this.state.arrayAPI942[0].usuarioactualizacion}</div>
                                                            </b>
                                                        </div>
                                                        <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-actualizacion" >
                                                            <label>Última actualización: </label>
                                                            <b style={{ fontWeight: '600' }}>
                                                                <div>{this.state.arrayAPI942[0].actualizado}</div>
                                                            </b>
                                                        </div>

                                                    </div>
                                                </div>

                                            ) : null}


                                        </div>


                                    </div>

                                </div>

                            ) : <><br /><br /></>}



                        </div>

                        {(this.props.tipoaccion != 3) ? (
                            <>
                                <div className="modal-cuentas-pie-edicion " style={{ margin: "auto", width: "fit-content", marginBottom: "20px" }}>

                                    <div className="modal-catalogo-checkinput modal-cuentas-checkinput "
                                        style={{ margin: "auto" }}
                                        onClick={() => this.setState({ confirmarenvio940: !this.state.confirmarenvio940 })}>
                                        <input type="checkbox" checked={this.state.confirmarenvio940} /> Confirmar envío de formulario
                                    </div>

                                    <div className="modal-catalogos-buttons " style={{ maxWidth: "400px", margin: "auto", marginTop: "10px" }}>
                                        <Button className="cancelbutton" onClick={() => this.showmodal()}><GoX /> Cancelar</Button>
                                        <Button className="bluebutton" onClick={() => this.guardarinfo()} disabled={!this.state.confirmarenvio940} > <BsCheckLg /> Guardar</Button>
                                    </div>

                                </div>
                            </>
                        ) : null}

                    </ModalBody>
                </Modal>
            </>
        );
    }
}




function crearplantilla(situaciones, medios, servicios, itemactivo) {

    let listamedios = []


    let plantilla = {
        actualizado: "",
        cliente: "",
        correo: "",
        creado: "",
        cuenta: "",
        estatus: "",
        familia: "",
        idcuentas: "",
        medio: listamedios,
        medios: "",
        notificaciones: "",
        servicios: "",
        usuarioactualizacion: "",
        usuariocreacion: "",
    }

    //console.log("la plantilla")
    //console.log(plantilla);

    return plantilla

}




function serviciosfinal(listaservicios) {
    let serviciosfinal = [];
    listaservicios.data.items.forEach(elemento => {
        serviciosfinal.push(
            {
                idcatalogoservicios: elemento.idcatalogoservicios,
                servicio: elemento.servicio,
                carpetaentrada: elemento.carpetaentrada,
                carpetasalida: elemento.carpetasalida,
                usuariocreacion: "",
                usuarioactualizacion: "",
                creado: "",
                actualizado: "",
                estatus: "",
                idcatalogoestatus: "",
                //idcatalogoservicios: "",
                idcuentacanalarchivosservicio: ""
            }
        )
    });

    return serviciosfinal
}


function editarservicios(listaservicios, activos) {
    let serviciosfinal = [];
    listaservicios.forEach(sv => {
        let activo = null;

        if (activos != undefined)
            activo = activos.servicios.find(x => x.idcatalogoservicios == sv.idcatalogoservicios)

        serviciosfinal.push(
            {
                idcatalogoservicios: sv.idcatalogoservicios,
                servicio: sv.servicio,
                carpetaentrada: sv.carpetaentrada,
                carpetasalida: sv.carpetasalida,

                usuariocreacion: (activo) ? (activo.usuariocreacion) : "",
                usuarioactualizacion: (activo) ? (activo.usuarioactualizacion) : "",
                creado: (activo) ? (activo.creado) : "",
                actualizado: (activo) ? (activo.actualizado) : "",
                estatus: (activo) ? (activo.estatus) : "",
                idcatalogoestatus: (activo) ? (activo.idcatalogoestatus) : "",
                // idcatalogoservicios:(activo)?(activo.idcatalogoservicios):"",
                idcuentacanalarchivosservicio: (activo) ? (activo.idcuentacanalarchivosservicio) : "",
            }
        )
    });

    return serviciosfinal
}



