import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import { RiRefreshLine } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { administracion_cuentadetalles, administracion_cuentas } from "../../Services/Api";
import AreaMensajes from "../Modales/AreaMensajes";
import ModalCuentas from "../Modales/ModalCuentas";
import { BotonesVerEditar } from "../Panel/BotonesVerEditar";
import Nabvar from "../Panel/Nabvar";
import Paginacion, { buildContent } from "../Panel/Paginacion";
import { listainputsCuentas, ordenarCuenta, ordenartablacuentas } from "./Complementos/CuentasOrdenar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";

export default class Cuentas2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Cuentas",
            botoncrear: "Crear nueva cuenta",
            tabla: {
                encabezados: ["ID", "Cuenta", "Nombre", "E-mail", "Familia", "Envío de resultados", "Situación"],
                dimensiones: ["10%", "10%", "10%", "10%", "20%", "20%", "10%"],
                mindimensiones: ["70px", "100px", "100px", "200px", "100px", "200px", "120px"],
                elementos: []
            },
            modalelementos: false,
            tipoaccion: null,
            modaltitulo: "",
            itemactivo: "",
            itemtitulos: ["ID", "Cuenta", "Nombre", "E-mail", "Familia", "Envío de resultados", "Situación", "Creado por", "Fecha de creación", "Ultima actualizacion", "Fecha de actualización"],
            itemver: [],
            contenido: [],
            permisoedicion: false,
            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            loadingImage: true,
            actualordenado: "",
            tipomensaje: 1,

            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: ""
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        const lista = await administracion_cuentas();
        if (lista.code == 0) {
            let listaitems = this.state.tabla;
            listaitems.elementos = lista.data.items;
            this.setState({ tabla: listaitems, actualordenado: "", showmensaje: false });
            this.handleCommentsChange(this.state.activePage)

            //toast.success("Carga de tabla exitosa",  { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            //verificar permisos
            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }



    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        //console.log("Cuentas");
        await this.refreshInfo();
    }

    crearelemento = async (item) => {
        this.setState({ tipoaccion: item.origen, loadingImage: true });

        let respuesta = await this.generarcuentaactiva(item.idelemento)
        if (respuesta.validar == true) {
            let elemento = respuesta.contenido;
            let lista = [
                elemento.idcuentas,
                elemento.cuenta,
                elemento.cliente,
                elemento.correo,
                elemento.familia,
                elemento.notificaciones,
                elemento.estatus,
                elemento.usuariocreacion,
                elemento.creado,
                elemento.usuarioactualizacion,
                elemento.actualizado,
            ];
            if (item.origen == 1) {
                this.estadosmodalcatalogo(lista, "Nueva Cuenta");
            }

            if (item.origen == 2) {
                this.estadosmodalcatalogo(lista, "Editar Cuenta [" + elemento.cliente + "]");
            }

            if (item.origen == 3) {
                this.estadosmodalcatalogo(lista, "Información Cuenta [" + elemento.cliente + "]");
            }
        } else {
            setTimeout(() => {
                this.setState({ loadingImage: false })
            }, 200);
        }
    }

    estadosmodalcatalogo = (lista, encabezadomodal) => {
        setTimeout(() => {
            this.setState({
                modaltitulo: encabezadomodal,
                modalelementos: true,
                itemver: lista,
                loadingImage: false
            });
        }, 200);
    }

    generarcuentaactiva = async (idcuenta) => {
        const cuentadetalles = await administracion_cuentadetalles(idcuenta);
        if (cuentadetalles.code == 0) {
            this.setState({ contenido:  cuentadetalles.data.items[0] });
            this.setState({ itemactivo: cuentadetalles.data.items[0] });
            let nuevoelemento = ordenarCuenta(cuentadetalles.data.items[0]);
            return { validar: true, contenido: nuevoelemento[0] }
        }
        else {
            if (cuentadetalles.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (cuentadetalles.code == 2) {
                toast.warn(cuentadetalles.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            return { validar: false, contenido: [] }
        }
    }

    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false });
    }

    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }

    handleCommentsChange = (pageNumber) => {

        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)

            });
        }, 0);
    }

    ordenar = (opcion) => {
        let actual = this.state.tabla;
        let x = ordenartablacuentas(opcion, actual, this.state.actualordenado);
        actual.elementos = x;
        this.setState({ tabla: actual, actualordenado: opcion });
        this.handleCommentsChange(this.state.activePage)
    }


    iniciarspinner = () => {
        this.setState({ loadingImage: true })
    }

    detenerspinner = () => {

        this.setState({ loadingImage: false })

    }


    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}
                {(this.state.modalelementos) ? (
                    <ModalCuentas
                        contenido={this.state.contenido}
                        show={this.state.modalelementos}
                        modaltitulo={this.state.modaltitulo}
                        changestate={() => this.cerrarmodalcatalogo()}
                        tipovista={this.state.tipoaccion}
                        elementosinput={listainputsCuentas()}
                        itemtitulos={this.state.itemtitulos}
                        itemver={this.state.itemver}
                        itemactivo={this.state.itemactivo}
                        iniciarspinner={this.iniciarspinner}
                        detenerspinner={this.detenerspinner}
                        refreshInfo={this.refreshInfo}
                    />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body ">

                        <div className="panel-body-header-table">
                            {this.state.titulotabla}
                            <div className="panel-body-header-table-buttons ">
                                <div>
                                    <Button className="greenoutlinebutton" onClick={this.refreshInfo}><RiRefreshLine /> Refrescar Lista</Button>
                                </div>
                                <div >
                                    <Button
                                        className={(this.state.permisoedicion) ? ("bluebutton") : "cancelbutton"}
                                        disabled={(this.state.permisoedicion) ? (false) : true}
                                        onClick={(this.state.permisoedicion) ? (
                                            () => this.crearelemento({ origen: 1, idelemento: "" })
                                        ) : (
                                            () => console.log("no tienes permiso")
                                        )
                                        }
                                    > <BsPlusLg /> {this.state.botoncrear}</Button>
                                </div>
                            </div>
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>
                                {(this.state.tabla.elementos.length > 0) ? (
                                    <>
                                        <Table className="panel-table"  >
                                            <thead>
                                                <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                            style={{ width: this.state.tabla.dimensiones[index], minWidth: this.state.tabla.mindimensiones[index] }}
                                                            onClick={() => this.ordenar(index)}>
                                                            {titulo} <FaSort className={(this.state.actualordenado == index) ? ("tables-iconsort tables-actualsorting") : "tables-iconsort"} />
                                                        </th>
                                                    ))}
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.listapaginada.map((item, index) => (
                                                    <tr key={"tablaitemtr" + index}>
                                                        <td className={"panel-table-cell" + 0}>
                                                            {item.idcuentas}
                                                        </td>
                                                        <td className={"panel-table-cell" + 1}>
                                                            {item.cuenta}
                                                        </td>
                                                        <td className={"panel-table-cell" + 2}>
                                                            {item.cliente}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3} >
                                                            <label style={{ width: "300px" }}>
                                                                {item.correo}
                                                            </label>
                                                        </td>
                                                        <td className={"panel-table-cell" + 4}>
                                                            {item.familia}
                                                        </td>
                                                        <td className={"panel-table-cell" + 5}>
                                                            {item.notificaciones}
                                                        </td>
                                                        <td className={"panel-table-cell" + 6 + " item-status"}>
                                                            {item.estatus}
                                                        </td>
                                                        <td className="panel-table-buttons">
                                                            <BotonesVerEditar permisoedicion={this.state.permisoedicion} id={item.idcuentas} crearelemento={this.crearelemento} />
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>

                                        <div className="panel-pagination-section">
                                            <Paginacion
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsperpage}
                                                totalItemsCount={this.state.tabla.elementos}
                                                pageRangeDisplayed={this.state.rangepages}
                                                handleCommentsChange={(e) => this.handleCommentsChange(e)}
                                            />
                                        </div>
                                    </>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }
                            </>
                        }

                    </div>

                    <div className="panel-footer">
                        <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>

            </>
        );
    }
}