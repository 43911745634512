export function crearplantilla(medios, servicios, estados, cuenta, tipovista) {


  

    let plantilla = {
        actualizar: (tipovista == 2) ? ("0") : "",
        actualizado: (tipovista == 2) ? (cuenta.actualizado) : "",
        cliente: (tipovista == 2) ? (cuenta.cliente) : "",
        contactossms: (listacontactos(cuenta.contactossms, tipovista)),
        correo: (tipovista == 2) ? (cuenta.correo) : "",
        creado: (tipovista == 2) ? (cuenta.creado) : "",
        cuenta: (tipovista == 2) ? (cuenta.cuenta) : "",
        estatus: (tipovista == 2) ? (cuenta.estatus) : "",
        estatussms: (tipovista == 2) ? (cuenta.estatussms) : "0",
        familia: (tipovista == 2) ? (cuenta.familia) : "",
        idcatalogoenviarresultados: (tipovista == 2) ? (cuenta.idcatalogoenviarresultados) : "",
        idcatalogoestatus: (tipovista == 2) ? (cuenta.idcatalogoestatus) : "",
        idcatalogofamilias: (tipovista == 2) ? (cuenta.idcatalogofamilias) : "",
        idcuentas: (tipovista == 2) ? (cuenta.idcuentas) : "",
        medio: (listamedios(medios.data.items, servicios.data.items, estados.data.items, tipovista, cuenta.medio)),
        medios: (tipovista == 2) ? (cuenta.medios) : "",
        notificaciones: (tipovista == 2) ? (cuenta.notificaciones) : "",
        servicios: (tipovista == 2) ? (cuenta.servicios) : "",
        usuarioactualizacion: (tipovista == 2) ? (cuenta.usuarioactualizacion) : "",
        usuariocreacion: (tipovista == 2) ? (cuenta.usuariocreacion) : "",
    }

    return plantilla

}

function listacontactos(contactos, tipovista) {
    let listacontactos = [];

    if (tipovista == 2) {
        contactos.forEach(item => {
            listacontactos.push({
                "actualizar":"0",
                "idcuentacontactossms": item.idcuentacontactossms,
                "nombrecontacto": item.nombrecontacto,
                "puesto": item.puesto,
                "telefono": item.telefono,
                "usuariocreacion": item.usuariocreacion,
                "creado": item.creado,
                "usuarioactualizacion": item.usuarioactualizacion,
                "actualizado": item.actualizado,
                "idcatalogoestatus": item.idcatalogoestatus,
                "estatus": item.estatus
            })
        });
    }

    return listacontactos
}











function listamedios(medios, servicios, estados, tipovista, cuentamedio) {
    let listamedios = [];

    if (tipovista == 1) {// nueva cuenta
        medios.forEach(medioitem => {
            listamedios.push({
                actualizado: "",
                canal: medioitem.canal,
                creado: "",
                estatus: "",
                idcatalogocanalarchivos: medioitem.idcatalogocanalarchivos,
                idcuentacanalarchivos: "",
                servicios: listaservicios(servicios, tipovista, []),
                usuarioactualizacion: "",
                usuariocreacion: "",

                idcatalogoestatus: "",
                //estados:  listaestados(estados, tipovista, []),
            })
        });
    }
    else if (tipovista == 2) {
        medios.forEach(medioitem => {

            let encontrado = null
            if (cuentamedio.length > 0) {
                encontrado = cuentamedio.find(x => x.idcatalogocanalarchivos == medioitem.idcatalogocanalarchivos);
            }

            listamedios.push({
                actualizar: "0",
                actualizado:  (encontrado) ? (encontrado.actualizado) : "",
                canal: medioitem.canal,
                creado: (encontrado) ? (encontrado.creado) : "",
                estatus: (encontrado) ? (encontrado.estatus) : "",
                idcatalogocanalarchivos: medioitem.idcatalogocanalarchivos,
                idcuentacanalarchivos: (encontrado) ? (encontrado.idcuentacanalarchivos) : "",
                servicios: listaservicios(servicios, tipovista, (encontrado != null) ? (encontrado.servicios) : []),
                usuarioactualizacion: (encontrado) ? (encontrado.usuarioactualizacion) : "",
                usuariocreacion: (encontrado) ? (encontrado.usuariocreacion) : "",

                idcatalogoestatus: (encontrado) ? (encontrado.idcatalogoestatus) : "",
                //estados: listaestados(estados, tipovista, []),
            })
        });

    }
    return listamedios
}




function listaservicios(servicios, tipovista, medioservicios) {

    let listaservicios = [];
    if (tipovista == 1) {
        servicios.forEach(elemento => {
            listaservicios.push(
                {
                    actualizado: "",
                    creado: "",
                    estatus: "",
                    idcatalogoestatus: "",
                    idcatalogoservicios: elemento.idcatalogoservicios,
                    idcuentacanalarchivosservicio: "",
                    servicio: elemento.servicio,
                    usuarioactualizacion: "",
                    usuariocreacion: "",
                }
            )
        });
    }
    else {
        servicios.forEach(elemento => {

            let encontrado = null;
            if (medioservicios.length > 0) {
                encontrado = medioservicios.find(x => x.idcatalogoservicios == elemento.idcatalogoservicios);
            }


            listaservicios.push(
                {
                    actualizar: "0",
                    actualizado: (encontrado) ? (encontrado.actualizado) : "",
                    creado: (encontrado) ? (encontrado.creado) : "",
                    estatus: (encontrado) ? (encontrado.estatus) : "",
                    idcatalogoestatus: (encontrado) ? (encontrado.idcatalogoestatus) : "",
                    idcatalogoservicios: elemento.idcatalogoservicios,
                    idcuentacanalarchivosservicio: (encontrado) ? (encontrado.idcuentacanalarchivosservicio) : "",
                    servicio: elemento.servicio,
                    usuarioactualizacion: (encontrado) ? (encontrado.usuarioactualizacion) : "",
                    usuariocreacion: (encontrado) ? (encontrado.usuariocreacion) : "",
                }
            )

        });

    }

    return listaservicios
}



/*

function listaestados(estados, tipovista, medioservicios) {

    let listaestados = [];
    if (tipovista == 1) {
        estados.forEach(elemento => {
            listaestados.push(
                {
                    actualizado: "",
                    creado: "",
                    estatus: "",
                    idcatalogoestatus: "",
                    idcatalogoestadodecuenta: elemento.idcatalogoestadodecuenta,
                    idcuentacanalarchivosestadodecuenta: "",
                    tipo: elemento.tipo,
                    usuarioactualizacion: "",
                    usuariocreacion: "",
                }
            )
        });
    }
    else {
        estados.forEach(elemento => {

            let encontrado = null;
            if (medioservicios.length > 0) {
                encontrado = medioservicios.find(x => x.idcatalogoestadodecuenta == elemento.idcatalogoestadodecuenta);
            }

            listaestados.push(
                {
                    actualizado: (encontrado) ? (encontrado.actualizado) : "",
                    creado: (encontrado) ? (encontrado.creado) : "",
                    estatus: (encontrado) ? (encontrado.estatus) : "",
                    idcatalogoestatus: (encontrado) ? (encontrado.idcatalogoestatus) : "",
                    idcatalogoestadodecuenta: elemento.idcatalogoestadodecuenta,
                    idcuentacanalarchivosestadodecuenta: (encontrado) ? (encontrado.idcuentacanalarchivosestadodecuenta) : "",
                    tipo: elemento.tipo,
                    usuarioactualizacion: (encontrado) ? (encontrado.usuarioactualizacion) : "",
                    usuariocreacion: (encontrado) ? (encontrado.usuariocreacion) : "",
                }
            )

        });

    }

    return listaservicios
}



*/



















