import React from "react"
import { Dropdown, Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Label, FormFeedback, FormGroup } from 'reactstrap';
import Inputform from "../Inputform/Inputform";
import { BsCheckLg } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import { GoX } from 'react-icons/go';
import { catalogo_servicios, catalogo_situaciones } from "../../Services/Api";
import AreaMensajes from "./AreaMensajes";

export default class ModalParametrosDeSistema extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            show: this.props.show,
            inputsform: this.props.elementosinput,
            colapsar: false,
            colapsarservicio: false,
            listasituacion: [],
            listaservicios: [],
            iteminfo: JSON.parse(JSON.stringify(this.iteminfo())),

            confirmarenvio: false,
            botonguardarinactivo: true,
            mostrarbotonesformulario: false
        }
    }

    iteminfo = () => {
        let x = []
        x.push(this.props.itemactivo)
        return x[0]
    }


    async componentDidMount() {

        //obtener lista de situaciones
        const listasituaciones = await catalogo_situaciones();
        if (listasituaciones.code == 0) {
            let listaitems = [];
            listaitems = listasituaciones.data.items;
            this.setState({ listasituacion: listaitems });
            if (listasituaciones.data.items.length > 0) {
                this.setState({ mostrarbotonesformulario: true });
                this.props.avisolistas({ validar: true, opcion: 0 });
            }
        }
        else {
            this.setState({ listasituacion: [] })
            this.setState({ mostrarbotonesformulario: false });
            if (listasituaciones.code == 0)
                this.props.avisolistas({ validar: false, opcion: 0 });
            else if (listasituaciones.code == 1)
                this.props.avisolistas({ validar: false, opcion: 1 });
            else
                this.props.avisolistas({ validar: false, opcion: 2 });
        }
    }

    guardarvalor = (item) => {
        let listainputs = this.state.inputsform
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;
        });
        this.setState({ inputsform: listainputs })
    }

    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }

    verestatus = () => {
        this.setState({ colapsar: !this.state.colapsar })
    }
    verservicio = () => {
        this.setState({ colapsarservicio: !this.state.colapsarservicio })
    }

    cambiarestado = (nuevoestado) => {
        let item = this.state.iteminfo;
        item.estatus = nuevoestado.estatus

        let inputsform = this.state.inputsform;

        inputsform[11].valor = nuevoestado.estatus;
        inputsform[11].contenido = nuevoestado.idcatalogoestatus;

        this.setState({ iteminfo: item, inputsform: inputsform })
    }

    async guardarinfo() {


        let nuevovalor = await this.props.respuestaformulario(this.state.inputsform); // enviar lista
        this.setState({ inputsform: nuevovalor.nuevoarreglo });// respuesta validada

        if (nuevovalor.aprobado) {
            setTimeout(() => {
                this.props.finalizaraccion(nuevovalor.nuevoarreglo);// si aprobado entonces cerrar modal
                this.setState({ show: false })
            }, 200);
        }
    }





    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    // toggle={this.showmodal}
                    className="modal-catalogo">
                    <ModalBody>
                        <div>

                            {(this.props.tipoaccion == 3) ? ( //mostrar vista de contenido completo
                                <div>
                                    <p className="modal-titulo">{this.props.modaltitulo}</p>
                                    {this.props.itemtitulos.map((item, index) => (
                                        <>
                                            {
                                                (this.props.itemver[index] == "") ? (null)
                                                    :
                                                    <>
                                                        <div className={(index < (this.props.itemtitulos.length - 4)) ? ("modal-catalogo-vista-tabla") : "modal-catalogo-vista-tabla-secundario"}
                                                            style={(index < (this.props.itemtitulos.length - 4)) ? ({ background: "#e9ecef" }) : null}>
                                                            <label>{item}: </label>
                                                            <div style={{ wordWrap: 'break-word' }}>{this.props.itemver[index]}</div>
                                                        </div>
                                                    </>

                                            }
                                        </>
                                    ))}
                                </div>
                            ) :


                                //mostrar formulario de creaci[on y edici[on]]
                                <>
                                    <p className="modal-titulo">{this.props.modaltitulo}</p>

                                    {this.state.inputsform.map((item, index) => (
                                        <>
                                            {(item.tipoinput == "vista") ? (
                                                <>
                                                    <div className={(index < (this.props.itemtitulos.length - 4)) ? ("modal-catalogo-vista-tabla") : "modal-catalogo-vista-tabla-secundario"}
                                                        style={(index < (this.props.itemtitulos.length - 4)) ? ({ background: "#e9ecef" }) : null}>
                                                        <label>{item.tituloinput}</label>
                                                        <div>{item.valor}</div>
                                                    </div>
                                                </>
                                            ) : null}

                                            {(item.tipoinput == "text" || item.tipoinput == "textarea") ? (
                                                <>
                                                    <Inputform datosinput={item} valoringresado={(e) => this.guardarvalor(e)} />
                                                    <br />
                                                </>
                                            ) : null}

                                            {(item.tipoinput == "listaestados") ? (
                                                <>
                                                    {(this.state.listasituacion.length > 0) ? (
                                                        <>
                                                            <Label className="labelform-theme" >
                                                                Situación:
                                                            </Label>
                                                            <div>
                                                                <Dropdown isOpen={this.state.colapsar} toggle={() => this.verestatus()} >
                                                                    <DropdownToggle tag="span" className="navbar-web-menuitem-button" caret>
                                                                        <Button className={(this.state.iteminfo.estatus == "") ? ("blackoutlinebutton") : "blueoutlinebutton"} onClick={() => this.verestatus()}>
                                                                            {(this.state.iteminfo.estatus != "") ? (this.state.iteminfo.estatus) : "Selecciona una situación"}
                                                                            <AiFillCaretDown />
                                                                        </Button>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu style={{ width: "100%", background: "", maxHeight: "300px", overflowY: "scroll" }}>
                                                                        {this.state.listasituacion.map(estado => (
                                                                            <><DropdownItem onClick={() => this.cambiarestado(estado)}>{estado.estatus} </DropdownItem>
                                                                                <DropdownItem divider />
                                                                            </>

                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                            <br />
                                                        </>
                                                    ) :
                                                        <>
                                                            <Label className="labelform-theme" >
                                                                Situación:
                                                            </Label>
                                                            <div className="modal-catalogo-listavacia">
                                                                Necesario crear situaciones
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            ) : null}

                                        </>
                                    ))}
                                    <br />


                                    {(this.props.showmensaje) ? (
                                        <AreaMensajes
                                            showmensaje={this.props.showmensaje}
                                            titulo={this.props.titulo}
                                            mensaje={this.props.mensaje} />
                                    ) : null}


                                    <div className="modal-catalogo-checkinput" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                        <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar envío de formulario
                                    </div>

                                </>
                            }

                            {
                                (this.props.tipoaccion == 1 || this.props.tipoaccion == 2) ? (
                                    <>
                                        {(this.state.mostrarbotonesformulario) ? (
                                            <div className="modal-catalogos-buttons">
                                                <Button className="cancelbutton" onClick={() => this.showmodal()}><GoX /> Cancelar</Button>
                                                <Button disabled={!this.state.confirmarenvio} className="bluebutton" onClick={() => this.guardarinfo()}> <BsCheckLg /> Guardar</Button>
                                            </div>

                                        ) :
                                            <div className="modal-catalogos-buttons-ver">
                                                <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> Cerrar</Button>
                                            </div>

                                        }

                                    </>

                                ) :
                                    <div className="modal-catalogos-buttons-ver">
                                        <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> Cerrar</Button>
                                    </div>
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

