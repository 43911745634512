import axios from "axios";
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { host } from './../Configuracion'

var CryptoJS = require("crypto-js");
var ruta_api = host;
var apigeneral = "/h2h-api/web/";
var x_accesskey = "1081592e5bcf4d20a3233e044a0171f4";
var SecretKey = "116971e670ad4dc6821dd3ea75d7f671";


export async function consumir_api(apis, json, bandera) {
    var dia_ac = new Date(),
        month = '' + (dia_ac.getMonth() + 1),
        day = '' + dia_ac.getDate(),
        year = dia_ac.getFullYear(),

        h = '' + dia_ac.getHours(),
        m = '' + dia_ac.getMinutes(),
        ss = '' + dia_ac.getSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    if (h.length < 2) {
        h = '0' + h;
    }
    if (m.length < 2) {
        m = '0' + m;
    }
    if (ss.length < 2) {
        ss = '0' + ss;
    }

    let dia_actual = [year, month, day].join('-');
    let hora_actual = [h, m, ss].join(':');
    var fecha = dia_actual + ' ' + hora_actual;
    //console.log(fecha + x_accesskey + apis);

    var token = Base64.stringify(hmacSHA512(fecha + x_accesskey + apis, SecretKey));
    // console.log("Bearer " + token);

    try {

        var headerstype = 'application/json';
        if (bandera == 'formdata') {
            headerstype = 'multipart/form-data'
        }

        var consulta = await axios.post(ruta_api + apis, json, {
            headers: {
                "content-type": headerstype,
                "x-accessKey": x_accesskey,
                "x-date": fecha,
                "x-signature": "Bearer " + token,
            },
        });
        var resp = consulta.data;
        return resp;
    } catch (error) {
        return -1;
    }
}


////////// Archivos
export async function GoUPFile(formdata, bandera) {

    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "demo/test-servicio", formdata, bandera);
        // console.log(JSON.stringify(datos));
        resp = datos;
    } catch (error) {
        //console.log(error)
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}









export async function login(usuario, password) {
    //console.log("login");
    let jsonfile = { "usuario": usuario, "password": password };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "login/apikey", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////

export async function Autorizarfamilia(idfamilia) {
    //console.log("login");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1070", "idcatalogofamilias": idfamilia };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/autorizar-llave", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


////////// catálogos

export async function catalogo_situaciones() {
    //console.log("catalogo_situaciones");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1000" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/situaciones", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevasituacion(clave, estatus) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1000", "clave": clave, "estatus": estatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/situaciones", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editarsituacion(clave, estatus, idcatalogoestatus) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1000", "clave": clave, "estatus": estatus, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/situaciones", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function catalogo_envioresultados() {
    //console.log("catalogo_envioresultados");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1010" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/envio-resultados", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevoenvioresultados(descripcion, idcatalogoestatus) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1010", "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/envio-resultados", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editarenvioresultados(descripcion, idcatalogoestatus, idcatalogoenviarresultados) {
    //console.log("catalogo_envioresultados");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        token: token,
        modulo: "1010",
        descripcion: descripcion,
        idcatalogoestatus: idcatalogoestatus,
        idcatalogoenviarresultados: idcatalogoenviarresultados
    }

    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/envio-resultados", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function catalogo_servicios() {
    //console.log("catalogo_servicios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1020" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/servicios", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevoservicio(servicio, carpetaentrada, carpetasalida, tiempodeespera, idcatalogoestatus) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1020", "servicio": servicio, "carpetaentrada": carpetaentrada, "carpetasalida": carpetasalida, "tiempodeespera": tiempodeespera, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/servicios", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editarservicio(servicio, carpetaentrada, carpetasalida, tiempodeespera, idcatalogoestatus, idcatalogoservicios) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token,
        "modulo": "1020",
        "servicio": servicio,
        "carpetaentrada": carpetaentrada,
        "carpetasalida": carpetasalida,
        "tiempodeespera": tiempodeespera,
        "idcatalogoestatus": idcatalogoestatus,
        "idcatalogoservicios": idcatalogoservicios
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/servicios", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}











export async function catalogo_monedas() {
    //console.log("catalogo_monedas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1030" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/monedas", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevamoneda(codigo, descripcion, idcatalogoestatus) {
    //console.log("catalogo_monedas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1030", "codigo": codigo, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/monedas", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function catalogo_editarmoneda(codigo, descripcion, idcatalogoestatus, idcatalogomonedas) {
    //console.log("catalogo_monedas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1030", "codigo": codigo, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus, "idcatalogomonedas": idcatalogomonedas };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/monedas", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}










export async function catalogo_errores() {
    //console.log("catalogo_errores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1040" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/errores", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevoerrores(codigo, descripcion, idcatalogoestatus) {
    //console.log("catalogo_errores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1040", "codigo": codigo, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/errores", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editarerror(codigo, descripcion, idcatalogoestatus, idcatalogoerrores) {
    //console.log("catalogo_errores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1040", "codigo": codigo, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus, "idcatalogoerrores": idcatalogoerrores };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/errores", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}






export async function catalogo_mediosServicios() {
    //console.log("catalogo_mediosServicios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1050" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/canal-archivos", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevomediosServicios(canal, descripcion, idcatalogoestatus) {
    //console.log("catalogo_mediosServicios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1050", "canal": canal, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/canal-archivos", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function catalogo_editarmediosServicios(canal, descripcion, idcatalogoestatus, idcatalogocanalarchivos) {
    //console.log("catalogo_mediosServicios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1050", "canal": canal, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus, "idcatalogocanalarchivos": idcatalogocanalarchivos };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/canal-archivos", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}









export async function catalogo_asesores() {
    //console.log("catalogo_asesores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1060" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/asesores", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevoasesores(nombre, correo, idcatalogoestatus, idcatalogoservicios) {
    //console.log("catalogo_asesores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1060", "nombre": nombre, "correo": correo, "idcatalogoestatus": idcatalogoestatus, "idcatalogoservicios": idcatalogoservicios };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/asesores", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editarasesores(nombre, correo, idcatalogoestatus, idcatalogoservicios, idcatalogoasesores) {
    //console.log("catalogo_asesores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1060", "nombre": nombre, "correo": correo, "idcatalogoestatus": idcatalogoestatus, "idcatalogoservicios": idcatalogoservicios, "idcatalogoasesores": idcatalogoasesores };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/asesores", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}










export async function catalogo_familia() {
    //console.log("catalogo_familia");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1070" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/familias", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevafamilia(familia, idcatalogoestatus, cliente) {
    //console.log("catalogo_familia");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1070", "familia": familia, "idcatalogoestatus": idcatalogoestatus, "cliente": cliente };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/familias", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editarfamilia(familia, idcatalogoestatus, cliente, idcatalogofamilias) {
    //console.log("catalogo_familia");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1070", "familia": familia, "idcatalogoestatus": idcatalogoestatus, "cliente": cliente, "idcatalogofamilias": idcatalogofamilias };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/familias", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}






export async function catalogo_tipoOperaciones() {
    //console.log("catalogo_tipoOperaciones");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1080" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/tipo-operaciones", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevotipoOperaciones(folio, descripcion, tipo, codigo, idcatalogoestatus) {
    //console.log("catalogo_tipoOperaciones");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1080", "folio": folio, "descripcion": descripcion, "tipo": tipo, "codigo": codigo, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/tipo-operaciones", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_editartipoOperaciones(folio, descripcion, tipo, codigo, idcatalogoestatus, idcatalogotipooperacion) {
    //console.log("catalogo_tipoOperaciones");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1080", "folio": folio, "descripcion": descripcion, "tipo": tipo, "codigo": codigo, "idcatalogoestatus": idcatalogoestatus, "idcatalogotipooperacion": idcatalogotipooperacion };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/tipo-operaciones", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}











export async function catalogo_estados() { /// Estado de cuenta <------> Cambiar api //////
    //console.log("catalogo_estados");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1090" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/estado-de-cuenta", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_nuevoestadodecuenta(tipo, descripcion, idcatalogoestatus) {/// Estado de cuenta <------> Cambiar api //////
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1090", "tipo": tipo, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/estado-de-cuenta", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp
}

export async function catalogo_editarestadodecuenta(tipo, descripcion, idcatalogoestatus, idcatalogoestadodecuenta) {/// Estado de cuenta <------> Cambiar api //////
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "1090", "tipo": tipo, "descripcion": descripcion, "idcatalogoestatus": idcatalogoestatus,
        "idcatalogoestadodecuenta": idcatalogoestadodecuenta
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/estado-de-cuenta", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }


    return resp
}
export async function nuevo_actualizar_cuenta(parametro, valor) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "1090",
        "parametro": parametro,
        "valor": valor
    };
    var resp = [];
    try {

        var datos = await consumir_api(apigeneral + "estado-cuenta/actualizar-configuracion", jsonfile);
        //console.log(JSON.stringify(datos))
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp
}
export async function configuracion_estadodecuenta() {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "10000"
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "estado-cuenta/configuracion", jsonfile);
        //console.log(JSON.stringify(datos))
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp
}

export async function configuracion_estadodecuenta_editar(horainiciomt940, horafinmt940, horainiciomt942, horafinmt942, vigenciadiasarchivos, vigenciadiasarchivoscliente, rutahome, rutacertificadocliente, rutarepositoriocertificados, rutallavepublicaintercam,
    rutallaveprivadaintercam, idcatalogoestatus, idconfigestadocuenta) {
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "1090",
        "horainiciomt940": horainiciomt940, "horafinmt940": horafinmt940, "horainiciomt942": horainiciomt942,
        "horafinmt942": horafinmt942, "vigenciadiasarchivos": vigenciadiasarchivos,
        "vigenciadiasarchivoscliente": vigenciadiasarchivoscliente, "rutahome": rutahome,
        "rutacertificadocliente": rutacertificadocliente, "rutarepositoriocertificados": rutarepositoriocertificados,
        "rutallavepublicaintercam": rutallavepublicaintercam,
        "rutallaveprivadaintercam": rutallaveprivadaintercam,
        "idcatalogoestatus": idcatalogoestatus, "idconfigestadocuenta": idconfigestadocuenta
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "estado-cuenta/actualizar-configuracion", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp
}































////////// Administraci[on]

export async function administracion_cuentas() {
    //console.log("administracion_cuentas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "3000" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "cuentas/lista", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function administracion_cuentadetalles(idcuentas) {
    //console.log("administracion_cuentas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "3000", "idcuentas": idcuentas };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "cuentas/cuenta-detalles", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function administracion_nuevacuenta(json) {
    //console.log("administracion_cuentas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = json;
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "cuentas/nueva", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function administracion_editarcuenta(json) {
    //console.log("administracion_cuentas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = json;
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "cuentas/actualizar", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getcomboarchivos() {

    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "catalogo-general/archivos-salida-mt", resp);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}






export async function administracion_cuentasAsociadas() {
    //console.log("administracion_cuentas");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "3010" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "estado-cuenta/cuentas", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}




export async function Reporte_consulta_archivos_individual(idcuentassolicitudesservicios) {
    //console.log("Reporte_archivos");
    var token = sessionStorage.getItem("ls").split(",")[3];

    let jsonfile = {
        "token": token, "modulo": "3000", "idcuentassolicitudesservicios": idcuentassolicitudesservicios
    };

    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "reportes/archivos-procesados-detalle", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}




export async function Reporte_consulta_archivos(fechainicio, fechafin, idcuentas, idcatalogoservicios, idcatalogofamilias) {
    //console.log("Reporte_archivos");
    var token = sessionStorage.getItem("ls").split(",")[3];

    let jsonfile = {
        "token": token, "modulo": "4000", "idcuentas": idcuentas, "idcatalogofamilias": idcatalogofamilias,
        "idcatalogoservicios": idcatalogoservicios, "fechainicio": fechainicio, "fechafin": fechafin
    };



    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "reportes/archivos-procesados", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function Reporte_consulta_archivos_detallado(fechainicio, fechafin, idcuentas, idcatalogoservicios, idcatalogofamilias) {
    //console.log("Reporte_archivos");
    var token = sessionStorage.getItem("ls").split(",")[3];

    let jsonfile = {
        "token": token, "modulo": "4000", "idcuentas": idcuentas, "idcatalogofamilias": idcatalogofamilias,
        "idcatalogoservicios": idcatalogoservicios, "fechainicio": fechainicio, "fechafin": fechafin
    };



    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "reportes/archivos-procesados-detalle-exportar", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function Reporte_consulta_clientes(idcuentas, idcatalogoservicios, idfamilia, idcatalogocanalarchivos) {
    //console.log("Reporte_consulta_clientes");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "4000", "idcuentas": idcuentas, "idcatalogoservicios": idcatalogoservicios, "idcatalogofamilias": idfamilia, "idcatalogocanalarchivos": idcatalogocanalarchivos
    };


    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "reportes/cuenta-servicios-asignados", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function administracion_bitacora_exportar(idcuentas, fechainicio, fechafin) {
    //console.log("administracion_bitacora");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "4000", "idcuentas": idcuentas, "fechainicio": fechainicio, "fechafin": fechafin };
    var resp = [];
    try {

        var datos = await consumir_api(apigeneral + "bitacora/exportar", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function obtener_volumen_detalles_general(idfamilia, idcliente, idservicio, fechainicio, fechafin, legado) {
    //console.log("administracion_bitacora");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {

        "token": token, "modulo": "1000",
        "idcatalogofamilias": idfamilia,
        "idcuentas": idcliente,
        "idcatalogoservicios": idservicio,
        "fechainicio": fechainicio,
        "fechafin": fechafin,
        "tipo": (legado == 0) ? "Todos" : (legado == 1) ? "Correctos" : "Incorrectos"

    };
    var resp = [];
    try {

        var datos = await consumir_api(apigeneral + "reportes/volumen-operado-detalles-exportar", jsonfile);







        var resptem = datos;
        var array_datos = [];
        var array_datos_divisa = [];
        var array_datosr = [];


        var array_datos_detalles = [];
        var array_datos_divisa_detalles = [];
        var array_datosr_detalles = [];

        var detalles = [];

        var temarray = resptem.data.items;
        if (temarray.length > 0) {
            var suma1 = 0;
            var suma2 = 0;
            var suma3 = 0;

            var suma4 = 0;
            var suma5 = 0;
            var suma6 = 0;

            //console.log(JSON.stringify(temarray[0]))

            let aux = temarray[0].divisa;
            for (let i = 0; i < temarray.length; i++) {


                /////////////////////////////////////////////////////////////////

                array_datosr_detalles = [];
                array_datos_detalles = [];
                var arraydetalles = temarray[i].detalles;
                if (arraydetalles.length > 0) {
                    suma4 = 0;
                    suma5 = 0;
                    suma6 = 0;

                    let auxdetalles = arraydetalles[0].divisa;
                    for (let j = 0; j < arraydetalles.length; j++) {

                        array_datos_detalles = {
                            "idcuentassolicitudesservicios": arraydetalles[j].idcuentassolicitudesservicios,
                            "idcuentassolicitudesserviciosvalidados": arraydetalles[j].idcuentassolicitudesserviciosvalidados,
                            "fila": arraydetalles[j].fila,
                            "archivo": arraydetalles[j].archivo,
                            "fechasolicitudlegado": arraydetalles[j].fechasolicitudlegado,
                            "divisa": arraydetalles[j].divisa,
                            "importe": arraydetalles[j].importe,
                            "operado": arraydetalles[j].operado,
                            "nooperado": arraydetalles[j].nooperado,
                            "codigodescripcion": arraydetalles[j].codigodescripcion,
                            "concepto": arraydetalles[j].concepto,
                            "totales": false
                        }


                        ////////////////////////////////////////////////
                        if (auxdetalles == arraydetalles[j].divisa) {

                            suma4 += parseFloat(arraydetalles[j].importe);
                            suma5 += parseFloat(arraydetalles[j].operado);
                            suma6 += parseFloat(arraydetalles[j].nooperado);

                        } else {

                            array_datos_divisa_detalles = { // seguimos cambiando el nombre
                                "importe": suma4.toFixed(2),
                                "operado": suma5.toFixed(2),
                                "nooperado": suma6.toFixed(2),
                                "totales": true
                            }
                            array_datosr_detalles.push(array_datos_divisa_detalles);

                            suma4 = parseFloat(arraydetalles[j].importe);
                            suma5 = parseFloat(arraydetalles[j].operado);
                            suma6 = parseFloat(arraydetalles[j].nooperado);
                            auxdetalles = arraydetalles[j].divisa;

                        }
                        array_datosr_detalles.push(array_datos_detalles);

                    }


                    array_datos_divisa_detalles = {
                        "importe": suma4.toFixed(2),
                        "operado": suma5.toFixed(2),
                        "nooperado": suma6.toFixed(2),
                        "totales": true
                    }

                    array_datosr_detalles.push(array_datos_divisa_detalles);
                    //console.log(JSON.stringify(array_datosr_detalles));
                    detalles = array_datosr_detalles;

                }

                /////////////////////////////////////////////////////////////////





                array_datos = {
                    "idcatalogocanalarchivos": temarray[i].idcatalogocanalarchivos,
                    "canal": temarray[i].canal,
                    "idcatalogoservicios": temarray[i].idcatalogoservicios,
                    "servicio": temarray[i].servicio,
                    "idcatalogofamilias": temarray[i].idcatalogofamilias,
                    "familia": temarray[i].familia,
                    "idcuentas": temarray[i].idcuentas,
                    "cliente": temarray[i].cliente,
                    "cuenta": temarray[i].cuenta,
                    "divisa": temarray[i].divisa,
                    "importe": temarray[i].importe,
                    "operado": temarray[i].operado,
                    "nooperado": temarray[i].nooperado,
                    "respuestalegadocodigo": temarray[i].respuestalegadocodigo,
                    "totales": false,
                    "detalles": detalles

                }


                ////////////////////////////////////////////////
                if (aux == temarray[i].divisa) {
                    suma1 += parseFloat(temarray[i].importe);
                    suma2 += parseFloat(temarray[i].operado);
                    suma3 += parseFloat(temarray[i].nooperado);

                } else {

                    array_datos_divisa = {
                        "importe": suma1.toFixed(2),
                        "operado": suma2.toFixed(2),
                        "nooperado": suma3.toFixed(2),
                        "totales": true
                    }
                    array_datosr.push(array_datos_divisa);

                    suma1 = parseFloat(temarray[i].importe);
                    suma2 = parseFloat(temarray[i].operado);
                    suma3 = parseFloat(temarray[i].nooperado);
                    aux = temarray[i].divisa;

                }
                array_datosr.push(array_datos);

            }


            array_datos_divisa = {
                "importe": suma1.toFixed(2),
                "operado": suma2.toFixed(2),
                "nooperado": suma3.toFixed(2),
                "totales": true
            }

            array_datosr.push(array_datos_divisa);
            /*    //console.log(JSON.stringify(array_datosr));
            for (let i = 0; i < array_datosr.length; i++) {
                array_datosr[i].importe = array_datosr[i].importe.toString().replace("\.", ",");
                array_datosr[i].operado = array_datosr[i].operado.toString().replace("\.", ",");
                array_datosr[i].nooperado = array_datosr[i].nooperado.toString().replace("\.", ",");
            }
            */
            // console.log(JSON.stringify(array_datosr));
            resptem.data.items = array_datosr;

        }

        resp = resptem;


    } catch (error) {
        //console.log(error);
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function obtener_volumen_detalles(idcatalogocanalarchivos, idcliente, idservicio, fechainicio, fechafin, divisa) {
    //console.log("administracion_bitacora");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {

        "token": token, "modulo": "1000",
        "idcatalogocanalarchivos": idcatalogocanalarchivos,
        "idcuentas": idcliente,
        "idcatalogoservicios": idservicio,
        "fechainicio": fechainicio, "fechafin": fechafin,
        "divisa": divisa
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "reportes/volumen-operado-detalles", jsonfile);

        var resptem = datos;


        var array_datos = [];
        var array_datos_divisa = [];
        var array_datosr = [];


        var temarray = resptem.data.items;
        if (temarray.length > 0) {
            var suma1 = 0;
            var suma2 = 0;
            var suma3 = 0;

            let aux = temarray[0].divisa;
            for (let i = 0; i < temarray.length; i++) {

                array_datos = {
                    "idcuentassolicitudesservicios": temarray[i].idcuentassolicitudesservicios,
                    "idcuentassolicitudesserviciosvalidados": temarray[i].idcuentassolicitudesserviciosvalidados,
                    "fila": temarray[i].fila,
                    "archivo": temarray[i].archivo,
                    "fechasolicitudlegado": temarray[i].fechasolicitudlegado,
                    "divisa": temarray[i].divisa,
                    "importe": temarray[i].importe,
                    "operado": temarray[i].operado,
                    "nooperado": temarray[i].nooperado,
                    "codigodescripcion": temarray[i].codigodescripcion,
                    "concepto": temarray[i].concepto,
                    "totales": false
                }


                ////////////////////////////////////////////////
                if (aux == temarray[i].divisa) {
                    suma1 += parseFloat(temarray[i].importe);
                    suma2 += parseFloat(temarray[i].operado);
                    suma3 += parseFloat(temarray[i].nooperado);

                } else {

                    array_datos_divisa = {
                        "importe": suma1.toFixed(2),
                        "operado": suma2.toFixed(2),
                        "nooperado": suma3.toFixed(2),
                        "totales": true
                    }
                    array_datosr.push(array_datos_divisa);

                    suma1 = parseFloat(temarray[i].importe);
                    suma2 = parseFloat(temarray[i].operado);
                    suma3 = parseFloat(temarray[i].nooperado);
                    aux = temarray[i].divisa;

                }
                array_datosr.push(array_datos);

            }


            array_datos_divisa = {
                "importe": suma1.toFixed(2),
                "operado": suma2.toFixed(2),
                "nooperado": suma3.toFixed(2),
                "totales": true
            }

            array_datosr.push(array_datos_divisa);
            //console.log(JSON.stringify(array_datosr));
            /*for (let i = 0; i < array_datosr.length; i++) {
                array_datosr[i].importe = array_datosr[i].importe.toString().replace("\.", ",");
                array_datosr[i].operado = array_datosr[i].operado.toString().replace("\.", ",");
                array_datosr[i].nooperado = array_datosr[i].nooperado.toString().replace("\.", ",");
            }
            */

            resptem.data.items = array_datosr;
        }

        //  console.log(JSON.stringify(resptem));
        resp = resptem;


    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function obtener_volumen(idfamilia, idcliente, idservicio, fechainicio, fechafin, legado) {
    //console.log("administracion_bitacora");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "1000",
        "idcatalogofamilias": idfamilia,
        "idcuentas": idcliente,
        "idcatalogoservicios": idservicio,
        "fechainicio": fechainicio,
        "fechafin": fechafin,
        "tipo": (legado == 0) ? "Todos" : (legado == 1) ? "Correctos" : "Incorrectos"
    };

    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "reportes/volumen-operado", jsonfile);
        var resptem = datos;


        var array_datos = [];
        var array_datos_divisa = [];
        var array_datosr = [];


        var temarray = resptem.data.items;
        if (temarray.length > 0) {
            var suma1 = 0;
            var suma2 = 0;
            var suma3 = 0;


            let aux = temarray[0].divisa;
            for (let i = 0; i < temarray.length; i++) {

                array_datos = {
                    "idcatalogocanalarchivos": temarray[i].idcatalogocanalarchivos,
                    "canal": temarray[i].canal,
                    "idcatalogoservicios": temarray[i].idcatalogoservicios,
                    "servicio": temarray[i].servicio,
                    "idcatalogofamilias": temarray[i].idcatalogofamilias,
                    "familia": temarray[i].familia,
                    "idcuentas": temarray[i].idcuentas,
                    "cliente": temarray[i].cliente,
                    "cuenta": temarray[i].cuenta,
                    "divisa": temarray[i].divisa,
                    "importe": temarray[i].importe,
                    "operado": temarray[i].operado,
                    "nooperado": temarray[i].nooperado,
                    "respuestalegadocodigo": temarray[i].respuestalegadocodigo,
                    "totales": false,
                    "detalles": []
                }


                ////////////////////////////////////////////////
                if (aux == temarray[i].divisa) {
                    suma1 += parseFloat(temarray[i].importe);
                    suma2 += parseFloat(temarray[i].operado);
                    suma3 += parseFloat(temarray[i].nooperado);

                } else {

                    array_datos_divisa = {
                        "importe": suma1.toFixed(2),
                        "operado": suma2.toFixed(2),
                        "nooperado": suma3.toFixed(2),
                        "totales": true
                    }
                    array_datosr.push(array_datos_divisa);

                    suma1 = parseFloat(temarray[i].importe);
                    suma2 = parseFloat(temarray[i].operado);
                    suma3 = parseFloat(temarray[i].nooperado);
                    aux = temarray[i].divisa;

                }
                array_datosr.push(array_datos);

            }


            array_datos_divisa = {
                "importe": suma1.toFixed(2),
                "operado": suma2.toFixed(2),
                "nooperado": suma3.toFixed(2),
                "totales": true
            }

            array_datosr.push(array_datos_divisa);
            /*/   //console.log(JSON.stringify(array_datosr));
            for (let i = 0; i < array_datosr.length; i++) {
                array_datosr[i].importe = array_datosr[i].importe.toString().replace("\.", ",");
                array_datosr[i].operado = array_datosr[i].operado.toString().replace("\.", ",");
                array_datosr[i].nooperado = array_datosr[i].nooperado.toString().replace("\.", ",");
            }
 */
            //console.log(JSON.stringify(array_datosr));

            resptem.data.items = array_datosr;
        }

        resp = resptem;

    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}




export async function administracion_bitacora(idcuentas, fechainicio, fechafin) {
    //console.log("administracion_bitacora");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "4000", "idcuentas": idcuentas, "fechainicio": fechainicio, "fechafin": fechafin };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "bitacora/lista", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function administracion_bitacora_desglose(idcuentas) {
    //console.log("administracion_bitacora_errores");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "4000", "idcuentassolicitudesservicios": idcuentas };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "bitacora/consultar-solicitud", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}




//para ventana de cuentas asociadas
export async function actualizar_940(idcuentas, mt940, mt942) { /// -----------nuevo ----------
    var jsonfile = "";
    var token = sessionStorage.getItem("ls").split(",")[3];
    if (mt942 == 0) {
        jsonfile = { "token": token, "modulo": "1090", "idcuentas": idcuentas, "mt940": mt940 };
    }
    else {
        jsonfile = { "token": token, "modulo": "1090", "idcuentas": idcuentas, "mt940": mt940, "mt942": mt942 };
    }

    //let jsonfile = { "token": token, "modulo": "1090", "idcuentas": idcuentas, "mt940": mt940 };

    //console.log(JSON.stringify(jsonfile));

    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "estado-cuenta/cuenta-actualizar", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function verinfo(id) { /// -----------nuevo ----------

    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "1090", "idcuentas": id };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "estado-cuenta/cuenta-detalles", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

















































////////// Usuarios

export async function gestion_usuarios() {
    //console.log("gestion_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "2000" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/usuarios", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogo_tipos_usuarios() {
    //console.log("tipos_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "2000" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/tipo-usuario", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function gestion_nuevosuario(login, nombre, apellido, email, idusuariotipo, idestatus) {
    //console.log("nuevos_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "2000",
        "login": login, "nombre": nombre, "apellido": apellido, "email": email, "idusuariotipo": idusuariotipo, "idestatus": idestatus
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "nuevo-catalogo/usuarios", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function gestion_editarusuario(login, nombre, apellido, email, idusuariotipo, idestatus, idusuario) {
    //console.log("editar_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "2000",
        "login": login, "nombre": nombre, "apellido": apellido, "email": email, "idusuariotipo": idusuariotipo, "idestatus": idestatus, "idusuario": idusuario
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/usuarios", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function gestion_desactivarusuario(idusuario) {
    //console.log("desactivar_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "2000", "idusuario": idusuario
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/bloquear-usuario", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function gestion_activarusuario(idusuario) {
    //console.log("activar_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "2000", "idusuario": idusuario
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/activar-usuario", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function gestion_usuario_nuevopassword(idusuario) {
    //console.log("gestionusuario_nuevopass");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = {
        "token": token, "modulo": "2000", "idusuario": idusuario
    };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/usuario-generar-password", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



















////////// Usuarios??????

export async function info_perfil() {
    //console.log("editar_usuarios");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "2000" };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "lista-catalogo/usuario-logueado", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function cambiar_password(password, nuevopassword) {
    //console.log("cambiar_password");
    var token = sessionStorage.getItem("ls").split(",")[3];
    let jsonfile = { "token": token, "modulo": "2000", "nuevopassword": nuevopassword, "password": password };
    var resp = [];
    try {
        var datos = await consumir_api(apigeneral + "actualizar-catalogo/usuario-password", jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



















