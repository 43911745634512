import React from "react"
import { Button, Modal, ModalBody } from 'reactstrap';
import { BsCheckLg } from 'react-icons/bs';

import { GoX } from 'react-icons/go';
import { administracion_editarcuenta, administracion_nuevacuenta, catalogo_envioresultados, catalogo_estados, catalogo_familia, catalogo_mediosServicios, catalogo_servicios, catalogo_situaciones, getcomboarchivos } from "../../Services/Api";
import { vaidarcuentafolio, validarEmail, validarnombrecuenta, validarnombresms, validartelefono } from "../../Services/Validaciones";
import { listainputsFormularioSMS, ordenarlistainputsFormularioSMS } from "../Administracion/Complementos/CuentasOrdenar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormularioCuenta from "./ComplementosModalCuentas/FormularioCuenta";
import { crearplantilla } from "./ComplementosModalCuentas/GeneradorPlantilla";
import VistaTablerosCuenta from "./ComplementosModalCuentas/VistaTablerosCuenta";
import TableroEditableCuenta from "./ComplementosModalCuentas/TableroEditableCuenta";
import SeccionContactos from "./ComplementosModalCuentas/SeccionContactos";
import SpinnerModal from "./SpinnerModal/SpinnerModal";



export default class ModalCuentas extends React.Component {
    constructor(props) {
        super(props);
        // console.log("ModalCuentas", this.props.itemactivo);
        this.state = {
            show: this.props.show,
            inputs: this.props.elementosinput,
            cuenta: this.props.itemactivo,

            colapsar: false,
            colapsar2: false,
            colapsefamilia: false,
            colapsenotificaciones: false,
            listasituaciones: [],
            listafamilias: [],
            listaenvioresultados: [],

            headerservicios: ["ID", "Servicio", "Situación", "Creado por", "Creado", "Actualizado por", "Actualizado"],
            listaservicios: [],

            headermedios: ["ID", "Medio de servicio", "Situación", "Creado por", "Creado", "Actualizado por", "Actualizado"],
            listamedios: [],
            listamediosServicios: [],

            headerestados: ["ID", "Estado", "Situación", "Creado por", "Creado", "Actualizado por", "Actualizado"],
            listaestados: [],

            headersms: ["Folio", "Contacto", "Cargo", "Teléfono", "Situación", "Creado por", "Creado", "Actualizado por", "Actualizado"],

            inputsformulariosms: this.llenarlistainputsFormularioSMS(),
            formulariosmsactivo: true,
            tipoformulariosms: 1,
            contactoactivoedicion: null,

            loadingImage: false,
            confirmarenvio: false,
            idmt942: 1,
            idmt940: 1,
            idgeneracion: 0,
            arrayarchivos940: [],
            arrayarchivos942: [],
            arrayageneracion: [
                {
                    id: 0,
                    nombre: "Por rango"
                },
                {
                    id: 1,
                    nombre: "Por día"
                }]
        }

    }


    //cargar todos los catalogos
    async componentDidMount() {

        if (this.props.contenido.mt940pordia != undefined) {
            this.setState({ idgeneracion: this.props.contenido.mt940pordia });
        }
        if (this.props.contenido.idcatalogoarchivossalidamt940 != undefined) {
            this.setState({ idmt940: this.props.contenido.idcatalogoarchivossalidamt940 });
        }
        if (this.props.contenido.idcatalogoarchivossalidamt942 != undefined) {
            this.setState({ idmt942: this.props.contenido.idcatalogoarchivossalidamt942 });
        }

        this.setState({ loadingImage: true });

        let listasituaciones = await catalogo_situaciones();
        let listafamilias = await catalogo_familia();
        let listaresultados = await catalogo_envioresultados();
        const listaestados = await catalogo_estados();
        const listaservicios = await catalogo_servicios();
        const listamedios = await catalogo_mediosServicios();

        if (listaservicios.code == 0 && listamedios.code == 0 && listafamilias.code == 0 && listaresultados.code == 0 && listasituaciones.code == 0) {
            this.setState({
                listasituaciones: listasituaciones.data.items,
                listafamilias: listafamilias.data.items,
                listaenvioresultados: listaresultados.data.items,
                listaestados: listaestados.data.items
            })


            let plantilla = crearplantilla(listamedios, listaservicios, listaestados, this.state.cuenta, this.props.tipovista)

            //llenar a plantilla con la info de edición
            if (this.props.tipovista == 2) {
                let nuevoformulario = this.props.elementosinput;
                nuevoformulario[2].valor = this.props.itemactivo.correo;
                this.setState({ cuenta: plantilla, inputs: nuevoformulario, })
            }
            else if (this.props.tipovista == 1) {

                plantilla.idcatalogofamilias = listafamilias.data.items[0].idcatalogofamilias;
                plantilla.idcatalogoestatus = listaestados.data.items[0].idcatalogoestatus;
                plantilla.idcatalogoenviarresultados = listaresultados.data.items[0].idcatalogoenviarresultados;
                this.setState({ cuenta: plantilla })
            }
            else if (this.props.tipovista == 3) {
            }
        }

        let arrayarchivos = await getcomboarchivos();

        if (arrayarchivos.code == "0") {
            this.setState({ arrayarchivos940: JSON.parse(JSON.stringify(arrayarchivos.data)) })
            this.setState({ arrayarchivos942: JSON.parse(JSON.stringify(arrayarchivos.data)) })
        }
        this.setState({ loadingImage: false });

    }

    //ediciones de cuenta
    guardarvalor = (item) => {
        //console.log("guardarvalor ern modal");
        let cuenta = this.state.cuenta;
        let listainputs = this.state.inputs
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;
        });

        if (cuenta.idcuentas != "" && cuenta.idcuentas != "0") {
            cuenta.actualizar = "1"
        }

        this.setState({ inputs: listainputs, cuenta: cuenta })
    }

    cambiarestatuscuenta = (index) => {
        // console.log("cambiar estaus de cuenta");
        let nuevoitem = this.state.cuenta
        nuevoitem.estatus = this.state.listasituaciones[index].estatus;
        nuevoitem.idcatalogoestatus = this.state.listasituaciones[index].idcatalogoestatus;

        if (nuevoitem.idcuentas != "" && nuevoitem.idcuentas != "0") {
            nuevoitem.actualizar = "1"

        }
        this.setState({ cuenta: nuevoitem })
    }

    cambiargeneracion = (id) => {
        let cuenta = this.state.cuenta;
        if (cuenta.idcuentas != "" && cuenta.idcuentas != "0") {
            cuenta.actualizar = "1"
        }

        this.setState({ idgeneracion: id, cuenta: cuenta })
    }

    cambiarmt940 = (id) => {
        let cuenta = this.state.cuenta;
        if (cuenta.idcuentas != "" && cuenta.idcuentas != "0") {
            cuenta.actualizar = "1"
        }

        this.setState({ idmt940: id, cuenta: cuenta })
    }

    cambiarmt942 = (id) => {

        let cuenta = this.state.cuenta;
        if (cuenta.idcuentas != "" && cuenta.idcuentas != "0") {
            cuenta.actualizar = "1"
        }

        this.setState({ idmt942: id, cuenta: cuenta })
    }

    cambiarnotificaciones = (index) => {
        //  console.log("cambiar notificaciones");
        let nuevoitem = this.state.cuenta
        nuevoitem.notificaciones = this.state.listaenvioresultados[index].notificaciones;
        nuevoitem.idcatalogoenviarresultados = this.state.listaenvioresultados[index].idcatalogoenviarresultados;
        if (nuevoitem.idcuentas != "" && nuevoitem.idcuentas != "0") {
            nuevoitem.actualizar = "1"
        }
        this.setState({ cuenta: nuevoitem })
    }

    cambiarfamilia = (index) => {
        // console.log("cambiar familia");
        let nuevoitem = this.state.cuenta
        nuevoitem.familia = this.state.listafamilias[index].familia;
        nuevoitem.idcatalogofamilias = this.state.listafamilias[index].idcatalogofamilias;
        if (nuevoitem.idcuentas != "" && nuevoitem.idcuentas != "0") {
            nuevoitem.actualizar = "1"
        }
        this.setState({ cuenta: nuevoitem })
    }

    cambiarindicadorsms = () => {
        let cuenta = this.state.cuenta;
    //    console.log(cuenta.estatussms)
        cuenta.estatussms = (cuenta.estatussms == 1) ? (0) : 1
        this.setState({ cuenta: cuenta })
        if (cuenta.idcuentas != "" && cuenta.idcuentas != "0") {
            cuenta.actualizar = "1"
        }
        this.setState({ cuenta: cuenta })
    }




    //ediciones de tableros

    activarmedio = (medio) => {
        //idcatalogocanalarchivos
        let nuevalista = this.state.cuenta;
        nuevalista.medio.forEach(medioitem => {
            if (medioitem.idcatalogocanalarchivos == medio.idcatalogocanalarchivos) {
                medioitem.idcatalogoestatus = this.state.listasituaciones[0].idcatalogoestatus;
                medioitem.estatus = this.state.listasituaciones[0].estatus;
            }
        });

        //   console.log("activarmedio()", nuevalista.medio)
        this.setState({ cuenta: nuevalista })
    }

    desactivarmedio = (medio) => {
        //idcatalogocanalarchivos
        let nuevalista = this.state.cuenta;
        nuevalista.medio.forEach(medioitem => {
            if (medioitem.idcatalogocanalarchivos == medio.idcatalogocanalarchivos) {

                medioitem.idcatalogoestatus = "";
                medioitem.estatus = "";

                medioitem.servicios.forEach(servicio => {
                    servicio.estatus = "";
                    servicio.idcatalogoestatus = "";
                });
            }
        });

        //  console.log("desactivarmedio()", nuevalista.medio)
        this.setState({ cuenta: nuevalista })
    }

    cambiarestatusmedio = (valores) => {
        let nuevalista = this.state.cuenta;
        nuevalista.medio.forEach(medioitem => {
            if (medioitem.idcatalogocanalarchivos == valores.medioid) {
                medioitem.estatus = this.state.listasituaciones[valores.indexestatus].estatus;
                medioitem.idcatalogoestatus = this.state.listasituaciones[valores.indexestatus].idcatalogoestatus;

                if (medioitem.idcuentacanalarchivos != "") {
                    medioitem.actualizar = "1"
                }

                if (this.state.listasituaciones[valores.indexestatus].idcatalogoestatus == 2) {
                    medioitem.servicios.forEach(servicio => {

                        if (servicio.idcatalogoestatus == 1) {
                            servicio.estatus = this.state.listasituaciones[valores.indexestatus].estatus
                            servicio.idcatalogoestatus = this.state.listasituaciones[valores.indexestatus].idcatalogoestatus

                            if (servicio.idcuentacanalarchivosservicio != "") {
                                servicio.actualizar = "1"
                            }

                        }
                    });
                }
            }
        });

        //  console.log("cambiarestatusmedio()", nuevalista.medio)
        this.setState({ cuenta: nuevalista })
    }

    activarservicio = (valores) => {
        // console.log("activarservicio ", valores)
        //idcatalogocanalarchivos
        let nuevalista = this.state.cuenta;
        nuevalista.medio.forEach(md => {
            if (md.idcatalogocanalarchivos == valores.medio.idcatalogocanalarchivos) {
                md.servicios.forEach(servicioitem => {
                    if (servicioitem.idcatalogoservicios == valores.servicio.idcatalogoservicios) {
                        servicioitem.idcatalogoestatus = this.state.listasituaciones[0].idcatalogoestatus;
                        servicioitem.estatus = this.state.listasituaciones[0].estatus;
                    }
                })
            }
        });

        // console.log(nuevalista)
        this.setState({ cuenta: nuevalista })
    }

    desactivarservicio = (valores) => {
        //idcatalogocanalarchivos
        // console.log("desactivarservicio")
        let nuevalista = this.state.cuenta;
        nuevalista.medio.forEach(md => {
            if (md.idcatalogocanalarchivos == valores.medio.idcatalogocanalarchivos) {
                md.servicios.forEach(servicioitem => {
                    if (servicioitem.idcatalogoservicios == valores.servicio.idcatalogoservicios) {
                        servicioitem.idcatalogoestatus = "";
                        servicioitem.estatus = "";
                    }
                })
            }
        });

        this.setState({ cuenta: nuevalista })
    }

    cambiarestatusservicio = (valores) => { // cambiarestatus de servicio
        //  console.log("cambiarestatusservicio", valores);
        let nuevalista = this.state.cuenta;
        nuevalista.medio.forEach(medioitem => {
            if (medioitem.idcatalogocanalarchivos == valores.medioid) {
                medioitem.servicios.forEach(servicioitem => {
                    if (servicioitem.idcatalogoservicios == valores.servicioid) {
                        servicioitem.estatus = this.state.listasituaciones[valores.indexestatus].estatus;
                        servicioitem.idcatalogoestatus = this.state.listasituaciones[valores.indexestatus].idcatalogoestatus;
                        if (servicioitem.idcuentacanalarchivosservicio != "") {
                            servicioitem.actualizar = "1"
                        }
                    }
                })
            }
        });

        // console.log(nuevalista.medio)
        this.setState({ cuenta: nuevalista });
    }









    ////////////////// acciones de formularios sms 

    limpiarformulariosms = () => {
        //  console.log('limpiarformularios()')
        let nuevos = this.llenarlistainputsFormularioSMS();
        this.setState({ inputsformulariosms: nuevos, tipoformulariosms: 1, contactoactivoedicion: null })
    }

    llenarlistainputsFormularioSMS = () => {
        let nuevalista = [];
        let actual = listainputsFormularioSMS()
        actual.forEach(item => {
            nuevalista.push(item)
        });
        return nuevalista
    }

    guardarvalorsms = (item) => {
        //  console.log("guardarvalor en modal");
        let listainputs = this.state.inputsformulariosms
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;
        });
        this.setState({ inputsformulariosms: listainputs })
    }

    cambiarestadosms = (indexestatus) => {
        //   console.log("cambiarestado de dropdown sms");
        let formulariosms = this.state.inputsformulariosms;
        formulariosms[4].valor = this.state.listasituaciones[indexestatus].estatus;
        formulariosms[4].contenido = this.state.listasituaciones[indexestatus].idcatalogoestatus;
        this.setState({ inputsformulariosms: formulariosms });
    }

    agregarelemento = () => {
        //  console.log("agregarelemento()", this.state.tipoformulariosms)

        let respuesta = this.validarcontenidosms();
        //  console.log("validando contenido sms: ", respuesta)

        if (respuesta.validar == true) {
            let cuenta = this.state.cuenta;
            let listacontactos = cuenta.contactossms;

            if (this.state.tipoformulariosms == 1 && this.state.contactoactivoedicion == null) {// nuevo contacto
                let nuevo = this.state.inputsformulariosms;
                listacontactos.push({
                    actualizado: "",
                    creado: "",
                    estatus: nuevo[4].valor,
                    idcatalogoestatus: nuevo[4].contenido,
                    idcuentacontactossms: "",
                    nombrecontacto: nuevo[1].valor,
                    puesto: nuevo[2].valor,
                    telefono: nuevo[3].valor,
                    usuarioactualizacion: "",
                    usuariocreacion: ""
                })
            }
            else {// editado
                let nuevo = this.state.inputsformulariosms;
                listacontactos[this.state.contactoactivoedicion].nombrecontacto = nuevo[1].valor
                listacontactos[this.state.contactoactivoedicion].puesto = nuevo[2].valor
                listacontactos[this.state.contactoactivoedicion].telefono = nuevo[3].valor
                listacontactos[this.state.contactoactivoedicion].estatus = nuevo[4].valor
                listacontactos[this.state.contactoactivoedicion].idcatalogoestatus = nuevo[4].contenido
                if (listacontactos[this.state.contactoactivoedicion].idcuentacontactossms != "") {
                    listacontactos[this.state.contactoactivoedicion].actualizar = "1"
                }
            }

            this.setState({ listacontactosms: cuenta });
            this.limpiarformulariosms();
        }
        else {
            this.setState({ inputsformulariosms: respuesta.formulario })
        }
    }

    validarcontenidosms = () => {
        let contenido = this.state.inputsformulariosms;
        //  console.log("validarcontenido()", contenido);
        let respuestavalidacion = true;
        //folio
        if (this.state.tipoformulariosms == 1) {
            //contacto
            let nombre = validarnombresms(contenido[1].valor)
            contenido[1].mensajevalidacion = nombre.mensaje;
            contenido[1].validacion = nombre.codigo;
            if (nombre.codigo == "invalid")
                respuestavalidacion = false;
        }


        //cargo
        let cargo = validarnombresms(contenido[2].valor)
        contenido[2].mensajevalidacion = cargo.mensaje;
        contenido[2].validacion = cargo.codigo;
        if (cargo.codigo == "invalid")
            respuestavalidacion = false;

        //telefono
        let telefono = validartelefono(contenido[3].valor)
        contenido[3].mensajevalidacion = telefono.mensaje;
        contenido[3].validacion = telefono.codigo;
        if (telefono.codigo == "invalid")
            respuestavalidacion = false;

        //estatus
        if (contenido[4].contenido == "") {
            toast.warn("Debes seleccionar una situación", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            respuestavalidacion = false;
        }

        return { validar: respuestavalidacion, formulario: contenido }
    }

    eliminarcontactosms = (posicioncontacto) => {
        // console.log("eliminarcontactos", posicioncontacto)
        let cuenta = this.state.cuenta;
        let nuevalista = [];
        cuenta.contactossms.forEach((contacto, index) => {
            if (index != posicioncontacto) {
                nuevalista.push(contacto)
            }
        });

        cuenta.contactossms = nuevalista;
        this.setState({ cuenta: cuenta });
    }

    editarcontacto = (valores) => {
        let cuenta = this.state.cuenta
        // console.log(valores, cuenta.contactossms)
        let nuevosinputs = ordenarlistainputsFormularioSMS(this.llenarlistainputsFormularioSMS(), cuenta.contactossms[valores.indexcontacto])
        // console.log("editarcontacto()", nuevosinputs)
        this.setState({ tipoformulariosms: valores.opcion, inputsformulariosms: nuevosinputs, contactoactivoedicion: valores.indexcontacto })
    }



















    guardarcambios = () => {
        this.props.iniciarspinner();
        // console.log("guardando cambios de cuenta");

        //validar datos de cuenta
        let validarcuentaformulario = this.validarcuenta();
        if (validarcuentaformulario == true) {
            //    //console.log("ok")
            let cuenta = this.state.cuenta;
            let cuentaform = this.state.inputs
            if (this.props.tipovista == 1) {// nueva cuenta
                //     //console.log("okok")

                //    //console.log(this.state.cuenta)
                //agregar datos del formulario
                var token = sessionStorage.getItem("ls").split(",")[3];
                let json = {
                    "token": token,
                    "modulo": "3000",
                    "idcuentas": "0",
                    "cuenta": cuentaform[0].valor,
                    "cliente": cuentaform[1].valor,
                    "correo": cuentaform[2].valor,
                    "idcatalogoenviarresultados": cuenta.idcatalogoenviarresultados,
                    "estatussms": cuenta.estatussms,
                    "idcatalogofamilias": cuenta.idcatalogofamilias,
                    "idcatalogoestatus": cuenta.idcatalogoestatus,
                    "medio": this.mediosenuso(),
                    "contactossms": this.contactosenuso(),
                    "idcatalogoarchivossalidamt940": this.state.idmt940,
                    "idcatalogoarchivossalidamt942": this.state.idmt942,
                    "mt940pordia": this.state.idgeneracion

                }
                //  console.log("jsoncaso1   ", json)

                this.enviardatos(json);

            }
            else {//edicion

                //  console.log("jsoncaso2     ", this.state.cuenta)
                var token = sessionStorage.getItem("ls").split(",")[3];
                let json = {
                    "token": token,
                    "modulo": "3000",
                    "idcuentas": cuenta.idcuentas,
                    "actualizar": cuenta.actualizar,
                    "cuenta": cuenta.cuenta,
                    "cliente": cuenta.cliente,
                    "correo": cuentaform[2].valor,
                    "idcatalogoenviarresultados": cuenta.idcatalogoenviarresultados,
                    "estatussms": cuenta.estatussms,
                    "idcatalogofamilias": cuenta.idcatalogofamilias,
                    "idcatalogoestatus": cuenta.idcatalogoestatus,
                    "medio": this.mediosenuso(),
                    "contactossms": this.contactosenuso(),
                    "idcatalogoarchivossalidamt940": this.state.idmt940,
                    "idcatalogoarchivossalidamt942": this.state.idmt942,
                    "mt940pordia": this.state.idgeneracion
                }
                //console.log(JSON.stringify(json));

                if (json.idcuentas == "0" || json.actualizar == "1" || json.medio.length > 0 || json.contactossms.length > 0) {
                    this.enviardatos(json);
                }
                else {
                    toast.warn("No hay cambios registrados en esta cuenta", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
            }

        } else {
            //  console.log("no")
        }
        this.props.detenerspinner();
    }

    contactosenuso = () => {
        let listacontactos = []
        let contactos = this.state.cuenta.contactossms
        if (this.props.tipovista == 1) {
            contactos.forEach(contacto => {
                listacontactos.push(
                    {
                        "idcuentacontactossms": "0",
                        "nombrecontacto": contacto.nombrecontacto,
                        "puesto": contacto.puesto,
                        "telefono": contacto.telefono,
                        "idcatalogoestatus": contacto.idcatalogoestatus,
                    }
                )
            });

        } else {
            contactos.forEach(contacto => {
                listacontactos.push(
                    {
                        "idcuentacontactossms": (contacto.idcuentacontactossms != "") ? (contacto.idcuentacontactossms) : "0",
                        "actualizar": (contacto.idcuentacontactossms != "") ? (contacto.actualizar) : "0",
                        "nombrecontacto": contacto.nombrecontacto,
                        "puesto": contacto.puesto,
                        "telefono": contacto.telefono,
                        "idcatalogoestatus": contacto.idcatalogoestatus,
                    }
                )
            });
        }

        let listadepurada = []
        listacontactos.forEach(contacto => {
            if (contacto.actualizar == "1" || contacto.idcuentacontactossms == "0") {
                listadepurada.push(contacto)
            }
        });
        return listadepurada
    }


    mediosenuso = () => {
        let medios = this.state.cuenta.medio;
        let listamedios = [];

        if (this.props.tipovista == 1) {
            medios.forEach(medio => {
                if (medio.idcatalogoestatus != "")
                    listamedios.push({
                        "idcuentacanalarchivos": "0",
                        "idcatalogocanalarchivos": medio.idcatalogocanalarchivos,
                        "idcatalogoestatus": medio.idcatalogoestatus,
                        "servicios": this.serviciosenuso(medio.servicios)
                    })
            });
            return listamedios;
        }
        else {
            medios.forEach(medio => {
                if (medio.idcatalogoestatus != "") {// est[a en uso]
                    listamedios.push({
                        "actualizar": medio.actualizar,
                        "idcuentacanalarchivos": (medio.idcuentacanalarchivos != "") ? (medio.idcuentacanalarchivos) : "0",
                        "idcatalogocanalarchivos": medio.idcatalogocanalarchivos,
                        "idcatalogoestatus": medio.idcatalogoestatus,
                        "servicios": this.serviciosenuso(medio.servicios)
                    })
                }
            });

            let listadepurada = []
            listamedios.forEach(medio => {
                if (medio.idcuentacanalarchivos == "0") { //solo si el medio es nuevo
                    listadepurada.push(medio)
                }
                else if (medio.servicios.length > 0) { // solo si el medio tiene servicios nuevos o actualizados
                    listadepurada.push(medio)
                }
                else if (medio.actualizar == "1") {// solo si el medio es actualizado
                    listadepurada.push(medio)
                }
            });

            return listadepurada;
        }
    }

    serviciosenuso = (servicios) => {
        let listaservicios = []

        if (this.props.tipovista == 1) {

            servicios.forEach(servicio => {
                if (servicio.idcatalogoestatus != "") {
                    listaservicios.push({
                        "idcuentacanalarchivosservicio": "0",
                        "idcatalogoservicios": servicio.idcatalogoservicios,
                        "idcatalogoestatus": servicio.idcatalogoestatus,
                    })
                }
            });
            return listaservicios

        } else {

            servicios.forEach(servicio => { //todos los servicios activos
                if (servicio.idcatalogoestatus != "") {
                    listaservicios.push({
                        "idcuentacanalarchivosservicio": (servicio.idcuentacanalarchivosservicio != "") ? (servicio.idcuentacanalarchivosservicio) : "0",
                        "idcatalogoservicios": servicio.idcatalogoservicios,
                        "idcatalogoestatus": servicio.idcatalogoestatus,
                        "actualizar": servicio.actualizar
                    })
                }
            });


            let listadepurada = []

            listaservicios.forEach(servicio => {
                if (servicio.idcuentacanalarchivosservicio == "0") {//solo los servicios que son nuevos
                    listadepurada.push({
                        "idcuentacanalarchivosservicio": servicio.idcuentacanalarchivosservicio,
                        "idcatalogoservicios": servicio.idcatalogoservicios,
                        "idcatalogoestatus": servicio.idcatalogoestatus,
                    })
                }
                else if (servicio.actualizar == "1") {//solo los servicios que tuvieron cambios
                    listadepurada.push({
                        "idcuentacanalarchivosservicio": servicio.idcuentacanalarchivosservicio,
                        "idcatalogoservicios": servicio.idcatalogoservicios,
                        "idcatalogoestatus": servicio.idcatalogoestatus,
                    })
                }

            });
            //   console.log("listaservicios", listadepurada.length)
            return listadepurada;
        }

    }



    validarcuenta() {
        //validar formulario

        let form = this.state.inputs;
        let respuestavalidacion = true;

        if (this.props.tipovista == 1) {
            let cuenta = vaidarcuentafolio(form[0].valor)
            form[0].mensajevalidacion = cuenta.mensaje;
            form[0].validacion = cuenta.codigo;
            if (cuenta.codigo == "invalid") {
                respuestavalidacion = false;
            }

            let nombre = validarnombrecuenta(form[1].valor)
            form[1].mensajevalidacion = nombre.mensaje;
            form[1].validacion = nombre.codigo;
            if (nombre.codigo == "invalid") {
                respuestavalidacion = false;
            }
        }

        let correo = validarEmail(form[2].valor)
        form[2].mensajevalidacion = correo.mensaje;
        form[2].validacion = correo.codigo;
        if (correo.codigo == "invalid") {
            respuestavalidacion = false;
            //     //console.log(3)
        }

        this.setState({ inputs: form });

        if (respuestavalidacion == false) {
            toast.warn("Parece que hay campos incorrectos, verifica la información", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }


        return respuestavalidacion;
    }


    enviardatos = async (json) => {
        let enviado = null;

        if (this.props.tipovista == 1) {
            enviado = await administracion_nuevacuenta(json);
        }
        else if (this.props.tipovista == 2) {
            enviado = await administracion_editarcuenta(json);

        }

        if (enviado.code == 0) {
            toast.success("Cuenta guardada exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            //return true
        }
        else {
            if (enviado.code == 1) {
                toast.warn("No se pudo guardar, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (enviado.code == 2) {
                toast.warn(enviado.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensajemodalcatalogo(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            // return false
        }

        setTimeout(() => {
            this.props.iniciarspinner();
            if (enviado.code == 0) {
                this.props.refreshInfo()
                this.showmodal()
            }
            else {
                this.props.detenerspinner()
            }
        }, 300);

    }



    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }
    render() {
        return (
            <>

                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                <ToastContainer />
                <Modal isOpen={this.state.show}
                    aria-labelledby=""
                    size="xl"
                    centered
                    className="modal-cuentas ">
                    <ModalBody>
                        <div>
                            <p className="" style={{ marginBottom: "0px" }}>
                                <label className="modal-titulo-aux"><b>{this.props.modaltitulo}</b></label>
                                <label className="modal-cuentas-cerrar">
                                    <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> </Button>
                                </label>
                            </p>
                        </div>

                        <div className="row modal-cuentas-maincontent">
                            <div className="col-12">
                                <div className="row modal-cuentas-mainheader ">
                                    <FormularioCuenta
                                        cuenta={this.state.cuenta}
                                        tipovista={this.props.tipovista}
                                        titulos={this.props.itemtitulos}
                                        inputs={this.state.inputs}
                                        familias={this.state.listafamilias}
                                        situaciones={this.state.listasituaciones}
                                        envioresultados={this.state.listaenvioresultados}
                                        guardarvalor={(e) => this.guardarvalor(e)}
                                        cambiarestatuscuenta={(e) => this.cambiarestatuscuenta(e)}
                                        cambiarnotificaciones={(e) => this.cambiarnotificaciones(e)}
                                        cambiarfamilia={(e) => this.cambiarfamilia(e)}
                                        cambiarindicadorsms={() => this.cambiarindicadorsms()}
                                    />

                                    {(this.props.tipovista != 1) ? ( //info de [ultima edici[on de elemento]]
                                        <>

                                            <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-creacion" >
                                                <label>Creado por: </label>
                                                <b> <div>{this.state.cuenta.usuariocreacion}</div> </b>
                                            </div>
                                            <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-creacion" >
                                                <label>Fecha de creación: </label>
                                                <b> <div>{this.state.cuenta.creado}</div> </b>
                                            </div>
                                            {(this.state.cuenta.usuarioactualizacion != "") ? (
                                                <>
                                                    <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-actualizacion" >
                                                        <label>Actualizado por: </label>
                                                        <b> <div>{this.state.cuenta.usuarioactualizacion}</div> </b>
                                                    </div>
                                                    <div className=" col-md-6 col-sm-6 col-lg-3 col-3 col-xs-12 modal-cuenta-actualizacion" >
                                                        <label>Última actualización: </label>
                                                        <b> <div>{this.state.cuenta.actualizado}</div> </b>
                                                    </div>
                                                </>
                                            ) :
                                                null
                                            }
                                        </>

                                    ) : null}


                                    <div className="row align-items-center">

                                        <div className=" col-md-6 col-sm-6 col-lg-6 col-6 col-xs-12 modal-cuenta-creacion" >
                                            <br />
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <label style={{ width: '158px' }}>Copiado de archivo MT940: </label>
                                                </div>
                                                <div className="col">

                                                    {(this.props.tipovista == 2 || this.props.tipovista == 1) ? (//situaci[on]

                                                        <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                                            onChange={(e) => this.cambiarmt940(e.target.value)}>
                                                            {this.state.arrayarchivos940.map((situacion, index) => (
                                                                <>
                                                                    {(situacion.idcatalogoarchivossalidamt == this.state.idmt940) ? (
                                                                        <option value={situacion.idcatalogoarchivossalidamt} selected>{situacion.salida}</option>
                                                                    ) :
                                                                        <option value={situacion.idcatalogoarchivossalidamt} >{situacion.salida}</option>
                                                                    }
                                                                </>
                                                            ))}
                                                        </select>

                                                    ) :
                                                        <label><b>{this.props.contenido.salidamt940}</b></label>}

                                                </div>

                                            </div>



                                        </div>

                                        <div className=" col-md-6 col-sm-6 col-lg-6 col-6 col-xs-12 modal-cuenta-creacion" >
                                            <br />
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <label style={{ width: '158px' }}>Copiado de archivo MT942: </label>
                                                </div>
                                                <div className="col">
                                                    {(this.props.tipovista == 2 || this.props.tipovista == 1) ? (//situaci[on]

                                                        <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem" }}
                                                            onChange={(e) => this.cambiarmt942(e.target.value)}>
                                                            {this.state.arrayarchivos942.map((situacion, index) => (
                                                                <>
                                                                    {(situacion.idcatalogoarchivossalidamt == this.state.idmt942) ? (
                                                                        <option value={situacion.idcatalogoarchivossalidamt} selected>{situacion.salida}</option>
                                                                    ) :
                                                                        <option value={situacion.idcatalogoarchivossalidamt} >{situacion.salida}</option>
                                                                    }
                                                                </>
                                                            ))}
                                                        </select>
                                                    ) :
                                                        <label><b>{this.props.contenido.salidamt942}</b></label>}
                                                </div>

                                            </div>



                                        </div>
                                    </div>



                                    <div className="row align-items-center">

                                        <div className=" col-12 modal-cuenta-creacion" >
                                            <br />
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <label style={{ width: '158px' }}>Generación por rango o<br />por día: </label>
                                                </div>
                                                <div className="col">

                                                    {(this.props.tipovista == 2 || this.props.tipovista == 1) ? (//situaci[on]

                                                        <select className="blueoutlinebutton" style={{ padding: ".375rem .75rem", width: '244px' }}
                                                            onChange={(e) => this.cambiargeneracion(e.target.value)}>
                                                            {this.state.arrayageneracion.map((item, index) => (
                                                                <>
                                                                    {(item.id == this.state.idgeneracion) ? (
                                                                        <option value={item.id} selected>{item.nombre}</option>
                                                                    ) :
                                                                        <option value={item.id} >{item.nombre}</option>
                                                                    }
                                                                </>
                                                            ))}
                                                        </select>

                                                    ) :
                                                        <>
                                                            {this.state.arrayageneracion.map((item, index) => (
                                                                <>
                                                                    {(item.id == this.props.contenido.mt940pordia) ? (

                                                                        <label><b>{item.nombre}</b></label>
                                                                    ) : null}
                                                                </>
                                                            ))

                                                            }
                                                        </>
                                                    }
                                                </div>

                                            </div>



                                        </div>
                                    </div>

                                </div>


                                {(this.props.tipovista == 3) ? (
                                    <>
                                        {(this.props.itemactivo.medio.length > 0) ? (
                                            <VistaTablerosCuenta
                                                medio={this.props.itemactivo.medio}
                                                headermedios={this.state.headermedios}
                                                headerservicios={this.state.headerservicios}
                                                headerestados={this.state.headerestados}
                                            />
                                        ) : <b>No hay medios ni servicios agregados</b>}
                                    </>
                                ) : null}


                                {(this.props.tipovista != 3) ? (
                                    <>
                                        {(this.state.cuenta.medio.length > 0) ? (
                                            <div className="row">
                                                <TableroEditableCuenta
                                                    listamedios={this.state.cuenta.medio}
                                                    headermedios={this.state.headermedios}
                                                    listasituaciones={this.state.listasituaciones}
                                                    activarmedio={this.activarmedio}
                                                    desactivarmedio={this.desactivarmedio}
                                                    cambiarestatusmedio={this.cambiarestatusmedio}
                                                    headerservicios={this.state.headerservicios}
                                                    activarservicio={(e) => this.activarservicio(e)}
                                                    desactivarservicio={(e) => this.desactivarservicio(e)}
                                                    cambiarestatusservicio={(e) => this.cambiarestatusservicio(e)}
                                                />
                                            </div>
                                        ) : <b>No hay medios ni servicios agregados</b>}
                                    </>
                                ) : null}


                                <SeccionContactos
                                    cuenta={this.state.cuenta}
                                    tipovista={this.props.tipovista}

                                    tipoformulariosms={this.state.tipoformulariosms}
                                    inputsformulariosms={this.state.inputsformulariosms}
                                    listasituaciones={this.state.listasituaciones}
                                    headersms={this.state.headersms}
                                    contactoactivoedicion={this.state.contactoactivoedicion}

                                    guardarvalorsms={(e) => this.guardarvalorsms(e)}
                                    cambiarestadosms={(e) => this.cambiarestadosms(e)}
                                    agregarelemento={(e) => this.agregarelemento(e)}
                                    editarcontacto={(e) => this.editarcontacto(e)}
                                    eliminarcontactosms={(e) => this.eliminarcontactosms(e)}
                                    limpiarformulariosms={this.limpiarformulariosms}
                                />

                            </div>
                        </div>





                        {(this.props.tipovista != 3) ? (
                            <>
                                <div className="modal-cuentas-pie-edicion " style={{ margin: "auto", width: "fit-content", marginBottom: "20px" }}>

                                    <div className="modal-catalogo-checkinput modal-cuentas-checkinput "
                                        style={{ margin: "auto" }}
                                        onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                        <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar envío de formulario
                                    </div>

                                    <div className="modal-catalogos-buttons " style={{ maxWidth: "400px", margin: "auto", marginTop: "10px" }}>
                                        <Button className="cancelbutton" onClick={() => this.showmodal()}><GoX /> Cancelar</Button>
                                        <Button className="bluebutton" onClick={() => this.guardarcambios()} disabled={!this.state.confirmarenvio} > <BsCheckLg /> Guardar</Button>
                                    </div>

                                </div>
                            </>



                        ) :
                            <div style={{ width: "200px", marginLeft: "auto" }}>
                                <Button className="cancelbutton" onClick={() => this.showmodal()}><GoX /> Cerrar</Button>
                            </div>
                        }
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

