import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import { cambiar_password, gestion_editarusuario } from "../../Services/Api";
import { validarasesornombre, validarnombres, validarnuevopassword, validarpassword } from "../../Services/Validaciones";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import Nabvar from "../Panel/Nabvar";
import AreaMensajes from "../Modales/AreaMensajes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoX } from "react-icons/go";
import Inputform from "../Inputform/Inputform";
import { RiRefreshLine } from "react-icons/ri";

export default class Password extends React.Component {

    constructor(props) {
        super(props)

        let usuario = sessionStorage.getItem("ls");
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Contraseña",


            confirmarenvio: false,
            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            inputsform: [
                {
                    tituloinput: "Contraseña actual:", contenido: "", tipoinput: "password", posicion: 1,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },
                {
                    tituloinput: "Nueva contraseña:", contenido: "", tipoinput: "password", posicion: 2,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },
                {
                    tituloinput: "Confirmación de la nueva contraseña:", contenido: "", tipoinput: "password", posicion: 3,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },

            ],
            loadingImage: false,
            cancelar: false,
            seguridadpassword: 0

        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }


    refresh = async () => {


        let inputs = this.state.inputsform;

        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";

        inputs[0].validacion = "";
        inputs[1].validacion = "";
        inputs[2].validacion = "";

        this.setState({ inputsform: inputs, seguridadpassword: 0, confirmarenvio: false, showmensaje: false });

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        //console.log("Contrasenia");
        this.refresh();
    }

    guardarcambios = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        let respuestavalidacion = await this.validarcontenido(this.state.inputsform);
        if (respuestavalidacion.aprobado) {
            this.finalizaraccion()
        }
        else {
            this.setState({ loadingImage: false })
        }
    }

    validarcontenido = async (contenido) => {
        let respuestavalidacion = true;

        let nombreguardado = validarpassword(contenido[0].valor)
        contenido[0].mensajevalidacion = nombreguardado.mensaje;
        contenido[0].validacion = nombreguardado.codigo;
        if (nombreguardado.codigo == "invalid")
            respuestavalidacion = false;

        let apellidoguardado = validarnuevopassword(contenido[1].valor)
        contenido[1].mensajevalidacion = apellidoguardado.mensaje;
        contenido[1].validacion = apellidoguardado.codigo;
        if (apellidoguardado.codigo == "invalid")
            respuestavalidacion = false;

        if (contenido[2].valor != contenido[1].valor) {
            respuestavalidacion = false;
            contenido[2].mensajevalidacion = "Las contraseñas no coinciden";
            contenido[2].validacion = "invalid";
        }
        else {
            contenido[2].mensajevalidacion = "";
            contenido[2].validacion = "valid";
        }


        //detener spinner si invalid 
        if (respuestavalidacion == true) {
            respuestavalidacion = await this.enviardatos(contenido);

            if (respuestavalidacion == false) {
                setTimeout(() => {
                    this.setState({ loadingImage: false })
                }, 200);                
            }
        }
        else {
            this.setState({ loadingImage: false })
        }

        return { aprobado: respuestavalidacion, nuevoarreglo: contenido }
    }

    finalizaraccion = async () => {
        this.refresh()
    }

    enviardatos = async (datos) => {

        let password = datos[0].valor;
        let nuevopassword = datos[1].valor;
        let enviado = null;

        enviado = await cambiar_password(password, nuevopassword);

        if (enviado.code == 0) {
            toast.success("Contraseña guardada exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else {
            if (enviado.code == 1) {
                toast.warn("No se pudo guardar, contraseña incorrecta", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (enviado.code == 2) {
                toast.warn(enviado.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            return false
        }
    }



    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }


    guardarvalor = (item) => {
        let listainputs = this.state.inputsform
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;


            if (item.posicion == 2) {
                this.setState({ seguridadpassword: seguridad_clave(item.valor) })
            }
        });
        this.setState({ inputsform: listainputs })
    }

    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                {(this.state.cancelar == true) ? (
                    <Redirect to="/" />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body "  style={{ minWidth: "400px",maxWidth:"500px", marginLeft:"auto", marginRight:"auto" }}>

                        <div className="panel-body-header-table" style={{ minWidth: "300px", minHeight: "70px", maxHeight: "100px" }}>
                            {this.state.titulotabla}
                            <div className="panel-body-header-table-buttons ">
                                <div>
                                    <Button className="greenoutlinebutton" onClick={this.refresh}><RiRefreshLine /> Refrescar</Button>
                                </div>
                            </div>
                        </div>


                        <Inputform datosinput={this.state.inputsform[0]} valoringresado={(e) => this.guardarvalor(e)} />
                        <br />
                        <Inputform datosinput={this.state.inputsform[1]} valoringresado={(e) => this.guardarvalor(e)} />
                        <br />
                        <div>
                            Seguridad de la contraseña:
                        </div>
                        <div className="password-seguridad-bar">
                            <div className="password-seguridad-filler" style={{ width: this.state.seguridadpassword + "%" }}></div>
                        </div>

                        <br />
                        <Inputform datosinput={this.state.inputsform[2]} valoringresado={(e) => this.guardarvalor(e)} />
                        <br />

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>
                                {(this.state.showmensaje) ? (
                                    <AreaMensajes
                                        showmensaje={this.state.showmensaje}
                                        titulo={this.state.titulo}
                                        mensaje={this.state.mensaje} />
                                ) : null}

                                <div className="modal-catalogo-checkinput" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar envío de formulario
                                </div>
                                <div className="modal-catalogos-buttons">
                                    <Button className="cancelbutton" onClick={() => this.setState({ cancelar: true })}><GoX /> Cancelar</Button>
                                    <Button disabled={!this.state.confirmarenvio} className="bluebutton" onClick={() => this.guardarcambios()}> <BsCheckLg /> Guardar</Button>
                                </div>
                            </>
                        }
                    </div>

                    <div className="panel-footer">
                    <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>
            </>
        );
    }
}





function seguridad_clave(clave) {
    var seguridad = 0;
    if (clave.length != 0) {
        if (tiene_numeros2(clave) && tiene_letras2(clave)) {
            seguridad += 30;
        }
        if (tiene_minusculas(clave) && tiene_mayusculas(clave)) {
            seguridad += 30;
        }
        if (clave.length >= 8 && clave.length <= 9) {
            seguridad += 10;
        } else {
            if (clave.length >= 10 && clave.length <= 11) {
                seguridad += 30;
            } else {
                if (clave.length > 11) {
                    seguridad += 40;
                }
            }
        }
    }
    return seguridad
}


function tiene_mayusculas(cadena) {
    var contar = 0;
    var buscar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0; i < buscar.length; i++) {
        for (var x = 0; x < cadena.length; x++) {
            if (cadena[x] == buscar[i]) {
                contar += 1;
            }
        }
    }
    return contar;
}


function tiene_minusculas(cadena) {
    var contar = 0;
    var buscar = "abcdefghijklmnopqrstuvwxyz";
    for (var i = 0; i < buscar.length; i++) {
        for (var x = 0; x < cadena.length; x++) {
            if (cadena[x] == buscar[i]) {
                contar += 1;
            }
        }
    }
    return contar;
}


function tiene_numeros2(cadena) {
    var contar = 0;
    var buscar = "0123456789";
    for (var i = 0; i < buscar.length; i++) {
        for (var x = 0; x < cadena.length; x++) {
            if (cadena[x] == buscar[i]) {
                contar += 1;
            }
        }
    }
    return contar;
}

function tiene_letras2(cadena) {
    var contar = 0;
    var buscar = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0; i < buscar.length; i++) {
        for (var x = 0; x < cadena.length; x++) {
            if (cadena[x] == buscar[i]) {
                contar += 1;
            }
        }
    }
    return contar;
}






