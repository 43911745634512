import React, { useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Font } from '@react-pdf/renderer'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CSVLink, CSVDownload } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ExportarVolumenOperado extends React.Component {
    constructor(props) {
        super(props);

        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }

        this.state = {
            fechaActual: fechaaux,
        }

    }

    verdatos = () => {
        //console.log("Data -- ");
        //console.log(JSON.stringify(this.props.arraydatos));
    }

    pruebatotal = () => {
        //console.log("DDDD");
        //console.log(JSON.stringify(this.props.arraydatos));
    }

    mensajesindatos = () => {
        toast.warn("No hay datos que exportar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }


    render() {
        return (

            <>
                <ToastContainer />
                {this.props.bandera == "PDF" ? (
                    <>

                        {this.props.arraydatos.length > 0 ?
                            <PDFDownloadLink onClick={this.verdatos}
                                document={
                                    <MyDocument
                                        detalles={this.props.detalles}
                                        fechaActual={this.state.fechaActual}
                                        fechaI={this.props.fechaI}
                                        fechaF={this.props.fechaF}
                                        cliente={this.props.cliente}
                                        familia={this.props.familia}
                                        tipo={this.props.tipo}
                                        legado={this.props.legado}
                                        array_heders={this.props.array_heders}
                                        arraydatos={this.props.arraydatos}
                                        sub_heders={this.props.sub_heders}
                                    />
                                } fileName="Volumen_Operado_por_Clientes_y_Tipos_de_servicio.pdf">
                                <BsFillFileEarmarkPdfFill className="consulta-archivos-pdf" style={{ color: "#ad0b00", fontSize: "30px" }} />
                            </PDFDownloadLink>
                            :
                            <BsFillFileEarmarkPdfFill onClick={this.mensajesindatos} className="consulta-archivos-pdf" style={{ color: "#ad0b00", fontSize: "30px" }} />
                        }
                    </>
                ) :
                    <>
                        {this.props.arraydatos.length > 0 ?
                            <CSVLink data={this.props.arraycsv} filename={"Volumen_Operado_por_Clientes_y_Tipos_de_servicio.csv"} enclosingCharacter={``} >
                                <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px" }} />
                            </CSVLink>
                            :
                            <RiFileExcel2Fill onClick={this.mensajesindatos} className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px" }} />
                        }
                    </>}

            </>
        )
    }
}

Font.register({
    family: 'Oswald',
    fontWeight: 900,
    src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf',
});


let tourItemtabla2 = {
    paddingTop: '4px',
    paddingBottom: '4px',
    borderTop: '2px solid black',
    borderBottom: '2px solid black'
};


let tourItemImage = {
    paddingTop: '3px',
    paddingBottom: '3px',
    borderBottom: '1px solid black'
};


// Create Document Component
export function MyDocument(props) {

    var data = props.arraydatos;


    return (
        <Document>

            <Page orientation="landscape" size="A4" style={stylestabla2.page} >

                <View fixed>
                    <View style={{ paddingBottom: '7px' }}>   </View>
                    <View style={[stylestabla2.row, stylestabla2.bold, stylestabla2.header]}>

                        <View style={stylestabla2.rowimage}>

                        </View>

                        <View style={stylestabla2.rowtitle}>
                            <Text style={stylestabla2.lbltitle}>Volumen Operado por Clientes y Tipos de servicios</Text>
                        </View>

                        <View style={stylestabla2.rowdate}>
                            <Text style={stylestabla2.lblDate}>Fecha de gestión: {props.fechaActual}</Text>
                        </View>

                    </View>

                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Familia: {props.familia}</Text>
                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Cliente: {props.cliente}</Text>

                    <View style={[stylestabla2.subrow, stylestabla2.bold, stylestabla2.header]}>
                        <View style={stylestabla2.rowtitle}>
                            <Text style={stylestabla2.lbltexto}>Tipo de servicio: {props.tipo}</Text>
                        </View>

                        <View style={stylestabla2.rowimage}>

                        </View>

                        <View style={stylestabla2.rowdate}>
                            <Text style={stylestabla2.lbltexto} render={({ pageNumber }) =>
                                `Hoja: ${pageNumber}`
                            } fixed></Text>

                        </View>
                    </View>

                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Fecha del {props.fechaI} al {props.fechaF}</Text>
                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Procesados Legados: {props.legado}</Text>
                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Detallado: {props.detalles == true ? 'SI' : 'NO'}</Text>




                    <View style={[stylestabla2.subrow, stylestabla2.bold, stylestabla2.header, tourItemtabla2]}>

                        <View style={stylestabla2.subcol1}>
                            <Text style={[styles.lbltitlesubrow, { paddingLeft: '3px' }]}>{props.array_heders[0]}</Text>
                        </View>

                        <View style={stylestabla2.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[1]}</Text>
                        </View>

                        <View style={stylestabla2.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[2]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[3]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[4]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[5]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[6]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[7]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[8]}</Text>
                        </View>

                    </View>



                    <View style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header, tourItemImage]}>

                        <View style={stylestabla2.subcol1detalles}>
                            <Text style={[styles.lbltitlesubrow, { paddingLeft: '3px' }]}
                            >{props.sub_heders[0].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1detalles}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[1].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1detalles}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[2].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[3].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1detalles}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[4].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1detalles}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[5].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[6].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[7].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcolunico}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[8].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[9].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[10].titulo}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={styles.lbltitlesubrow}>{props.sub_heders[11].titulo}</Text>
                        </View>



                    </View>



                </View>


                <View style={{ paddingBottom: '6px' }}>   </View>

                {data.length > 0 ? (
                    <>
                        {data.map((items, i) => (
                            <View key={'a' + i}>
                                {(items.totales == false) ?
                                    <>

                                        <View key={'a' + i} style={{ paddingTop: '4px' }}></View>
                                        <View key={i} style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header]}>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.canal}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol2}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.servicio}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol2}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.familia}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.cliente}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.cuenta}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.divisa}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.importe}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.operado}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}>{items.nooperado}</Text>
                                            </View>

                                        </View>
                                        <View key={'b' + i} style={{ paddingBottom: '3px' }}>   </View>


                                        {props.detalles == true ? (

                                            <>

                                                {items.detalles.map((itemsdetalles, k) => (
                                                    <>

                                                        {(itemsdetalles.totales == false) ?
                                                            <>

                                                                <View key={'d' + i} style={{ paddingTop: '3px' }}></View>
                                                                <View key={k} style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header]}>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.idcuentassolicitudesservicios}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.idcuentassolicitudesserviciosvalidados}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.fechasolicitudlegado}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.fila}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.divisa}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.importe}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.operado}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcolunico}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.nooperado}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol2}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.archivo}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol2}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.codigodescripcion}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.concepto}</Text>
                                                                    </View>

                                                                </View>
                                                                <View key={'e' + i} style={{ paddingBottom: '3px' }}>   </View>

                                                            </> :

                                                            <>

                                                                <View key={'f' + i} style={{ paddingTop: '3px' }}></View>
                                                                <View key={k} style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header]}>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={[stylestabla2.lblsubtextorow, stylestabla2.bold]}>SUB TOTAL</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1detalles}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>



                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.importe}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.operado}</Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol2}>
                                                                        <Text style={stylestabla2.lblsubtextorow}>{itemsdetalles.nooperado}</Text>
                                                                    </View>
                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                    <View style={stylestabla2.subcol1}>
                                                                        <Text style={stylestabla2.lblsubtextorow}></Text>
                                                                    </View>

                                                                </View>
                                                                <View key={'g' + i} style={{ paddingBottom: '3px' }}>   </View>
                                                            </>
                                                        }
                                                    </>
                                                ))}


                                            </>) :
                                            null
                                        }



                                    </>
                                    :
                                    <>
                                        <View key={'h' + i} style={{ paddingTop: '4px' }}></View>



                                        <View key={i} style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header]}>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={[stylestabla2.lbltotales, stylestabla2.bold]}>TOTAL</Text>
                                            </View>

                                            <View style={stylestabla2.subcol2}>
                                                <Text style={stylestabla2.lblsubtextorow}></Text>
                                            </View>

                                            <View style={stylestabla2.subcol2}>
                                                <Text style={stylestabla2.lblsubtextorow}></Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}></Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}></Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lblsubtextorow}></Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lbltotales}>{items.importe}</Text>
                                            </View>
                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lbltotales}>{items.operado}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol1}>
                                                <Text style={stylestabla2.lbltotales}>{items.nooperado}</Text>
                                            </View>

                                        </View>



                                        <View key={'i' + i} style={{ paddingBottom: '3px' }}>   </View>

                                    </>
                                }




                            </View>
                        ))}
                    </>

                ) : null}
            </Page>

        </Document>
    )
};




const stylestabla2 = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        orientation: 'landscape',
        backgroundColor: 'white',
        paddingBottom: '9%'
    },

    image: {
        width: "110px",
        padding: '5px',
    },


    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    lbltitle: {
        paddingTop: '10px',
        fontSize: '17px',
        fontWeight: '700',
        textAlign: 'center',
        color: 'black',
        letterSpacing: '0.9px'
    },

    lblDate: {
        paddingTop: '10px',
        fontSize: '12px',
        color: 'black'
    },


    lbltexto: {
        fontSize: '10px',
        color: 'black',
        paddingBottom: '4px'
    },

    padinlateral: {
        paddingLeft: '10px',
    },

    lbltitlesubrow: {

        fontSize: '7px',
        fontWeight: '700'

    },

    lblsubtextorow: {
        fontSize: '8px'
    },

    lbltotales: {
        fontSize: '8px',
        fontWeight: 900,
    },

    lbltextlateral: {
        textAlign: 'right',
        paddingRight: '5px'
    },


    negrita: {
        fontWeight: 900,
    },

    table: {
        width: '100%',
    },

    row: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },

    header: {
        borderTop: 'none',
    },

    rowimage: {
        width: '15%'
    },

    rowtitle: {
        width: '66%'
    },

    rowdate: {
        width: '21%'
    },


    subrow: {
        borderTopWidth: '4px',
        borderTopColor: '#0379ff',
        borderStyle: 'solid',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 2,
        paddingBottom: 2,
    },



    rowinternos: {

        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px',
        marginRight: '10px'
    },

    subcol1detalles: {
        width: '6%'
    },
    subcolunico: {
        width: '10%'
    },

    subcol1: {
        width: '8%'
    },

    subcol2: {
        width: '13%'
    },

    subcol3: {
        width: '25%'
    },

    ////EL segundo heders///

    subcol4: {
        width: '7.1%'
    },
    subcol5: {
        width: '25%'
    },
    subcol6: {
        width: '22.5%'
    },
    subcol7: {
        width: '38.3%'
    }

})





// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        orientation: 'landscape',
        backgroundColor: 'white',
        paddingBottom: '7%'
    },

    image: {
        width: "110px",
        padding: '5px',
    },


    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    lbltitle: {
        paddingTop: '10px',
        fontSize: '17px',
        fontWeight: '700',
        textAlign: 'center',
        color: 'black',
        letterSpacing: '0.9px'
    },

    lblDate: {
        paddingTop: '10px',
        fontSize: '12px',
        color: 'black'
    },


    lbltexto: {
        fontSize: '10px',
        color: 'black',
        paddingBottom: '4px'
    },

    padinlateral: {
        paddingLeft: '10px',
    },

    lbltitlesubrow: {

        fontSize: '7px',
        fontWeight: '700'

    },

    lblsubtextorow: {
        fontSize: '8px'
    },

    lbltotales: {
        fontSize: '8px',
        fontWeight: 900,
    },

    lbltextlateral: {
        textAlign: 'right',
        paddingRight: '5px'
    },


    negrita: {
        fontWeight: 900,
    },

    table: {
        width: '100%',
    },

    row: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },

    header: {
        borderTop: 'none',
    },

    rowimage: {
        width: '15%'
    },

    rowtitle: {
        width: '66%'
    },

    rowdate: {
        width: '21%'
    },


    subrow: {
        borderTopWidth: '4px',
        borderTopColor: '#0379ff',
        borderStyle: 'solid',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 2,
        paddingBottom: 2,
    },

    rowinternos: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px',
        marginRight: '10px',
        paddingTop: 2,
        paddingBottom: 1,
    },


    subcol1: {
        width: '8%'
    },

    subcol2: {
        width: '13%'
    },

    subcol3: {
        width: '25%'
    },


})

