
//administraci[on ] cuentas

export function itemactivoGestionusuarios(idelemento, elemento) {
    let nuevoelemento = [];
    if (idelemento == "") {
        nuevoelemento.push({
            "idusuario": "",
            "login": "",
            "nombre": "",
            "apellido": "",
            "email": "",
            "image_url": "",
            "usuariocreacion": "",
            "creado": "",
            "usuarioactualizacion": "",
            "actualizado": "",
            "idioma": "",
            "tipo": "",
            "idusuariotipo": "",
            "idestatus": "",
            "estatus": ""

        })
    } else {
        nuevoelemento.push(elemento)
    }
    return nuevoelemento;
}





export function listainputsUsuario() {
    let lista = [
        {
            tituloinput: "ID:", contenido: "", tipoinput: "vista", posicion: 1,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Login:", contenido: "", tipoinput: "vista", posicion: 2,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Nombre:", contenido: "", tipoinput: "vista", posicion: 3,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Apellidos:", contenido: "", tipoinput: "vista", posicion: 4,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Correo electrónico:", contenido: "", tipoinput: "vista", posicion: 5,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Activado:", contenido: "", tipoinput: "vista", posicion: 6,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },

        {
            tituloinput: "Perfiles:", contenido: "", tipoinput: "vista", posicion: 7,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Creado por:", contenido: "", tipoinput: "vista", posicion: 8,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de creación:", contenido: "", tipoinput: "vista", posicion: 9,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Actualizado por:", contenido: "", tipoinput: "vista", posicion: 10,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        },
        {
            tituloinput: "Fecha de actualización:", contenido: "", tipoinput: "vista", posicion: 11,
            validacion: "", mensajevalidacion: "erro de descripción", disabled: "false", valor: ""
        }
    ]

    return lista

}

export function ordenarListainputsUsuario(origen, inputs, elemento) {

    if (origen == 2) {
        inputs[0].valor = elemento.idusuario;
        inputs[1].valor = elemento.login;
        inputs[2].valor = elemento.nombre;
        inputs[3].valor = elemento.apellido;
        inputs[4].valor = elemento.email;
        inputs[5].valor = elemento.estatus;
        inputs[5].contenido = elemento.idestatus;
        inputs[6].valor = elemento.tipo;
        inputs[6].contenido = elemento.idusuariotipo;

        inputs[7].valor = elemento.usuariocreacion;
        inputs[8].valor = elemento.creado;
        inputs[9].valor = elemento.usuarioactualizacion;
        inputs[10].valor = elemento.actualizado;



        inputs[1].tipoinput = "text";
        inputs[1].disabled = false;
        inputs[2].tipoinput = "text";
        inputs[2].disabled = false;
        inputs[3].tipoinput = "text";
        inputs[3].disabled = false;
        inputs[4].tipoinput = "text";
        inputs[4].disabled = false;
        inputs[5].tipoinput = "checkbox";
        inputs[5].disabled = false;
        inputs[6].tipoinput = "listaperfiles";
        inputs[6].disabled = false;
    }
    else {
        inputs[0].valor = "";
        inputs[1].valor = "";
        inputs[2].valor = "";
        inputs[3].valor = "";
        inputs[4].valor = "";
        inputs[5].valor = "";
        inputs[5].contenido = 2;
        inputs[6].valor = "";
        inputs[6].contenido = "";
        inputs[7].valor = "";
        inputs[8].valor = "";
        inputs[9].valor = "";
        inputs[10].valor = "";

        inputs[0].tipoinput = "null";
        inputs[1].tipoinput = "text";
        inputs[2].tipoinput = "text";
        inputs[3].tipoinput = "text";
        inputs[4].tipoinput = "text";
        inputs[5].tipoinput = "checkbox";
        inputs[6].tipoinput = "listaperfiles";
        inputs[7].tipoinput = "null";
        inputs[8].tipoinput = "null";
        inputs[9].tipoinput = "null";
        inputs[10].tipoinput = "null";

        inputs[1].disabled = false;
        inputs[2].disabled = false;
        inputs[3].disabled = false;
        inputs[4].disabled = false;
        inputs[5].disabled = false;
        inputs[6].disabled = false;
    }

    return inputs

}







export function ordenarUsuarios(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idusuario) > parseInt(b.idusuario)) {
                    return 1;
                }
                if (parseInt(a.idusuario) < parseInt(b.idusuario)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.login.toUpperCase() > b.login.toUpperCase()) {
                    return 1;
                }
                if (a.login.toUpperCase() < b.login.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.email.toUpperCase() > b.email.toUpperCase()) {
                    return 1;
                }
                if (a.email.toUpperCase() < b.email.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.tipo.toUpperCase() > b.tipo.toUpperCase()) {
                    return 1;
                }
                if (a.tipo.toUpperCase() < b.tipo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.creado.toUpperCase() > b.creado.toUpperCase()) {
                    return 1;
                }
                if (a.creado.toUpperCase() < b.creado.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.usuarioactualizacion.toUpperCase() > b.usuarioactualizacion.toUpperCase()) {
                    return 1;
                }
                if (a.usuarioactualizacion.toUpperCase() < b.usuarioactualizacion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 6) {
            lista = temporal.sort(function (a, b) {
                if (a.actualizado.toUpperCase() > b.actualizado.toUpperCase()) {
                    return 1;
                }
                if (a.actualizado.toUpperCase() < b.actualizado.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        else if (opcion == 7) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}
