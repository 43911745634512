import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { createBrowserHistory } from 'history';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Componentes/Home/Home';
import Situacion2 from './Componentes/Catalogos/Situacion2';
import EnvioResultados2 from './Componentes/Catalogos/EnvioResultados2';
import Servicios2 from './Componentes/Catalogos/Servicios2';
import Monedas2 from './Componentes/Catalogos/Monedas2';
import Errores2 from './Componentes/Catalogos/Errores2';
import MediosServicios2 from './Componentes/Catalogos/MediosServicios2';
import AsesoresServicio2 from './Componentes/Catalogos/AsesoresServicio2';
import Familia2 from './Componentes/Catalogos/Familias2';
import TipoOperacion2 from './Componentes/Catalogos/TipoOperacion2';
import Usuarios2 from './Componentes/Usuarios/Usuarios2';
import Ajustes from './Componentes/Cuenta/Ajustes';
import Password from './Componentes/Cuenta/Password';
//import ParseadorDigital2 from './Componentes/Administracion/ParseadorDigital2';
import Cuentas2 from './Componentes/Administracion/Cuentas2';
import BitacoraGestion2 from './Componentes/Administracion/BitacoraGestion2';

import Estadodecuenta from './Componentes/Catalogos/EstadoCuenta';
import CuentasAsociadas from './Componentes/Administracion/CuentasAsociadas';

import ParametrosDeSistema from './Componentes/Administracion/ParametroDeSistema';
import ConsultarArchivos from './Componentes/Reportes/ConsultaArchivos';
import ConsultarClientes from './Componentes/Reportes/ConsultaClientes';
import VolumenOperado from './Componentes/Reportes/VolumenOperado';
import ParametrosDeSistemaNuevo from './Componentes/Administracion/ParametrosDeSistemaNuevo';



export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>

      <Switch>

      <Route path="/volumenoperado" component={VolumenOperado} />
      <Route path="/consultarclientes" component={ConsultarClientes} />
        <Route path="/consultararchivos" component={ConsultarArchivos} />

        <Route path="/parametrodesistema" component={ParametrosDeSistemaNuevo} />
        <Route path="/estadosdecuenta" component={CuentasAsociadas} />{/*//Arias/*/}
        <Route path="/bitacoragestion" component={BitacoraGestion2} />
        {/*<Route path="/parseadordigital" component={ParseadorDigital2} />*/}
        <Route path="/cuentas" component={Cuentas2} />

        <Route path="/password" component={Password} />
        <Route path="/ajustes" component={Ajustes} />

        <Route path="/usuarios" component={Usuarios2} />

        <Route path="/tipoestadodecuenta" component={Estadodecuenta} />{/*//Arias/*/}
        <Route path="/tipooperacion" component={TipoOperacion2} />
        <Route path="/familia" component={Familia2} />
        <Route path="/asesoresservicio" component={AsesoresServicio2} />
        <Route path="/mediosservicio" component={MediosServicios2} />
        <Route path="/errores" component={Errores2} />
        <Route path="/monedas" component={Monedas2} />
        <Route path="/servicios" component={Servicios2} />
        <Route path="/envioresultados" component={EnvioResultados2} />
        <Route path="/situacion" component={Situacion2} />
        <Route path="/" component={Home} />

      </Switch>


    </BrowserRouter>
  );

}

export default App;

