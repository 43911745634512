import React from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { Button, Table } from "reactstrap";



export default class TableroEditableCuenta extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                {this.props.listamedios.map((medio, indexmedio) => (
                    <div className=" col-12 modal-cuentas-tablecontainer">
                        <p><b>Medio {medio.canal} </b></p>
                        <Table className="panel-table">
                            <thead>
                                <tr>
                                    {/*this.props.headermedios.map(head => (
                                        <th>{head}</th>
                                    ))*/}
                                    <th style={{ minWidth: "60px" }}>{this.props.headermedios[0]}</th>
                                    <th style={{ minWidth: "200px" }}>{this.props.headermedios[1]}</th>
                                    <th style={{ minWidth: "100px" }}>{this.props.headermedios[2]}</th>
                                    <th style={{ minWidth: "100px" }}>{this.props.headermedios[3]}</th>
                                    <th style={{ minWidth: "160px" }}>{this.props.headermedios[4]}</th>
                                    <th style={{ minWidth: "120px" }}>{this.props.headermedios[5]}</th>
                                    <th style={{ minWidth: "160px" }}>{this.props.headermedios[6]}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ minWidth: "60px" }}>{medio.idcuentacanalarchivos}</td>
                                    <td style={{ minWidth: "200px" }}>{medio.canal}</td>

                                    {(medio.estatus != "") ? (
                                        <td style={{ minWidth: "100px" }}> {(this.props.listasituaciones.length > 0) ? (
                                            <div style={{display:"flex"}}>
                                                <select className="blueoutlinebutton modal-cuentas-tablero-button" style={{ padding: ".1rem .1rem" }}
                                                    onChange={(e) => this.props.cambiarestatusmedio({ indexestatus: e.target.value, medioid: medio.idcatalogocanalarchivos })}>
                                                    {this.props.listasituaciones.map((estado, indexestatus) => (
                                                        <>
                                                            {(estado.idcatalogoestatus == medio.idcatalogoestatus) ? (
                                                                <option value={indexestatus} selected>{estado.estatus}</option>
                                                            ) :
                                                                <option value={indexestatus} >{estado.estatus}</option>
                                                            }
                                                        </>
                                                    ))}
                                                </select>

                                                {(medio.idcuentacanalarchivos == "") ? (
                                                    <div style={{ width: "fit-content", margin: "auto"}}>
                                                        <Button className="redbutton modal-cuentas-tablero-button modal-cuentas-iconostableros"
                                                        onClick={() => this.props.desactivarmedio(medio)}>
                                                            <BsFillTrashFill className="modal-cuentas-tablero-button"/>
                                                        </Button>
                                                    </div>
                                                ) : null}

                                            </div>
                                        ) : "Necesario crear situaciones"} </td>
                                    ) :
                                        <td style={{ minWidth: "100px" }}>
                                            <Button className="greenbutton modal-cuentas-tablero-button"
                                                onClick={() => this.props.activarmedio(medio)}>
                                                Agregar
                                            </Button>
                                        </td>
                                    }

                                    <td style={{ minWidth: "100px" }}>{medio.usuariocreacion}</td>
                                    <td style={{ minWidth: "160px" }}>{medio.creado}</td>
                                    <td style={{ minWidth: "120px" }}> {medio.usuarioactualizacion}</td>
                                    <td style={{ minWidth: "160px" }}> {medio.actualizado}</td>

                                </tr>
                            </tbody>
                        </Table>










                        {/* servicios ///////////////////////////////////////////////////////////// */}
                        {(medio.servicios.length > 0) ? (
                            <>
                                <p><b>Servicios para {medio.canal} </b></p>
                                <Table className="panel-table">
                                    <thead>
                                        <tr>
                                            {/*this.props.headerservicios.map(head => (
                                                <th>{head}</th>
                                            ))*/}
                                            <th style={{ minWidth: "60px" }}>{this.props.headerservicios[0]}</th>
                                            <th style={{ minWidth: "200px" }}>{this.props.headerservicios[1]}</th>
                                            <th style={{ minWidth: "100px" }}>{this.props.headerservicios[2]}</th>
                                            <th style={{ minWidth: "100px" }}>{this.props.headerservicios[3]}</th>
                                            <th style={{ minWidth: "160px" }}>{this.props.headerservicios[4]}</th>
                                            <th style={{ minWidth: "120px" }}>{this.props.headerservicios[5]}</th>
                                            <th style={{ minWidth: "160px" }}>{this.props.headerservicios[6]}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {medio.servicios.map((servicio) => (
                                            <tr>
                                                <td style={{ minWidth: "60px" }}>{servicio.idcuentacanalarchivosservicio} </td>
                                                <td style={{  minWidth: "200px" }}>{servicio.servicio} </td>

                                                {(servicio.estatus != "") ? (
                                                    <td style={{ minWidth: "100px" }}>
                                                        {(this.props.listasituaciones.length > 0) ? (
                                                            <div style={{display:"flex"}}>
                                                                {(medio.idcatalogoestatus == 1) ? (
                                                                    <select className="blueoutlinebutton modal-cuentas-tablero-button" style={{ padding: ".375rem .75rem" }}
                                                                        onChange={(e) => this.props.cambiarestatusservicio({ indexestatus: e.target.value, servicioid: servicio.idcatalogoservicios, medioid: medio.idcatalogocanalarchivos })}>
                                                                        {this.props.listasituaciones.map((estado, indexestatus) => (
                                                                            <>
                                                                                {(estado.idcatalogoestatus == servicio.idcatalogoestatus) ? (
                                                                                    <option value={indexestatus} selected>{estado.estatus}</option>
                                                                                ) :
                                                                                    <option value={indexestatus} >{estado.estatus}</option>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </select>
                                                                ) :
                                                                    <label className="cancelbutton modal-cuentas-tablero-button" 
                                                                    style={{ padding: ".375rem .75rem", textAlign:"center", alignItems:"center" }}>
                                                                        {servicio.estatus}
                                                                    </label>
                                                                }
                                                                {(servicio.idcuentacanalarchivosservicio == "") ? (
                                                                    <div style={{ width: "fit-content", margin: "auto" }}>
                                                                        <Button className="redbutton modal-cuentas-tablero-button modal-cuentas-iconostableros"
                                                                        onClick={() => this.props.desactivarservicio({ servicio: servicio, medio: medio })}>
                                                                            <BsFillTrashFill className="modal-cuentas-tablero-button"/>
                                                                        </Button>
                                                                    </div>

                                                                ) : null}
                                                            </div>
                                                        ) : "Necesario crear situaciones"}
                                                    </td>
                                                ) :
                                                    <td style={{ minWidth: "100px" }}>
                                                        <Button className="greenbutton modal-cuentas-tablero-button"
                                                            disabled={(medio.idcatalogoestatus == "") ? true : false}
                                                            onClick={() => this.props.activarservicio({ servicio: servicio, medio: medio })}>
                                                            Agregar
                                                        </Button>
                                                    </td>
                                                }
                                                <td style={{ minWidth: "100px" }}>{servicio.usuariocreacion}</td>
                                                <td style={{  minWidth: "160px" }}>{servicio.creado}</td>
                                                <td style={{  minWidth: "120px" }}>{servicio.usuarioactualizacion}</td>
                                                <td style={{  minWidth: "160px" }}>{servicio.actualizado}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ) :
                            <p><b>No has agregado servicios para {medio.canal} </b></p>
                        }
                    </div>
                ))}

            </>
        )
    }


}