import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';


import { RiFileList2Fill } from 'react-icons/ri';
import { GoGear } from 'react-icons/go';
import { FaUsersCog } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiFillHome } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi'




export default function MenuItem({ direction, ...args }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [seleccionado, setRuta] = useState("");
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    function revisar(ruta) {

        if (ruta.nombre == "Cerrar sesión") {
            sessionStorage.removeItem("ls");

            var loca = window.location.pathname;
            var ara = loca.split('/');
            ara = "/" + ara[1]
            if (ara == "/")
                window.location.reload(true)
        }
        setRuta(ruta.enlace);
    }
    return (
        <div>
            {(seleccionado != "") ? (<Redirect to={seleccionado} />) : null}
            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle tag="span" className="navbar-web-menuitem-button" caret>

                    {(args.icon == 1) ? (<RiFileList2Fill className="navbar-web-menuicons" />) : null}
                    {(args.icon == 2) ? (<GoGear className="navbar-web-menuicons" />) : null}
                    {(args.icon == 3) ? (<HiOutlineDocumentReport className="navbar-web-menuicons" /> ) :null }
                    {(args.icon == 4) ? (<FaUsersCog className="navbar-web-menuicons" />) : null}
                    {(args.icon == 5) ? (<BsFillPersonFill className="navbar-web-menuicons" />) : null}

                    {args.grupo.titulo}
                </DropdownToggle>
                <DropdownMenu {...args} right>
                    {(args.grupo.items.map((el, index) => (
                        <DropdownItem className={(args.ruta == el.enlace && args.ruta != "/") ? ("rutaactiva") : null} key={"menuweb" + index}
                            //href={el.enlace}

                            onClick={() => revisar(el)}
                        >{el.nombre}</DropdownItem>
                    )))}
                </DropdownMenu>
            </Dropdown>

        </div>
    );
}

MenuItem.propTypes = {
    direction: PropTypes.string,
};








export class MenuMovil extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lista: this.props.listamenu,
            nuevaruta: ""
        }
    }

    activaropcion = (opcion, estado) => {
        let listanueva = this.state.lista;

        listanueva.forEach(el => {
            if (el.opcion != opcion)
                el.activa = false
        });
        listanueva[opcion].activa = !estado;
        this.setState({ lista: listanueva });

        if (opcion == 0) {
            this.setState({ nuevaruta: "/" })
        }
    }

    cambiarRuta = (item) => {

        if (item.nombre == "Cerrar sesión") {
            sessionStorage.removeItem("ls");

            var loca = window.location.pathname;
            var ara = loca.split('/');
            ara = "/" + ara[1]
            if (ara == "/")
                window.location.reload(true)
        }
        this.setState({ nuevaruta: item.enlace })
    }


    render() {
        return (
            <>
                {(this.state.nuevaruta != "") ? (
                    <Redirect to={this.state.nuevaruta} />
                ) : null}
                <Collapse isOpen={this.props.colapsar}  >

                    {(this.state.lista.map((el, index) => (
                        <>
                            {(sessionStorage.getItem("ls")) ? (
                                <div key={"menumovil" + index} className='navbar-movil-menuitem-option'>

                                    <button onClick={() => this.activaropcion(index, el.activa)}>
                                        {(index == 0) ? (<AiFillHome className="navbar-web-menuicons" />) : null}
                                        {(index == 1) ? (<RiFileList2Fill className="navbar-web-menuicons" />) : null}
                                        {(index == 2) ? (<GoGear className="navbar-web-menuicons" />) : null}
                                        {(index == 3) ? (<HiOutlineDocumentReport className="navbar-web-menuicons" />) : null}
                                        {(index == 4) ? (<FaUsersCog className="navbar-web-menuicons" />) : null}
                                        {(index == 5) ? (<BsFillPersonFill className="navbar-web-menuicons" />) : null}

                                        {el.titulo}
                                    </button>
                                    <Collapse isOpen={el.activa} className="navbar-movil-menuitem-option-submenu ">
                                        {el.items.map((item, index2) => (
                                            <>
                                                <p key={"menumovilsub" + index2} className={(this.props.ruta.toString() == item.enlace && item.enlace != "/") ? ("rutaactiva href") : "href"} onClick={() => this.cambiarRuta(item)}>{item.nombre} </p>
                                            </>
                                        ))}
                                    </Collapse>
                                </div>

                            ) :
                                <>
                                    {(index == 0) ? (
                                        <div key={"menumovil" + index} className='navbar-movil-menuitem-option'>

                                            <button onClick={() => this.activaropcion(index, el.activa)}>
                                                <AiFillHome className="navbar-web-menuicons" /> {el.titulo}
                                            </button>
                                            <Collapse isOpen={el.activa} className="navbar-movil-menuitem-option-submenu ">
                                                {el.items.map((item, index2) => (
                                                    <>
                                                        <p key={"menumovilsub" + index2} className={(this.props.ruta.toString() == item.enlace && item.enlace != "/") ? ("rutaactiva href") : "href"} onClick={() => this.cambiarRuta(item)}>{item.nombre} </p>
                                                    </>
                                                ))}
                                            </Collapse>
                                        </div>
                                    ) : null}
                                </>
                            }

                        </>

                    )))}
                </Collapse>
            </>
        );
    }
}
