import React, { useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Font } from '@react-pdf/renderer'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ExportarConsultaClientes extends React.Component {
    constructor(props) {
        super(props);

        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }

        this.state = {
            fechaActual: fechaaux,
        }

    }

    mensajesindatos = () => {
        toast.warn("No hay datos que exportar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    render() {
        return (
            <>
                <ToastContainer />
                {this.props.bandera == "PDF" ? (
                    <>
                        {this.props.arraydatos.length >= 0 ?
                            <PDFDownloadLink onClick={this.verdatos}
                                document={
                                    <MyDocument fechaActual={this.state.fechaActual} fechaI={this.props.fechaI} fechaF={this.props.fechaF}
                                        cliente={this.props.cliente} tipo={this.props.tipo} legado={this.props.legado} detalles={this.props.detalles}
                                        array_heders={this.props.array_heders} arraydatos={this.props.arraydatos} familia={this.props.familia} medio={this.props.medio}
                                    />
                                } fileName="Tipo_de_servicio_por_medio_y_cuenta.pdf">
                                <BsFillFileEarmarkPdfFill className="consulta-archivos-pdf" style={{ color: "#ad0b00", fontSize: "30px" }} />
                            </PDFDownloadLink>
                            :
                            <BsFillFileEarmarkPdfFill onClick={this.mensajesindatos} className="consulta-archivos-pdf" style={{ color: "#ad0b00", fontSize: "30px" }} />
                        }
                    </>
                ) :
                    <>
                        {this.props.arraydatos.length >= 0 ?
                            <CSVLink data={this.props.arraycsv} filename={"Tipo_de_servicio_por_medio_y_cuenta.csv"} enclosingCharacter={``}  >
                                <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px" }} />
                            </CSVLink>
                            :
                            <RiFileExcel2Fill onClick={this.mensajesindatos} className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px" }} />
                        }
                    </>}

            </>
        )
    }
}



Font.register({
    family: 'Oswald',
    fontWeight: 900,
    src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf',
});


let tourItemImage = {
    border: '2px solid black'
};


// Create Document Component
export function MyDocument(props) {

    //console.log(JSON.stringify(props.arraydatos));
    var data = props.arraydatos;
    // console.log(JSON.stringify(data))


    return (
        <Document>

            <Page orientation="landscape" size="A4" style={styles.page}>

                <View fixed>

                    <View style={{ paddingTop: '25px', paddingBottom: '7px' }}>   </View>
                    <View style={[styles.row, styles.bold, styles.header]}>

                        <View style={styles.rowimage}>
                            <Image style={styles.image} src="assets\logos\intercam_logo.png" />
                        </View>
                        <View style={styles.rowtitle}>
                            <Text style={styles.lbltitle}>Consulta Archivos recibidos por tipo de servicio y resultado</Text>
                        </View>


                    </View>

                    <View style={{ paddingBottom: '15px' }}>   </View>


                    <View style={[styles.row, styles.bold, styles.header]}>
                        <View style={styles.rowtitle}>
                            <Text style={[styles.lbltexto, styles.padinlateral]}>Familia: {props.familia}</Text>
                        </View>

                        <View style={styles.rowimage}>

                        </View>



                    </View>

                    <View style={[styles.row, styles.bold, styles.header]}>
                        <View style={styles.rowtitle}>
                            <Text style={[styles.lbltexto, styles.padinlateral]}>Cliente: {props.cliente}</Text>
                        </View>

                        <View style={styles.rowimage}>

                        </View>

                        <View style={styles.rowdate}>
                            <Text style={styles.lblDate}>Fecha de gestión: {props.fechaActual}</Text>
                        </View>

                    </View>

                    <View style={[styles.row, styles.bold, styles.header]}>
                        <View style={styles.rowtitle}>
                            <Text style={[styles.lbltexto, styles.padinlateral]}>Tipo de medio: {props.medio}</Text>
                        </View>


                        <View style={styles.rowimage}>

                        </View>

                        <View style={styles.rowdate}>
                            <Text style={styles.lbltexto} render={({ pageNumber }) =>
                                `Hoja: ${pageNumber}`
                            }
                                fixed></Text>
                        </View>



                    </View>

                    <View style={[styles.row, styles.bold, styles.header]}>
                        <View style={styles.rowtitle}>
                            <Text style={[styles.lbltexto, styles.padinlateral]}>Tipo de servicio: {props.tipo}</Text>
                        </View>

                        <View style={styles.rowimage}>

                        </View>



                    </View>

                    <View style={{ paddingBottom: '6px' }}>   </View>
                    <View style={[styles.subrow, styles.bold, styles.header, tourItemImage]}>

                        <View style={styles.subcol1}>
                            <Text style={[styles.lbltitlesubrow, { paddingLeft: '3px' }]}>{props.array_heders[0]}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[1]}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[2]}</Text>
                        </View>

                        <View style={styles.subcol3}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[3]}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[4]}</Text>
                        </View>

                        <View style={styles.subcol3}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[5]}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lbltitlesubrow}>{props.array_heders[6]}</Text>
                        </View>

                    </View>

                </View>


                {data.map((items, i) => (
                    <View key={i} style={[styles.rowinternos, styles.bold, styles.header]}>

                        <View style={styles.subcol1}>
                            <Text style={[styles.lblsubtextorow, { paddingLeft: '3px' }]}>{items.idcuentacanalarchivosservicio}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lblsubtextorow}>{items.familia}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lblsubtextorow}>{items.cuenta}</Text>
                        </View>

                        <View style={styles.subcol3}>
                            <Text style={styles.lblsubtextorow}>{items.cliente}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lblsubtextorow}>{items.canal}</Text>
                        </View>

                        <View style={styles.subcol3}>
                            <Text style={styles.lblsubtextorow}>{items.servicio}</Text>
                        </View>

                        <View style={styles.subcol2}>
                            <Text style={styles.lblsubtextorow}>{items.clave}</Text>
                        </View>

                    </View>
                ))}

            </Page>
        </Document>

    )
};


// Create styles
const styles = StyleSheet.create({
    page: {
        orientation: 'landscape',
        backgroundColor: 'white',
        paddingBottom: '7%',
        width: '120px',
    },

    image: {
        width: "110px",
        paddingLeft: '10px',
    },

    lbltitle: {
        paddingRight: '20px',
        paddingTop: '10px',
        fontSize: '17px',
        fontWeight: '700',
        textAlign: 'start',
        color: 'black',
        letterSpacing: '0.9px'
    },

    lblDate: {
        paddingTop: '10px',
        fontSize: '12px',
        color: 'black'
    },


    lbltexto: {
        fontSize: '10px',
        color: 'black',
        paddingBottom: '4px'
    },

    padinlateral: {
        paddingLeft: '20px',
    },

    lbltitlesubrow: {
        fontSize: '7px',
        fontWeight: '700'
    },

    lblsubtextorow: {
        fontSize: '8px'
    },

    lbltextlateral: {
        textAlign: 'right',
        paddingRight: '5px'
    },


    negrita: {
        fontWeight: 900,
    },

    table: {
        width: '100%',
    },

    row: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',

    },

    header: {
        borderTop: 'none',
    },

    rowimage: {
        width: '17%'
    },

    rowtitle: {
        width: '61%'
    },

    rowdate: {
        width: '24%'
    },


    subrow: {
        borderTopWidth: '4px',
        borderTopColor: '#0379ff',
        borderStyle: 'solid',
        marginTop: '15px',
        width: '85%',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 2,
        paddingBottom: 2,
    },

    rowinternos: {
        display: 'flex',
        flexDirection: 'row',
        width: '85%',
        alignSelf: 'center',
        paddingTop: 2,
        paddingBottom: 1,
    },

    subcol1: {
        width: '9%'
    },

    subcol2: {
        width: '14%'
    },

    subcol3: {
        width: '25%'
    },

})

