import React from "react";
import { Redirect } from "react-router-dom";
import { administracion_bitacora, administracion_bitacora_desglose, administracion_cuentas, catalogo_familia, catalogo_servicios, Reporte_consulta_archivos, Reporte_consulta_archivos_individual } from "../../Services/Api";
import ModalBitacora from "../Modales/ModalBitacora";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import AreaMensajes from "../Modales/AreaMensajes";
import { Button, Table } from "reactstrap";
import { FaSort } from "react-icons/fa";
import Paginacion, { buildContent } from "../Panel/Paginacion";
import Nabvar from "../Panel/Nabvar";
import { BsEyeFill } from "react-icons/bs";
import { ordenarbitacoragestion } from "../Administracion/Complementos/CuentasOrdenar";
import FiltrosConsultaArchivos from "./Complementos/FiltrosConsultaArchivos";

export default class ConsultarArchivos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Consulta Archivos recibidos por tipo de servicio y resultado",
            tabla: {
                encabezados: [
                    "Id Folio",
                    "Familia",
                    "Cuenta",
                    "Cliente",
                    "Tipo Medio",
                    "Tipo Servicio",
                    "Nombre del Archivo",
                    "Fecha Recepción",
                    "Registros Leídos",
                    "Registros con error ",
                    "Registros procesados",
                    "Registros error legado",
                    "Estatus",
                    "Detalle"],
                dimensiones: [
                    "70px",
                    "80px",
                    "80px",
                    "120px",
                    "80px",
                    "110px",
                    "100px",
                    "100px",
                    "70px",
                    "90px",
                    "100px",
                    "100px",
                    "130px",
                    "100px"],
                elementos: [],
            },
            jsoncsv: [],
            familias: [],
            clientes: [],
            servicios: [],
            listamodal: [],
            modalelementos: false,
            tipoaccion: null,


            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            loadingImage: true,
            actualordenado: "",
            tipomensaje: 1,

            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: ""
        }
    }



    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        const lista = await administracion_cuentas();
        if (lista.code == 0) {
            let listaitems = this.state.clientes;
            listaitems = lista.data.items;

            let listaservicios = await catalogo_servicios();
            let listafamilia = await catalogo_familia();


            this.setState({
                clientes: listaitems, respaldoclientes: listaitems, servicios: listaservicios.data.items,
                familias: listafamilia.data.items, actualordenado: "", showmensaje: false
            });

            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        //console.log("Bitacora");
        this.refreshInfo()
    }



    async buscarseleccionado(seleccionado) {
        //console.log(JSON.stringify(seleccionado));
        this.setState({ loadingImage: true, showmensaje: false })
        let bitacora = await Reporte_consulta_archivos(seleccionado[0], seleccionado[1], seleccionado[2], seleccionado[3], seleccionado[4]);


        this.setState({ tabla: [] })
        this.setState({ jsoncsv: [] })

        if (bitacora.code == 0) {
            let nuevatabla = this.state.tabla;
            nuevatabla.elementos = bitacora.data.items;

            //console.log(JSON.stringify(bitacora.data.items))
            //paginarlista
            var respuesta = bitacora.data.items;

            var array_datos = [];
            var array_datosr = [];


            array_datos = ["Fecha de gestión", "Cliente", "Tipo de servicio", "Familia", "Fecha inicio", "Fecha Fin", "Detallado"];
            array_datosr.push(array_datos);

            array_datos = [seleccionado[6], seleccionado[7],
            seleccionado[8], seleccionado[5], seleccionado[0], seleccionado[1], seleccionado[9]];
            array_datosr.push(array_datos);

            var arrayheders = JSON.parse(JSON.stringify(this.state.tabla.encabezados));
            arrayheders.pop();
            array_datosr.push(arrayheders);


            for (let i = 0; i < respuesta.length; i++) {

                array_datos = [respuesta[i].idcuentassolicitudesservicios,
                respuesta[i].familia,
                respuesta[i].cuenta,
                respuesta[i].cliente,
                respuesta[i].medio,
                respuesta[i].servicio,
                respuesta[i].archivo,
                respuesta[i].fecharecepcion,
                respuesta[i].totaltransacciones,
                respuesta[i].totaltransaccioneserror,
                respuesta[i].totaltransaccionesenviadas,
                respuesta[i].totaltransaccionesenviadaserror,
                respuesta[i].estatus,

                ]

                array_datosr.push(array_datos)

            }

            if (array_datosr.length > 3) {

                this.setState({ jsoncsv: array_datosr })
            }


            await this.setState({ tabla: nuevatabla });
            this.handleCommentsChange(1)
        } else {
            if (bitacora.code == 1) {
                toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (bitacora.code == 2) {
                toast.warn(bitacora.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }
        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false });
    }


    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }


    handleCommentsChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)
            });
        }, 0);

    }

    buildContent = (itemsperpage, page, lista) => {
        let list = lista;
        let content = [];
        let itemfinal = ((itemsperpage * page) - 1);

        if (itemfinal > list.length)
            itemfinal = list.length - 1;
        let iteminicial = (itemsperpage * (page - 1));
        for (let i = iteminicial; i <= itemfinal; i++) {
            if (i < list.length) {
                content.push(list[i]);
            }
        }
        return content;
    }





    crearelemento = async (origen) => {
        this.setState({ loadingImage: true, showmensaje: false })
        let tituloactivo = "";
        if (origen[0] == 10) {
            tituloactivo = "Detalle de archivos recibidos";
        }


        //console.log(JSON.stringify(origen));
        //llenar la tabla 
        let lista = await Reporte_consulta_archivos_individual(origen[1]);


        if (lista.code == 0) {/////////dejamos pendiente esto porque nel aun falta mucho y no dara tiempo
            // mandarles otra opcion para que valide que es para este modal

            this.setState({
                tipoaccion: origen, modalelementos: !this.state.modalelementos, opcion: origen[0],
                modaltitulo: tituloactivo, listamodal: lista.data.items
            });

            //console.log(JSON.stringify(lista.data.items));


        }
        else {
            if (lista.code == 1) {
                toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (lista.code == 2) {
                toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }
        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);

    }


    ordenar = (opcion) => {

        if (opcion < 6) {
            let actual = this.state.tabla;
            let x = ordenarbitacoragestion(opcion, actual, this.state.actualordenado);
            actual.elementos = x;
            this.setState({ tabla: actual, actualordenado: opcion });
            this.handleCommentsChange(this.state.activePage)
        }
    }



    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                {(this.state.modalelementos) ? (
                    <ModalBitacora
                        show={this.state.modalelementos}
                        tipoaccion={this.state.tipoaccion}
                        modaltitulo={this.state.modaltitulo}
                        listamodal={this.state.listamodal}
                        opcion={this.state.opcion}
                        changestate={() => this.cerrarmodalcatalogo()} />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body panel-bitacora ">

                        <div className="panel-body-header-table" style={{ height: "initial", display: "block", minWidth: "450px" }}>
                            {this.state.titulotabla}
                            {(this.state.clientes.length > 0) ? (
                                <FiltrosConsultaArchivos listafamilia={this.state.familias} respaldoclientes={this.state.respaldoclientes} listaclientes={this.state.clientes} listaservicios={this.state.servicios}
                                    buscarseleccionado={(e) => this.buscarseleccionado(e)} arraydatos={this.state.tabla.elementos} arrayjsoncsv={this.state.jsoncsv} array_heders={this.state.tabla.encabezados} />
                            ) : null
                            }
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :
                            <>

                                {this.state.tabla.elementos != null || this.state.tabla.elementos != undefined ? (

                                    this.state.tabla.elementos.length > 0 ? (

                                        <Table className="panel-table" >
                                            <thead style={{ verticalAlign: "top" }}>
                                                <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                            style={{ minWidth: this.state.tabla.dimensiones[index], fontSize: "13px" }}
                                                            onClick={() => this.ordenar(index)}>
                                                            {titulo}
                                                            {(index < 6) ? (
                                                                <FaSort className={(this.state.actualordenado == index) ? ("tables-iconsort tables-actualsorting") : "tables-iconsort"} />
                                                            ) : null}

                                                        </th>
                                                    ))}
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.listapaginada.map((item, index) => (
                                                    <tr key={"tablaitemtr" + index} style={{ fontSize: "13px" }}>
                                                        <td className={"panel-table-cell" + 0}>
                                                            {item.idcuentassolicitudesservicios}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.familia}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cuenta}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cliente}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.medio}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.servicio}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.archivo}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.fecharecepcion}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.totaltransacciones}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.totaltransaccioneserror}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.totaltransaccionesenviadaserror}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.codigoestructura}
                                                        </td>
                                                        <td className={"panel-table-cell" + 4}>
                                                            <b>{item.estatus}</b>
                                                        </td>
                                                        <td className="panel-table-buttons">
                                                            <div className="panel-table-buttons-aux" style={{ width: "initial !important" }}>
                                                                <div style={{ width: "initial" }}>
                                                                    {/*<Button className="bluebutton"><BsEyeFill /></Button>*/}
                                                                    <Button className="bluebutton" onClick={() => this.crearelemento(["10", item.idcuentassolicitudesservicios])}><BsEyeFill /></Button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>


                                    ) :
                                        <label><b>Esta tabla no tiene datos</b></label>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }

                                <div className="panel-pagination-section">
                                    <Paginacion
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsperpage}
                                        totalItemsCount={this.state.tabla.elementos}
                                        pageRangeDisplayed={this.state.rangepages}
                                        handleCommentsChange={(e) => this.handleCommentsChange(e)}
                                    />
                                </div>
                            </>
                        }

                    </div>

                    <div className="panel-footer">
                    <label> © INTERCAM Grupo Financiero 2022. Derechos reservados</label>
                    </div>
                </div>
            </>
        );
    }
}