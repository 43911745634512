
import React from "react";
import { Datepicker } from "../../Modales/DatePicker/DatePicker";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { RiCalendarEventFill, RiFileExcel2Fill } from 'react-icons/ri';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import ExportarVolumenOperado from "./ExportarVolumenOperado";
import { obtener_volumen_detalles } from "../../../Services/Api";
import { FaRegCheckSquare } from "react-icons/fa";
import { GrCheckbox } from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class FiltrosVolumenOperado extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }


        this.state = {
            dropdownusuarios: false,
            dropdownservicios: false,
            dropdownlegado: false,
            dropdownfamilia: false,
            showdatepicker: false,
            idclienteactivo: 0,
            idservicioactivo: 0,
            idlegadoactivo: 0,
            nombreclienteactivo: "Todos",
            servicioactivo: "Todos",
            legadoactivo: "Todos",
            fechaactiva: null,
            fechainicio: fechaaux,
            fechafin: fechaaux,
            familiaactivo: "Todos",
            idfamilia: 0,
            familia: this.props.listafamilia,
            clientes: this.props.listaclientes,
            servicios: this.props.listaservicios,
            legado: this.props.listalegado,
            check_detalles: false

        }
    }

    componentDidMount() {
        this.buscarseleccionado();
    }

    opendatepicker = (opcion) => {
        this.setState({ showdatepicker: !this.state.showdatepicker, fechaactiva: opcion });
    }

    dateSelected = (dato) => {
        this.setState({ showdatepicker: !this.state.showdatepicker });
        if (dato != null) {
            if (dato != null) {
                this.handleDayClick(dato.day, dato.y);
            }
        }
    }

    togglecuenta = () => {
        this.setState(prevState => ({
            dropdownusuarios: !prevState.dropdownusuarios
        }));
    }

    toggleservicio = () => {
        this.setState(prevState => ({
            dropdownservicios: !prevState.dropdownservicios
        }));
    }

    activa_detalles_check = async () => {

        if (this.props.arraydatos.length > 0) {
            
            var checkPDF = !this.state.check_detalles;
            await this.setState({ check_detalles: checkPDF })

            if (checkPDF == true) {
                setTimeout(() => {
                    this.props.buscarseleccionado([
                        this.state.idfamilia, this.state.familiaactivo,
                        this.state.idclienteactivo, this.state.nombreclienteactivo,
                        this.state.idservicioactivo, this.state.servicioactivo,
                        this.state.fechainicio, this.state.fechafin, this.state.legadoactivo, this.state.idlegadoactivo,
                        "detalles"
                    ])
                }, 500);
            }
            else{
                setTimeout(() => {
                    this.props.buscarseleccionado([
                        this.state.idfamilia, this.state.familiaactivo,
                        this.state.idclienteactivo, this.state.nombreclienteactivo,
                        this.state.idservicioactivo, this.state.servicioactivo,
                        this.state.fechainicio, this.state.fechafin, this.state.legadoactivo, this.state.idlegadoactivo,
                        "normal"
                    ])
                }, 500);
            }
        }
        else {
            toast.warn("No hay datos que exportar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            await this.setState({ check_detalles: false })
        }

    }

    togglefamilia = () => {
        this.setState(prevState => ({
            dropdownfamilia: !prevState.dropdownfamilia
        }));
    }

    togglelegado = () => {
        this.setState(prevState => ({
            dropdownlegado: !prevState.dropdownlegado
        }));
    }

    clienteselected = async (seleccionado) => {
        this.setState({ nombreclienteactivo: seleccionado.cuenta + " " + seleccionado.cliente, idclienteactivo: seleccionado.idcuentas })
        this.buscarseleccionado();
    }

    familiaselected = async (seleccionado) => {
        this.setState({ idfamilia: seleccionado.idcatalogofamilias, familiaactivo: seleccionado.familia })
        this.buscarseleccionado();
    }

    servicioselected = async (seleccionado) => {
        this.setState({ servicioactivo: seleccionado.servicio, idservicioactivo: seleccionado.idcatalogoservicios })
        this.buscarseleccionado();
    }

    legadoselected = async (seleccionado) => {
        this.setState({ legadoactivo: seleccionado.legado, idlegadoactivo: seleccionado.idlegado })
        this.buscarseleccionado();
    }

    buscarseleccionado = async () => {
        //console.log('' + this.state.check_detalles)
        setTimeout(async () => {
            await this.setState({ check_detalles: false })
        }, 100);
        setTimeout(() => {
            this.props.buscarseleccionado([
                this.state.idfamilia, this.state.familiaactivo,
                this.state.idclienteactivo, this.state.nombreclienteactivo,
                this.state.idservicioactivo, this.state.servicioactivo,
                this.state.fechainicio, this.state.fechafin, this.state.legadoactivo, this.state.idlegadoactivo,
                "normal",
            ])
        }, 100);
    }

    /////////////
    escribir = (prop) => (event) => {
        if (event.target.value == undefined) { return; }
        var dias = event.target.value.split("-");
        var fechaf = dias[2] + '/' + dias[1] + '/' + dias[0];
        this.setState({ [prop]: fechaf });
        if (this.state.fechaactiva == 1)
            this.setState({
                fechainicio: fechaf,
            });
        if (this.state.fechaactiva == 2)
            this.setState({
                fechafin: fechaf,
            });
        this.buscarseleccionado();
    }

    regresaFecha = (fecha) => {
        var [day, month, year] = fecha.split("/");
        var fechaf = "";
        if (day != undefined && month != undefined && year != undefined) {
            fechaf = `${year}-${month}-${day}`
            return fechaf;
        } else {
            fechaf = fecha;
        }
        return fechaf;
    }
    
    //////////

    render() {
        return (
            <>

                <ToastContainer />

                {(this.state.showdatepicker == true) ? (
                    <Datepicker show={this.state.showdatepicker} getDate={this.dateSelected} />
                ) : null}


                <div className="consulta-archivo-filtros">


                    <div className="consulta-archivo-usuarios">
                        <label>Familia:</label>
                        {
                            (this.state.familia.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownfamilia} toggle={this.togglefamilia} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.familiaactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona una familia</DropdownItem>
                                            <DropdownItem onClick={() => this.familiaselected({ familia: "Todos", idcatalogofamilias: 0 })}>Todos</DropdownItem>
                                            {this.state.familia.map((familia) => (
                                                <DropdownItem onClick={() => this.familiaselected(familia)}>{familia.familia}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="consulta-archivo-usuarios">
                        <label>Cliente:</label>
                        {
                            (this.state.clientes.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownusuarios} toggle={this.togglecuenta} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.nombreclienteactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un cliente</DropdownItem>
                                            <DropdownItem onClick={() => this.clienteselected({ cuenta: "", cliente: "Todos", idcuentas: 0 })}>Todos</DropdownItem>
                                            {this.state.clientes.map((cliente) => (
                                                <DropdownItem onClick={() => this.clienteselected(cliente)}>{cliente.cuenta} {cliente.cliente}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-usuarios">
                        <label>Tipo de servicio:</label>
                        {
                            (this.state.servicios.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownservicios} toggle={this.toggleservicio} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.servicioactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "400px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona un servicio</DropdownItem>
                                            <DropdownItem onClick={() => this.servicioselected({ servicio: "Todos", idcatalogoservicios: 0 })}>Todos</DropdownItem>
                                            {this.state.servicios.map((servicio) => (
                                                <DropdownItem onClick={() => this.servicioselected(servicio)}>{servicio.servicio}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="consulta-archivo-fechas " >
                        <label>
                            Fecha:
                        </label>

                        <div style={{ background: "flex", width: "170px" }}>

                            <input className='bitacoragestion-fechafin txtnuevasolicitud input_fecha_new'
                                type={'date'} value={this.regresaFecha(this.state.fechainicio)} onChange={this.escribir("fechainicio")}></input>

                            <input className='bitacoragestion-fechafin txtnuevasolicitud input_fecha_new' style={{ marginTop: '5px' }}
                                type={'date'} value={this.regresaFecha(this.state.fechafin)} onChange={this.escribir("fechafin")}></input>

                        </div>
                    </div>

                    <div className="consulta-archivo-usuarios">
                        <label style={{ paddingLeft: '10px' }}>Precesado<br />legado:</label>
                        {
                            (this.state.servicios.length > 0) ? (
                                <div>
                                    <Dropdown isOpen={this.state.dropdownlegado} toggle={this.togglelegado} style={{ position: "initial" }}>
                                        <DropdownToggle caret className="consulta-archivo-usuarioselected blueoutlinebutton">
                                            <label><b>{this.state.legadoactivo}</b></label>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxWidth: "300px", maxHeight: "300px", overflowY: "scroll" }}>
                                            <DropdownItem header>Selecciona legado</DropdownItem>
                                            <DropdownItem onClick={() => this.legadoselected({ legado: "Todos", idlegado: 0 })}>Todos</DropdownItem>
                                            {this.state.legado.map((item) => (
                                                <DropdownItem onClick={() => this.legadoselected(item)}>{item.legado}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            ) : null
                        }
                    </div>


                    <div className="consulta-archivos-exportar" style={{ marginRight: "0px", marginLeft: "auto" }}>
                        <div style={{ display: "flex" }}> 


                            <ExportarVolumenOperado bandera={'excel'} cliente={this.state.nombreclienteactivo} tipo={this.state.servicioactivo}
                                legado={this.state.legadoactivo} detalles={this.state.check_detalles} fechaI={this.state.fechainicio} fechaF={this.state.fechafin}
                                arraydatos={this.props.arraydatos} array_heders={this.props.array_heders} sub_heders={this.props.sub_heders} arraycsv={this.props.arraycsv} familia={this.state.familiaactivo} />


                            <ExportarVolumenOperado bandera={'PDF'} cliente={this.state.nombreclienteactivo} tipo={this.state.servicioactivo}
                                legado={this.state.legadoactivo} detalles={this.state.check_detalles} fechaI={this.state.fechainicio} fechaF={this.state.fechafin}
                                arraydatos={this.props.arraydatos} array_heders={this.props.array_heders} sub_heders={this.props.sub_heders} arraycsv={this.props.arraycsv} familia={this.state.familiaactivo} />


                        </div>


                        <label className="consulta-archivos-detalles" onClick={this.activa_detalles_check} >
                            {this.state.check_detalles ? <FaRegCheckSquare className="icodetalles" /> : <GrCheckbox className="icodetalles" />}
                            Detallado
                        </label>

                    </div>
                </div>
            </>
        );
    }
}

