import React, { useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Font } from '@react-pdf/renderer'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CSVLink, CSVDownload } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ExportarConsultaArchivos extends React.Component {


    constructor(props) {
        super(props);

        let date = new Date();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaaux = null;

        if (month < 10) {
            fechaaux = (`${day}/0${month}/${year}`)
        } else {
            fechaaux = (`${day}/${month}/${year}`)
        }

        this.state = {
            fechaActual: fechaaux,
        }

    }


    verdatos = () => {
        //console.log("Data -- ");
        //console.log(JSON.stringify(this.props.arraydatos));
    }

    mensajesindatos = () => {
        toast.warn("No hay datos que exportar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }


    render() {
        return (

            <>
                <ToastContainer />
                {this.props.bandera == "PDF" ? (
                    <>
                        {this.props.arraydatos.length > 0 ?
                            <PDFDownloadLink onClick={this.verdatos}
                                document={
                                    <MyDocument fechaActual={this.state.fechaActual} fechaI={this.props.fechaI} fechaF={this.props.fechaF}
                                        cliente={this.props.cliente} tipo={this.props.tipo} familia={this.props.familia} legado={this.props.legado}
                                        detalles={this.props.detalles} array_heders={this.props.array_heders} arraydatos={this.props.arraydatos}
                                    />
                                } fileName="Consulta_archivos_recibidos.pdf">
                                <BsFillFileEarmarkPdfFill className="consulta-archivos-pdf" style={{ color: "#ad0b00", fontSize: "30px" }} />
                            </PDFDownloadLink>
                            :
                            <BsFillFileEarmarkPdfFill onClick={this.mensajesindatos} className="consulta-archivos-pdf" style={{ color: "#ad0b00", fontSize: "30px" }} />
                        }
                    </>
                ) :
                    <>
                        {this.props.arraydatos.length > 0 ?

                            <CSVLink data={this.props.arraydatos} filename={"Consulta_archivos_recibidos.csv"} enclosingCharacter={``} >
                                <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px" }} />
                            </CSVLink>

                            :
                            <RiFileExcel2Fill onClick={this.mensajesindatos} className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px" }} />
                        }
                    </>}

            </>
        )
    }
}

Font.register({
    family: 'Oswald',
    fontWeight: 900,
    src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf',
});


let tourItemtabla2 = {
    borderTop: '2px solid black',
    borderBottom: '2px solid black'
};


let tourItemImage = {
    borderBottom: '1px solid black'
};


// Create Document Component
export function MyDocument(props) {

    var data_tablados = props.arraydatos;

    return (
        <Document>

            <Page orientation="landscape" size="A4" style={stylestabla2.page} >

                <View fixed>
                    <View style={{ paddingTop: '25px', paddingBottom: '7px' }}>   </View>
                    <View style={[stylestabla2.row, stylestabla2.bold, stylestabla2.header]}>

                        <View style={stylestabla2.rowimage}>
                            <Image style={styles.image} src="assets\logos\intercam_logo.png" />
                        </View>

                        <View style={stylestabla2.rowtitle}>
                            <Text style={stylestabla2.lbltitle}>Consulta Archivos recibidos por Tipo de servicios y resultado</Text>
                        </View>



                    </View>

                    <View style={[styles.subrow, stylestabla2.bold, stylestabla2.header]}>
                        <View style={stylestabla2.rowtitle}>
                            <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Cliente: {props.cliente}</Text>
                        </View>

                        <View style={stylestabla2.rowimage}>

                        </View>

                        <View style={stylestabla2.rowdate}>
                            <Text style={stylestabla2.lblDate}>Fecha de gestión: {props.fechaActual}</Text>
                        </View>

                    </View>


                    <View style={[styles.subrow, stylestabla2.bold, stylestabla2.header]}>
                        <View style={stylestabla2.rowtitle}>
                            <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Tipo de servicio: {props.tipo}</Text>
                        </View>

                        <View style={stylestabla2.rowimage}>

                        </View>


                        <View style={stylestabla2.rowdate}>
                            <Text style={stylestabla2.lbltexto} render={({ pageNumber }) =>
                                `Página: ${pageNumber}`
                            } fixed></Text>

                        </View>
                    </View>
                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Familia: {props.familia}</Text>
                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Fecha del {props.fechaI} al {props.fechaF}</Text>
                    <Text style={[stylestabla2.lbltexto, stylestabla2.padinlateral]}>Detallado: {props.detalles == true ? 'SI' : 'NO'}</Text>


                    <View style={[stylestabla2.subrow, stylestabla2.bold, stylestabla2.header, tourItemtabla2]}>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[0]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[1]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[2]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[3]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[4]}</Text>
                        </View>

                        <View style={stylestabla2.subcol2}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[5]}</Text>
                        </View>

                        <View style={stylestabla2.subcol2}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[6]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[7]}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{" Registros\n Leídos"}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{"Registros con\nerror"}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{"Registros\nprocesados"}</Text>
                        </View>

                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{'Registros error legado'}</Text>
                        </View>
                        <View style={stylestabla2.subcol1}>
                            <Text style={stylestabla2.lbltitlesubrow}>{props.array_heders[12]}</Text>
                        </View>

                    </View>

                    {props.detalles == true ? (
                        <>
                            <View style={[stylestabla2.rowinternos, stylestabla2.bold,
                            stylestabla2.header, tourItemImage, { paddingTop: 2, paddingBottom: 2 }

                            ]}>

                                <View style={stylestabla2.subcol5}>
                                    <Text style={stylestabla2.lbltitlesubrow}>ID FOLIO</Text>
                                </View>

                                <View style={stylestabla2.subcol4}>
                                    <Text style={stylestabla2.lbltitlesubrow}>RENGLON</Text>
                                </View>

                                <View style={stylestabla2.subcol4}>
                                    <Text style={stylestabla2.lbltitlesubrow}>COLUMNA</Text>
                                </View>

                                <View style={stylestabla2.subcol6}>
                                    <Text style={stylestabla2.lbltitlesubrow}>CODIGO Y DESCRIPCION VALIDACIÓN</Text>
                                </View>

                                <View style={stylestabla2.subcol7}>
                                    <Text style={stylestabla2.lbltitlesubrow}>FECHA APLICACIÓN</Text>
                                </View>

                            </View>
                        </>) : null}
                </View>
                <View style={{ paddingBottom: '6px' }}>   </View>


                {data_tablados.map((items, i) => (
                    <>
                        {props.detalles == true ?
                            <View style={{ paddingTop: '8px' }}>   </View> : null}

                        <View key={i} style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header]}>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.idcuentassolicitudesservicios}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.familia}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.cuenta}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.cliente}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.medio}</Text>
                            </View>

                            <View style={stylestabla2.subcol2}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.servicio}</Text>
                            </View>

                            <View style={stylestabla2.subcol2}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.archivo}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.fecharecepcion}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={[stylestabla2.lblsubtextorow, stylestabla2.lbltextlateral]}>{items.totaltransacciones}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={[stylestabla2.lblsubtextorow, stylestabla2.lbltextlateral]}>{items.totaltransaccioneserror}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={[stylestabla2.lblsubtextorow, stylestabla2.lbltextlateral]}>{items.totaltransaccionesenviadas}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={[stylestabla2.lblsubtextorow, stylestabla2.lbltextlateral]}>{items.totaltransaccionesenviadaserror}</Text>
                            </View>

                            <View style={stylestabla2.subcol1}>
                                <Text style={stylestabla2.lblsubtextorow}>{items.estatus}</Text>
                            </View>

                        </View>
                        <View style={{ paddingBottom: '3px' }}>   </View>


                        {props.detalles == true ? (
                            <>
                                {items.detalles.map((itemdetalles, x) => (
                                    <>
                                        <View key={i} style={[stylestabla2.rowinternos, stylestabla2.bold, stylestabla2.header, { paddingTop: 1, paddingBottom: 1 }]}>

                                            <View style={stylestabla2.subcol5}>
                                                <Text style={stylestabla2.lblsubtextorow}>{itemdetalles.idcuentassolicitudesserviciosvalidados}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol4}>
                                                <Text style={stylestabla2.lblsubtextorow}>{itemdetalles.fila}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol4}>
                                                <Text style={stylestabla2.lblsubtextorow}>{itemdetalles.columna}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol6}>
                                                <Text style={stylestabla2.lblsubtextorow}>{itemdetalles.codigo}-{itemdetalles.codigodescripcion}</Text>
                                            </View>

                                            <View style={stylestabla2.subcol7}>
                                                <Text style={[stylestabla2.lblsubtextorow, { paddingLeft: '7px' }]}>{itemdetalles.fecha}</Text>
                                            </View>

                                        </View>
                                    </>
                                ))}
                            </>

                        ) : null}

                    </>

                ))}


            </Page>

        </Document>
    )
};




const stylestabla2 = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        orientation: 'landscape',
        backgroundColor: 'white',
        paddingBottom: '9%'
    },

    image: {
        width: "110px",
        padding: '5px',
    },


    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    lbltitle: {
        paddingTop: '10px',
        fontSize: '17px',
        fontWeight: '700',
        textAlign: 'center',
        color: 'black',
        letterSpacing: '0.9px'
    },

    lblDate: {

        fontSize: '12px',
        color: 'black'
    },


    lbltexto: {
        fontSize: '10px',
        color: 'black',
        paddingBottom: '4px'
    },

    padinlateral: {
        paddingLeft: '20px',
    },

    lbltitlesubrow: {

        fontSize: '7px',
        fontWeight: '700'

    },

    lblsubtextorow: {
        fontSize: '8px'
    },

    lbltextlateral: {
        textAlign: 'right',
        paddingRight: '5px'
    },


    negrita: {
        fontWeight: 900,
    },

    table: {
        width: '100%',
    },

    row: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },

    header: {
        borderTop: 'none',
    },

    rowimage: {
        width: '15%'
    },

    rowtitle: {
        width: '66%'
    },

    rowdate: {
        width: '24%'
    },


    subrow: {
        borderTopWidth: '4px',
        borderTopColor: '#0379ff',
        borderStyle: 'solid',
        marginTop: '15px',
        marginLeft: '20px',
        marginRight: '20px',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 2,
        paddingBottom: 2,
    },



    rowinternos: {

        display: 'flex',
        flexDirection: 'row',
        marginLeft: '20px',
        marginRight: '20px'
    },


    subcol1: {
        width: '8%'
    },

    subcol2: {
        width: '14%'
    },

    subcol3: {
        width: '25%'
    },

    ////EL segundo heders///

    subcol4: {
        width: '7.1%'
    },
    subcol5: {
        width: '25%'
    },
    subcol6: {
        width: '22.5%'
    },
    subcol7: {
        width: '38.3%'
    }

})





// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        orientation: 'landscape',
        backgroundColor: 'white',
        paddingBottom: '7%'
    },

    image: {
        width: "110px",
        padding: '5px',
    },


    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    lbltitle: {
        paddingTop: '10px',
        fontSize: '17px',
        fontWeight: '700',
        textAlign: 'center',
        color: 'black',
        letterSpacing: '0.9px'
    },

    lblDate: {

        fontSize: '12px',
        color: 'black'
    },


    lbltexto: {
        fontSize: '10px',
        color: 'black',
        paddingBottom: '4px'
    },

    padinlateral: {
        paddingLeft: '20px',
    },

    lbltitlesubrow: {

        fontSize: '7px',
        fontWeight: '700'

    },

    lblsubtextorow: {
        fontSize: '8px'
    },

    lbltextlateral: {
        textAlign: 'right',
        paddingRight: '5px'
    },


    negrita: {
        fontWeight: 900,
    },

    table: {
        width: '100%',
    },

    row: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },

    header: {
        borderTop: 'none',
    },

    rowimage: {
        width: '15%'
    },

    rowtitle: {
        width: '66%'
    },

    rowdate: {
        width: '24%'
    },


    subrow: {
        borderTopWidth: '4px',
        borderTopColor: '#0379ff',
        borderStyle: 'solid',
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 2,
        paddingBottom: 2,
    },

    rowinternos: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: 2,
        paddingBottom: 1,
    },


    subcol1: {
        width: '8%'
    },

    subcol2: {
        width: '14%'
    },

    subcol3: {
        width: '25%'
    },


})

